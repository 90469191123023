import React, { ReactNode } from 'react'

export interface TogglableProps {
  enabled: boolean
  children?: ReactNode
  fallback?: ReactNode
}
function Togglable(props: TogglableProps) {
  const { enabled, children, fallback = <></> } = props

  return <>{enabled ? children : fallback}</>
}

export { Togglable }
