import { InvitationsDispatchTypes, InvitationSent } from '../actions'
import * as actionTypes from '../actions/types'

export interface InvitationsState {
  loading: boolean
  data: InvitationSent[]
}

const initialState = {
  loading: false,
  data: []
}

const invitationsReducer = (
  state: InvitationsState = initialState,
  action: InvitationsDispatchTypes
): InvitationsState => {
  switch (action.type) {
    case actionTypes.ADD_USER_TO_MATCH_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.ADD_USER_TO_MATCH_FAIL:
      return {
        ...state,
        loading: false
      }
    case actionTypes.ADD_USER_TO_MATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [
          ...state.data, action.payload
        ]
      }
    case actionTypes.REMOVE_USER_FROM_MATCH_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.REMOVE_USER_FROM_MATCH_FAIL:
      return {
        ...state,
        loading: false
      }
    case actionTypes.REMOVE_USER_FROM_MATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter(invitation => !Object.is(invitation, action.payload))
      }
    case actionTypes.GET_INVITATIONS_LOADING:
      return {
        ...state,
        loading: true
      }

    case actionTypes.GET_INVITATIONS_FAILS:
      return {
        ...state,
        loading: false
      }
    case actionTypes.GET_INVITATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      }
    default:
      return state
  }
}

export { invitationsReducer }