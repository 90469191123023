import React, { MouseEventHandler, ReactNode } from 'react'
import LaddaButton, { SLIDE_RIGHT, XS } from 'react-ladda-button'
import { Button } from 'reactstrap'
import classes from './CustomButton.module.scss'

interface CustomButtonProps {
  classColor: string
  color?: string
  loadingCondition?: boolean
  children: ReactNode
  clickHandler?: MouseEventHandler
  isInDynamicList: boolean
  isMobile?: boolean
}

function CustomButton(props: CustomButtonProps) {
  const {
    classColor, clickHandler, color, loadingCondition, children, isInDynamicList, isMobile
  } = props
  // {`ml-auto ${classes.fixCenter}`}

  return (
    <div
      className={`ml-auto d-flex ${isMobile ? 'justify-content-center' : 'align-items-center'} ${
        isInDynamicList === false ? classes.fixCenter : ''
      }`}>
      {isMobile ? (
        <Button color={color} onClick={clickHandler} loading={loadingCondition} size="sm">
          {children}
        </Button>
      ) : (
        <LaddaButton
          className={`ladda-button btn white ${classColor} ${classes.fixButton} ${
            isInDynamicList === true ? classes.bold : ''
          }`}
          data-style={SLIDE_RIGHT}
          data-size={XS}
          color={color}
          onClick={clickHandler}
          loading={loadingCondition}>
          {children}
        </LaddaButton>
      )}
    </div>
  )
}

export { CustomButton }
