import { UserCommunity } from '../user'

const actions = {
  SET_COMMUNITY: 'SET_COMMUNITY'
}

interface SetCommunity {
  type: typeof actions.SET_COMMUNITY
  payload: {
    userCommunity: UserCommunity
  }
}

export { actions as setCommunityActions }
export type { SetCommunity }