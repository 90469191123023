export const ACCEPT_INVITATION_LOADING = 'ACCEPT_INVITATION_LOADING'
export const ACCEPT_INVITATION_FAIL = 'ACCEPT_INVITATION_FAIL'
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS'

export interface AcceptMatchInvitationLoading {
  type: typeof ACCEPT_INVITATION_LOADING;
}

export interface AcceptMatchInvitationFail {
  type: typeof ACCEPT_INVITATION_FAIL;
}

export interface AcceptMatchInvitationSuccess {
  type: typeof ACCEPT_INVITATION_SUCCESS;
}

export type AcceptMatchInvitationDispatchTypes =
  | AcceptMatchInvitationLoading
  | AcceptMatchInvitationFail
  | AcceptMatchInvitationSuccess
