import React from 'react'
import { VectorMap } from 'react-jvectormap'
import { mapIso2ToIso3Country, mapIso3ToIso2Countries } from 'src/utils'
import classes from './index.module.scss'

interface WorldMapProps {
  availableCountries: string[]
  onCountrySelected: (country: string) => void

  onHover?: () => void
  ref?: any
}

function WorldMap(props: WorldMapProps) {
  const {
    ref, onHover, onCountrySelected, availableCountries
  } = props

  return (
    <VectorMap
      map="world_mill"
      backgroundColor="rgba(179, 219, 188, 0.8)"
      ref={ref}
      containerClassName={classes.map}
      onRegionOver={onHover}
      onRegionClick={(_e: unknown, country: string) => onCountrySelected(mapIso2ToIso3Country(country))}
      selectedRegions={mapIso3ToIso2Countries(availableCountries)}
      regionStyle={{
        initial: {
          fill: 'white',
          'fill-opacity': 1,
          stroke: 'black',
          'stroke-width': 0.1,
          'stroke-opacity': 1
        },
        hover: {
          cursor: 'pointer',
          opacity: '1'
        },
        selected: {
          fill: '#502a91',
          opacity: '1',
          cursor: 'pointer'
        },
        selectedHover: {
          opacity: '0.8',
          cursor: 'pointer'
        }
      }}
    />
  )
}

export { WorldMap }
