/* eslint-disable no-nested-ternary */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { getMatchInfo } from 'src/store'
import { Match } from 'src/store/actions/types'
import { MatchesList, MatchesListProps } from '../MatchesList/MatchesList'

function PlayerMatchesList(props: Omit<MatchesListProps, 'title' | 'matchItemButtonText'>) {
  const { t } = useTranslation()
  const { isLoading, matches } = props
  const history = useHistory()
  const dispatch = useDispatch()
  // const id:any = useUser()?.id
  // const { match, isLoading } = useMatch(id)

  const runMatch = (match: Match) => {
    // TODO delegate to router
    history.push(`/dashboard/${match.id}`)
    // dispatch(resetPreview())
    // dispatch(getPreview(match.id))
    dispatch(getMatchInfo(match.id))
  }

  return (
    <MatchesList
      isLoading={isLoading}
      matches={matches}
      title={t('page.home.match_list.title')}
      matchItemButtonText={(isMatchInitiated: boolean, isMatchEnded: boolean) => isMatchInitiated && !isMatchEnded
        ? t('page.home.match_list.match_item.button_continue')
        : !isMatchInitiated && !isMatchEnded
          ? t('page.home.match_list.match_item.button_start')
          : isMatchEnded
            ? t('page.home.match_list.match_item.button_see_results')
            : t('page.home.match_list.match_item.button_see_results')}
      onMatchItemClick={match => runMatch(match)}
    />
  )
}

export { PlayerMatchesList }
