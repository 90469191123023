import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import { Togglable } from 'src/components'
import { useIsLoggedIn } from 'src/router/components/useIsLoggedIn'

function PrivateRoute(props: RouteProps) {
  const isLoggedIn = useIsLoggedIn()

  return (
    <Togglable enabled={isLoggedIn} fallback={<Redirect to="/login" />}>
      <Route {...props} />
    </Togglable>
  )
}

export { PrivateRoute }
