import { CeoParameters } from './match'

export const SET_MATCH_INFO = 'SET_MATCH_INFO'
export const MATCH_INFO_LOADING = 'MATCH_INFO_LOADING'
export const MATCH_INFO_FAIL = 'MATCH_INFO_FAIL'
export const MATCH_INFO_SUCCESS = 'MATCH_INFO_SUCCESS'
export const SET_COMPANY = 'SET_COMPANY'
export const ADD_COMPANY_LOADING = 'ADD_COMPANY_LOADING'
export const ADD_COMPANY_FAIL = 'ADD_COMPANY_FAIL'
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS'
export const SET_CEO_IN_ADMIN_MODE = 'SET_CEO_IN_ADMIN_MODE'
export const SET_DEPARTMENT_IN_ADMIN_MODE = 'SET_DEPARTMENT_IN_ADMIN_MODE'
export const SET_FIRST_CEO_AS_DEFAULT_IN_ADMIN_MODE = 'SET_FIRST_CEO_AS_DEFAULT_IN_ADMIN_MODE'

export interface MatchInfo {
  matchId: number
  name: string
  type: string
  industry: string
  gameParameters: any
  playerPosition: number
  currentStage: number
  lastResultsStage: number
  isAdmin: boolean
  createdAt: string
  stages: number
  playerHistory: PlayerHistory
  othersPlayers?: Player[]
  results?: any
  bankrupt: boolean
  ceos?: Ceo[]
  firstCeoAsDefaultInAdmin?: boolean
  statusId: number
}

export type Company = {
  matchId: number
  companyName: string
  logo: number
}

export type Ceo = {
  id: number
  name: string
  lastName: string
  companyName: string
  avatar: any
  isFounded: boolean
  bankrupt: boolean
  dismissed: boolean
  pivot: {
    results: CeoResults
    ceoParameters:CeoParameters
  }
  ceoParameters:CeoParameters
}

export type CeoResults = {
  [stage: string]: {
    [property: string]: number
  }
}

 type PlayerHistory = {
   'round 1': '',
   'round 2': '',
   'round 3': '',
   'round 4': '',
   'round 5': '',
   'round 6': '',
 }

 type Player = {
   id: number,
   name: string,
   position: number,
 }

export interface SetCompany {
  type: typeof SET_COMPANY;
  payload: boolean;
}

export interface SetCeoInAdminMode {
  type: typeof SET_CEO_IN_ADMIN_MODE;
  payload: Ceo | undefined;
}

export interface SetDepartmentInAdminMode {
  type: typeof SET_DEPARTMENT_IN_ADMIN_MODE;
  payload: string | undefined;
}

export interface SetFirstCeoAsDefaultInAdminMode {
  type: typeof SET_FIRST_CEO_AS_DEFAULT_IN_ADMIN_MODE;
  payload: boolean;
}

export interface AddCompanyLoading {
  type: typeof ADD_COMPANY_LOADING
}

export interface AddCompanyFail {
  type: typeof ADD_COMPANY_FAIL
}

export interface AddCompanySuccess {
  type: typeof ADD_COMPANY_SUCCESS
  payload: Company
}

export interface SetMatchInfo {
  type: typeof SET_MATCH_INFO;
  payload: MatchInfo;
}

export interface MatchInfoLoading {
  type: typeof MATCH_INFO_LOADING;
}

export interface MatchInfoFail {
  type: typeof MATCH_INFO_FAIL
}

export interface MatchInfoSuccess {
  type: typeof MATCH_INFO_SUCCESS
  payload: MatchInfo
}

export type MatchInfoDispatchTypes =
  | MatchInfoLoading
  | MatchInfoFail
  | MatchInfoSuccess
  | SetCompany
  | SetCeoInAdminMode
  | SetDepartmentInAdminMode
  | SetFirstCeoAsDefaultInAdminMode
  | AddCompanyLoading
  | AddCompanyFail
  | AddCompanySuccess

export type SetMatchInfoDispatchT = SetMatchInfo | MatchInfo | SetCompany | Company | SetCeoInAdminMode | SetDepartmentInAdminMode | SetFirstCeoAsDefaultInAdminMode;
