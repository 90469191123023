/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'

import { useMatchInfo } from 'src/views/Dashboard/hooks/useMatchInfo'
import { Ceo } from 'src/store/actions/types/matchInfo'
import { useUser } from 'src/hooks/useUser'
import { getMatchGlobalProperty, getUserProp } from 'src/utils/dataMatchServices'
import ExampleWrapperSimple from 'src/layoutComponents/ExampleWrapperSimple'
import { useMatches } from 'src/hooks/useMatchList'
import { formatIndustry } from 'src/utils/calculations'
import { Card, CardHeader } from 'reactstrap'
import { DataProps } from 'src/layoutComponents/Tabs/AdviserReport/ReportCategories/types'
import { useTranslation } from 'react-i18next'
import { ApexOptions } from 'apexcharts'
import colors from 'src/assets/colors.module.scss'
import ResultsBarChartMemo from 'src/components/Charts/ResultsBarCharts/ResultsBarChartMemo'

interface CeosData {
  ceos: Ceo[]
}

interface SeriesData {
  x: string
  y: number
  fillColor: string
  strokeColor: string
  goals?: {
    name: string
    value: number
    strokeColor: string
  }[]
}

type ObjectivesResultsState = {
  opening_cash: number
  price: number
  top_loan: number
  taxes: number
  un: number
  reference_cost: number
}

type ObjetivesResultsPropNames<K extends string = keyof ObjectivesResultsState> = {
  [key in K]: string
}

const resultsProps: DataProps<keyof ObjetivesResultsPropNames> = {
  opening_cash: 'opening_cash',
  price: 'price',
  top_loan: 'top_loan',
  taxes: 'taxes',
  un: 'un',
  reference_cost: 'reference_cost'
}

type ChartKeys = Partial<keyof ObjectivesResultsState>[]

export default React.memo(() => {
  const { matchInfo } = useMatchInfo()
  const id = useUser()?.id
  const ceosData = matchInfo.data?.ceos
  const matchData = matchInfo.data
  const fundsApplication: ChartKeys = ['opening_cash', 'price', 'top_loan', 'taxes', 'un', 'reference_cost']
  const [objectivesResults, setObjectivesResults] = useState<ObjectivesResultsState>()
  const { matches } = useMatches()
  const { t } = useTranslation()

  const department = 'finance'

  const data: CeosData = { ceos: [] }

  if (ceosData) {
    data.ceos = ceosData
  }

  const variables = {
    industry: 'industry',
    opening_cash: 'opening_cash',
    price: 'price',
    un: 'un'
  }

  const { reference_cost } = getMatchGlobalProperty(id, variables.industry, 0, matchData)
  const price = getMatchGlobalProperty(id, variables.price, 0, matchData)
  const un = getMatchGlobalProperty(id, variables.un, 0, matchData)

  useEffect(() => {
    if (matchInfo.data) {
      const currentObjectiveResults = {} as ObjectivesResultsState
      let key: keyof ObjectivesResultsState
      const keys = Object.keys(resultsProps) as Array<keyof typeof resultsProps>

      for (key of keys) {
        currentObjectiveResults[key] = getUserProp(id, resultsProps[key], matchInfo.data, 0) as number
      }

      currentObjectiveResults.reference_cost = reference_cost * 1000
      currentObjectiveResults.price = price * 1000
      currentObjectiveResults.un = un

      setObjectivesResults(currentObjectiveResults)
    }
  }, [matchInfo.data, data.ceos, id, matches, price, reference_cost, un]) // added: 'price','reference_cost','un'

  const populateDataInAdvanceToAvoidReRendering = (dataProps: any, keys: string[], test: any) => {
    const chartData: SeriesData[] = []

    for (let i = 0; i < keys.length; i++) {
      const prop = keys[i] as keyof typeof dataProps
      const key = dataProps[prop]
      const propValue = Object.values(Object.values(test))[i] as number

      const propData = {
        // eslint-disable-next-line no-negated-condition
        x: key !== 'price' ? t(`variables.${key}`) : t('variables.price_x_unity'),
        y: propValue,
        goals: undefined,
        fillColor: propValue >= 0 ? colors[`${department}_100`] : colors.warning,
        strokeColor: propValue >= 0 ? colors[`${department}_100`] : colors.warning
      }

      chartData.push(propData)
    }
    return chartData
  }

  const chartOptions: ApexOptions = {
    yaxis: {
      decimalsInFloat: 0
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    colors: [colors.finance_100],
    dataLabels: {
      formatter: (val: number) => `$${Math.floor(val)}`
    },
    tooltip: {
      y: {
        formatter: (val: number) => `$${Math.floor(val)}`
      }
    }
  }

  return (
    <ExampleWrapperSimple>
      {objectivesResults && (
        <Card>
          <CardHeader className="d-flex flex-column align-items-start">
            <h5 className="font-weight-bold mb-2">
              Datos Financieros de
              {' '}
              {formatIndustry(matchInfo?.data?.industry as string)}
            </h5>
          </CardHeader>
          <ResultsBarChartMemo
            department="finance"
            data={populateDataInAdvanceToAvoidReRendering(resultsProps, fundsApplication, objectivesResults)}
            options={chartOptions}
          />
        </Card>
      )}
    </ExampleWrapperSimple>
  )
})
