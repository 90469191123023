import { useSelector } from 'react-redux'
import { State, UserCommunity } from 'src/store'

function useUserCommunity(): UserCommunity {
  const selectedCommunity = useSelector((state: State) => state.communities.userCommunity)

  return selectedCommunity
}

function useUserCommunityId(): string {
  const userCommunity = useUserCommunity()

  return userCommunity.id
}

export { useUserCommunity, useUserCommunityId }