import { countryListData } from 'src/components/Tables/Countries/utils'
import {
  CreateMatchDispatchTypes,
  CreateMatchPostRes,
  MatchData,
  MatchSchema,
} from 'src/store/actions/types/match/createMatch'
import * as actionTypes from '../../actions/types/match'

interface MatchState {
  loading: boolean;
  data: MatchData;

  schema?: MatchSchema;
  postRes?: CreateMatchPostRes;
}

const initialMatchData = {
  step: 0,
  type: undefined,
}

const initialState: MatchState = {
  loading: false,
  schema: undefined,
  postRes: undefined,
  data: initialMatchData,
}

// todo: combine reducers
const matchReducer = (
  state: MatchState = initialState,
  action: CreateMatchDispatchTypes,
): MatchState => {
  switch (action.type) {
    case actionTypes.GET_MATCH_SCHEMA_FAILS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_MATCH_SCHEMA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_MATCH_SCHEMA_SUCCESS:
      const countryOptions: string[] = []

      countryListData.forEach((e, index) => countryOptions.push(countryListData[index]?.code as string))
      // console.log(countryOptions)
      return {
        ...state,
        loading: false,
        schema: {
          ...action.payload,
          match_parameters: {
            ...action.payload.match_parameters,
            country: {
              options: countryOptions // solo ['arg'] funciona
              // TODO: REMOVE REF: ['arg', 'usa', 'esp'] // action.payload.match_parameters.country.options,
            },
          },
        },
      }
    case actionTypes.ADD_MATCH_DATA:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...action.payload,
        },
      }
    case actionTypes.RESET_MATCH_DATA:
      return {
        ...state,
        loading: false,
        data: initialMatchData,
      }
    case actionTypes.POST_MATCH_DATA_FAILS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.POST_MATCH_DATA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.POST_MATCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        postRes: action.payload,
      }
    default:
      return state
  }
}

export default matchReducer
export { matchReducer }
export type { MatchState }

