/* eslint-disable prefer-const */
import React from 'react'
import { countryListData, CountriesProps } from './utils'
import { CountriesStats } from './CountriesStats'

// TODO: this is a feature component rather than a basic/extensible comp
function CountriesTab(props: CountriesProps) {
  const { selectedCountry } = props

  return (
    <>
      {selectedCountry && (
        <CountriesStats
          selectedCountry={selectedCountry}
          data={countryListData[countryListData.findIndex(el => el.code === selectedCountry)]}
        />
      )}
    </>
  )
}

export { CountriesTab }
