import { IconProp } from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import { Button } from 'reactstrap'
import * as S from './ConfirmationModal.styles'

type ConfirmationTemperament = 'success' | 'danger' | 'warning' | 'first'

interface ConfirmationModalProps {
  temperament: ConfirmationTemperament
  enabled: boolean
  questionMessage?: string
  description?: string
  acceptButtonText?: string
  cancelButtonText?: string
  onConfirmation?: () => void
  onCancellation?: () => void
  confirmButtonDisabled?: boolean
}

function ConfirmationModal(props: ConfirmationModalProps) {
  const {
    // TODO: translate
    acceptButtonText = 'Aceptar',
    cancelButtonText = 'Cancelar',
    enabled,
    questionMessage,
    description,
    temperament = 'success',
    onConfirmation,
    onCancellation,
    confirmButtonDisabled
  } = props

  // TODO refactor using styled-components
  const getIconContainerClasses = () => {
    if (temperament === 'success') return 'd-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper text-success m-0 d-130 bg-neutral-success'
    if (temperament === 'danger') return 'd-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130'
    if (temperament === 'warning') return 'd-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-warning text-white m-0 d-130'
    if (temperament === 'first') return 'd-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-first text-first m-0 d-130'
    throw new Error('You should pass a temperament prop')
  }

  // TODO refactor: only one function wich returns an object with icon, color, etc
  const getTemperamentIcon = () => {
    if (temperament === 'success') return 'lightbulb'
    if (temperament === 'danger') return 'bolt'
    if (temperament === 'warning') return 'exclamation'
    if (temperament === 'first') return 'hand-rock'
    throw new Error('You should pass a temperament prop')
  }

  const getCancellationButtonColor = () => {
    if (temperament === 'success') return 'neutral-dark'
    if (temperament === 'danger') return 'neutral-secondary'
    if (temperament === 'warning') return 'neutral-secondary'
    if (temperament === 'first') return ''
    throw new Error('You should pass a temperament prop')
  }

  return (
    <S.Container>
      <S.Modal zIndex={2000} centered={true} isOpen={enabled}>
        <S.ModalWrapper>
          <S.IconContainer className={getIconContainerClasses()}>
            <S.Icon icon={getTemperamentIcon() as IconProp} />
          </S.IconContainer>
          <S.QuestionText>{questionMessage}</S.QuestionText>
          <p className="">{description}</p>
          <div className="pt-4">
            <Button onClick={onCancellation} color={getCancellationButtonColor()} className="btn-pill mx-1">
              <span className="btn-wrapper--label">{cancelButtonText}</span>
            </Button>
            <Button
              onClick={onConfirmation}
              color={temperament}
              className="btn-pill mx-1"
              disabled={confirmButtonDisabled}>
              <span className="btn-wrapper--label">{acceptButtonText}</span>
            </Button>
          </div>
        </S.ModalWrapper>
      </S.Modal>
    </S.Container>
  )
}

export { ConfirmationModal }
