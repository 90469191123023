import { CeoDecisionsSchema, CeoDecisionsTypes, Decision } from 'src/store/actions'
import { CeoDecisionsState } from './ceoDecisions'

const calcAvailableFunds = (decisionsSchema: CeoDecisionsSchema, decisions: CeoDecisionsTypes) => {
  let totalFunds = 100

  const isInvestment = (value: string) => value !== 'production' && value !== 'corpDebt' && value !== 'price'

  for (const [key, value] of Object.entries(decisionsSchema)) {
    if (value.type === 'integer' && Object.keys(decisions).includes(key) && isInvestment(key)) {
      totalFunds -= Number(decisions[key as keyof typeof decisions])
    }
  }

  return totalFunds
}

export const updateDecisions = (state: CeoDecisionsState, payload: Decision) => {
  const updatedDecisions = {
    ...state.data.decisions,
    [payload.area]: payload.value
  }

  const updatedFunds = calcAvailableFunds(state.schema, updatedDecisions)

  if (updatedFunds < 0) {
    return state
  }

  return {
    ...state,
    data: {
      ...state.data,
      decisions: updatedDecisions
    },
    funds: updatedFunds
  }
}