/* eslint-disable max-len */
export const newsDetails = [
  {
    descParagraph1: 'Este indicador es clave para entender la tendencia de precios de la industria y compararlo con el precio de nuestra empresa.',
    descParagraph2: 'Sin embargo el analsis deberia completarse teniendo en cuenta la dispersión general de precios del mercado.'
  },
  {
    descParagraph1: 'Indica la cantidad total de unidades que vendio el conjunto de empresas que son parte del mercado.',
    descParagraph2: 'Las ordenes o unidades demandadas pueden diferir de las ventas concretadas.'
  },
  {
    descParagraph1: 'No todas las empresas cuentan con la misma planta productiva, asi que sus costos directos (CBU) seran distintos.',
    descParagraph2: 'Aqui se muestra el promedio de CBU de la industria. ¿Sus costos estan por debajo o por encima del promedio?'
  },
  {
    descParagraph1: 'Presenta la variacion porcentual de la demanda agregada.',
    descParagraph2: 'Es un indicador clave para entender si los consumidores ordenaron a la industria mas o menos unidades que en el periodo anterior.'
  },
  {
    descParagraph1: 'Muestra el cambio en el costo de endeudamiento de las empresas.',
    descParagraph2: 'Una tasa de interes en alza desalienta la inversión de capital fijo.'
  },
  {
    descParagraph1: 'La dispersión de precios se refiere a la variabilidad de los precios de las empresas que lo componen.',
    descParagraph2: 'El dato presentado es el desvío estándar de los precios, donde un desvío estándar más alto indica una mayor dispersión de precios y, por lo tanto, una mayor volatilidad en el mercado. Mas alto es el valor de dispersion de precios, menos util es el precio promedio como indicador.'
  }
]