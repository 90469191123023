import { UserDispatchTypes } from '../actions/types/user'
import { User } from '../actions/types'
import * as actionTypes from '../actions/types'

interface UserState {
  loading: boolean
  data?: User
}
const initialState: UserState = {
  loading: false,
  data: undefined
}

const userReducer = (state: UserState = initialState, action: UserDispatchTypes): UserState => {
  switch (action.type) {
    case actionTypes.USER_FAIL:
      return {
        loading: false
      }
    case actionTypes.USER_LOADING:
      return {
        loading: true
      }
    case actionTypes.USER_SUCCESS:
      return {
        loading: false,
        data: action.payload
      }
    default:
      return state
  }
}

export { userReducer }
