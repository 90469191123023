import { useTranslation } from 'react-i18next'

export const useHomeSteps = () => {
  const { t } = useTranslation()

  const steps = [
    {
      target: '.homeTourStep1',
      title: t('page.home.onboarding.step_1.title'),
      content: t('page.home.onboarding.step_1.content')
    },
    {
      target: '.homeTourStep2',
      title: t('page.home.onboarding.step_2.title'),
      content: t('page.home.onboarding.step_2.content'),
      placement: 'bottom-end' as const
    },
    {
      target: '.homeTourStep3',
      title: t('page.home.onboarding.step_3.title'),
      content: t('page.home.onboarding.step_3.content'),
    }
  ]

  return steps
}