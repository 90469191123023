/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'

import {
  Col, Badge, Card, Button, UncontrolledPopover, ListGroup, ListGroupItem
} from 'reactstrap'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TrendingDown, TrendingUp } from 'react-feather'
import CountUp from 'react-countup'
import { IconName } from '@fortawesome/free-solid-svg-icons'
import { toCurrency } from 'src/utils/toCurrency'
import { newsDetails } from './utils'
import { StageComparison } from '..'

interface TrendProps {
  trendData: StageComparison
  screenSizeMobile?: boolean
  popOver: number
}

const TrendChart = (props: TrendProps) => {
  const {
    title, value, previousValue, change, unit
  } = props.trendData

  const { screenSizeMobile, popOver } = props

  useEffect(() => {}, [title])

  const ellipsisText = (text: string, maxLength: number) => text.length > maxLength ? `${text.slice(0, maxLength)}...` : text

  const getVariationPercentage = (value: number, previousValue: number, unit: string): string => {
    if (unit === 'percentage') {
      const difference = value - previousValue
      const percentage = (difference / Math.abs(previousValue)) * 100
      const trimmedPercentage = (percentage / 10).toFixed(2)

      return `${trimmedPercentage}%`
    }
    if (unit === 'dolar' || unit === 'unit') {
      const difference = value - previousValue
      const percentage = (difference / previousValue) * 100
      const trimmedPercentage = percentage.toFixed(2)

      return `${trimmedPercentage}%`
    }
    return 'Unit not supported'
  }

  /* const getVariationPercentage = (value: number, previousValue: number, unit:string):String | Number => {
    console.log(unit, value)
    console.log(unit, previousValue)
    if (previousValue === 0) {
      return 0
    }

    const difference = value - previousValue
    const percentage = (difference / previousValue) * 100
    const trimmedPercentage = percentage.toFixed(2)

    return `${trimmedPercentage}%`
  }*/

  // console.log(props.trendData)
  const arrowDirection = (val: string): IconName => {
    let arrowDir = ''

    if (val === 'Increased') {
      arrowDir = 'arrow-up'
    } else if (val === 'Decreased') {
      arrowDir = 'arrow-down'
    }

    return arrowDir as IconName
  }

  const checkNew = (newLabel: String, screenSizeMobile: any): string => {
    switch (newLabel) {
      case 'Precio Promedio':
        if (screenSizeMobile) {
          return '100px'
        }
        return '115px'

      case 'Unidades Vendidas Industria':
        if (screenSizeMobile) {
          return '100px'
        }
        return '115px'

      case 'Costo Directo Promedio':
        if (screenSizeMobile) {
          return '100px'
        }
        return '115px'

      case 'Demanda Industria (variación porcentual)':
        if (screenSizeMobile) {
          return '100px'
        }
        return '115px'

      case 'Tasa Interés (variación porcentual)':
        if (screenSizeMobile) {
          return '100px'
        }
        return '115px'

      case 'Dispersión de Precios':
        if (screenSizeMobile) {
          return '100px'
        }
        return '115px'

      default:
        return '115px'
    }
  }

  return title ? (
    <Col
      xs="1"
      sm="1"
      lg="1"
      style={{
        minWidth: checkNew(title, screenSizeMobile),
        marginRight: screenSizeMobile ? '5px' : '5px',
        marginTop: '4px'
      }}>
      <Card className="card-box-t" style={{ height: '64px' }}>
        <Button
          id={`popoverContent${popOver}`}
          outline={true}
          color="sherpa"
          className="transition-none m-0 p-0 pb-4 text-left">
          <span
            className={`opacity-7 ${screenSizeMobile ? 'text' : 'text-uppercase'} ${
              screenSizeMobile ? 'ml-2' : 'ml-2'
            } mt-2 font-size-sm`}>
            <small className="font-weight-bold">
              {title === 'Demanda Industria (variación porcentual)'
                ? ellipsisText(title, 14)
                : title === 'Unidades Vendidas Industria'
                  ? ellipsisText(title, 14)
                  : title === 'Tasa Interés (variación porcentual)'
                    ? ellipsisText(title, 14)
                    : title === 'Dispersión de Precios'
                      ? ellipsisText(title, 14)
                      : title === 'Costo Directo Promedio'
                        ? ellipsisText(title, 14)
                        : title}
            </small>
          </span>
          <div className={`d-flex align-items-center ${screenSizeMobile ? 'pl-1' : 'pl-2'}`}>
            <div
              className={`font-size-md ${screenSizeMobile ? 'mr-0' : 'mr-1'} ${
                change === 'Increased' || change === 'N/A' || change === 'No Change'
                  ? 'text-success'
                  : 'text-warning'
              }`}>
              {change === 'Increased' || change === 'N/A' || change === 'No Change' ? (
                <TrendingUp />
              ) : (
                <TrendingDown />
              )}
              {/* } <div
            className={
              value > ceoPropPreviousRound ? 'font-size-lg text-success' : '`font-size-lg text-danger'
            }>
          {value > ceoPropPreviousRound ? <TrendingUp /> : <TrendingDown />}
          </div>*/}
            </div>
            <div className="px-2">
              {/* <span className="opacity-6 text-uppercase font-size-sm">
              <small>{title}</small>
            </span>*/}
              <div className="d-flex align-items-center justify-content-center pt-1">
                <span
                  className={`font-weight-bold ${
                    screenSizeMobile ? 'font-size-sm' : 'font-size-md'
                  } line-height-1`}>
                  <small className="opacity-7 pr-1">{unit === 'dolar' ? '$' : ''}</small>
                  <span className="opacity-7">
                    <CountUp
                      start={0}
                      end={value}
                      duration={2}
                      separator=","
                      decimals={0}
                      decimal="."
                      prefix=""
                      suffix=""
                    />
                  </span>
                  <small className="opacity-7 pr-1">
                    {unit === 'percentage' ? '%' : unit === 'unit' ? 'u.' : unit === 'none' ? '' : ''}
                  </small>
                </span>
                <Badge
                  color="white"
                  className={`ml-0 ${
                    change === 'Increased' || change === 'N/A' || change === 'No Change'
                      ? 'text-success'
                      : 'text-warning'
                  }`}>
                  {/* <span className="font-size-md">-8%</span> */}
                </Badge>
              </div>
            </div>
          </div>
        </Button>
      </Card>
      <UncontrolledPopover
        placement="bottom"
        trigger="legacy"
        target={`popoverContent${popOver}`}
        hideArrow={false}
        className="popover-custom-wrapper popover-custom-md border-0 rounded-md">
        <div className="bg-midnight-bloom rounded">
          <ListGroup flush={true} className="text-left bg-transparent rounded">
            <ListGroupItem className="bg-transparent rounded pt-1 mt-1 mb-2">
              <div className="card-body p-2 rounded">
                <div className="d-flex justify-content-start mr-auto pb-3">
                  <div className="bg-white text-center text-success font-size-lg d-40 rounded-circle">
                    <FontAwesomeIcon icon={['far', 'lightbulb']} />
                  </div>
                  <small className="text-white font-size-md font-weight-bold d-block mb-1 text-right mt-2 ml-2">
                    @tendencias-mercado-global
                  </small>
                </div>

                <div className="align-box-row align-items-start rounded">
                  <div className="font-weight-bold">
                    <small className="text-white-50 font-size-lg font-weight-bold d-block mb-1 text-uppercase">
                      {title}
                    </small>
                    <span className="font-size-xl mt-1 text-white">
                      {unit === 'dolar' ? toCurrency(value) : value}
                      <small className="opacity-7 pr-1">
                        {unit === 'percentage' ? '%' : unit === 'unit' ? 'u.' : unit === 'none' ? '' : ''}
                      </small>
                    </span>
                  </div>
                </div>
                <div className="mt-2">
                  <FontAwesomeIcon
                    icon={['fas', arrowDirection(change)]}
                    className={
                      change === 'Increased' || change === 'N/A' || change === 'No Change'
                        ? 'text-success'
                        : 'text-warning'
                    }
                  />
                  <span className="text-white px-1">
                    {previousValue
                      ? getVariationPercentage(value, previousValue, unit)
                      : 'Comparación no disponible en Contexto Inicial'}
                  </span>
                  <span className="text-white-50">
                    {change === 'Increased'
                      ? 'de suba respecto al período anterior'
                      : change === 'Decreased'
                        ? 'de baja respecto al período anterior'
                        : '(sin variación)'}
                  </span>
                </div>
                <div className="mt-2 text-white-50">{newsDetails[popOver].descParagraph1}</div>
                <div className="mt-1 text-white-50">{newsDetails[popOver].descParagraph2}</div>
              </div>
            </ListGroupItem>
          </ListGroup>
        </div>
      </UncontrolledPopover>
    </Col>
  ) : null
}

export default TrendChart
