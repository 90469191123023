/* eslint-disable no-negated-condition */
import React, {
  FC, ReactNode, useEffect, useState
} from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import useWindowWidth from 'src/hooks/useWindowWidth'
import { useMatches } from 'src/hooks/useMatchList'
import { Sidebar, Header, Footer } from '../../layoutComponents'
import BackgroundImage from '../../layoutComponents/BackgroundImage'

interface Props {
  children?: ReactNode
  sidebarToggle?: boolean
  sidebarToggleMobile?: boolean
  sidebarFixed?: boolean
  headerFixed?: boolean
  headerSearchHover?: boolean
  headerDrawerToggle?: boolean
  footerFixed?: boolean
  contentBackground?: boolean
}

const LeftSidebar: FC<Props> = ({
  children = null,
  sidebarToggle = true,
  sidebarToggleMobile = true,
  sidebarFixed = true,
  headerFixed = true,
  headerSearchHover = true,
  headerDrawerToggle = true,
  footerFixed = true,
  contentBackground = true
}) => {
  const [screenSizeMobile, setScreenSizeMobile] = useState(false)
  const isMobile = useWindowWidth() <= 750
  const { forceRender } = useMatches()

  useEffect(() => {
    setScreenSizeMobile(isMobile)
  }, [isMobile, screenSizeMobile, forceRender, children])

  // eslint-disable-next-line no-negated-condition
  return forceRender && children !== null ? ( // !isLoading && matches && matches?.length > 0 ?
    <>
      <div // app-wrapper--lightTheme
        className={clsx('app-wrapper', contentBackground, {
          'header-drawer-open': headerDrawerToggle,
          'app-sidebar-collapsed': sidebarToggle,
          'app-sidebar-mobile-open': sidebarToggleMobile,
          'app-sidebar-fixed': sidebarFixed,
          'app-header-fixed': headerFixed,
          'app-footer-fixed': footerFixed,
          'search-wrapper-open': headerSearchHover
        })}>
        <div>
          <Sidebar />
        </div>
        <div className="app-main">
          <Header />
          <div className="app-content">
            <BackgroundImage />
            <div
              className={`app-${forceRender && !screenSizeMobile ? 'content--inner' : ' p-0 pt-4 mt-2 '}`}
              style={{ zIndex: 1 }}>
              <div className={`app-${forceRender && !screenSizeMobile ? 'content--inner__wrapper' : ''}`}>
                {children}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  ) : null
}

const mapStateToProps = (state: any) => ({
  sidebarToggle: state.theme.sidebarToggle,
  sidebarToggleMobile: state.theme.sidebarToggleMobile,
  sidebarFixed: state.theme.sidebarFixed,
  headerFixed: state.theme.headerFixed,
  headerSearchHover: state.theme.headerSearchHover,
  headerDrawerToggle: state.theme.headerDrawerToggle,
  footerFixed: state.theme.footerFixed,
  contentBackground: state.theme.contentBackground
})

export default connect(mapStateToProps)(LeftSidebar)
