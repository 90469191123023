import React from 'react'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { useMatchInfo } from 'src/views/Dashboard/hooks/useMatchInfo'
import { useTranslation } from 'react-i18next'
import colors from 'src/assets/colors.module.scss'

interface BarChartMemoProps {
  // keys: string[]
  department: string
  options?: ApexOptions
  // dataProps: DataProps<K>
  data: SeriesData[]
}

interface SeriesData {
  x: string
  y: number
  fillColor: string
  strokeColor: string
  goals?: {
    name: string
    value: number
    strokeColor: string
  }[]
}

export default React.memo((props: BarChartMemoProps) => {
  const { department, options, data } = props
  const { matchInfo } = useMatchInfo()
  const { t } = useTranslation()

  const chartOptions: ApexOptions = {
    yaxis: {
      decimalsInFloat: 0
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    colors: [colors[`${department}_100`]],
    dataLabels: {
      formatter: (val: number) => `$${Math.floor(val)}`
    },
    tooltip: {
      y: {
        formatter: (val: number) => `$${Math.floor(val)}`
      }
    }
  }

  Object.assign(chartOptions, options)

  return (
    <>
      {matchInfo && (
        <Chart
          options={chartOptions}
          series={[{ name: t('common.current'), data }]}
          type="bar"
          height={220}
        />
      )}
    </>
  )
})
