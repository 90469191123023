import React, { useState } from 'react'
import { Row, Col, CardHeader } from 'reactstrap'
import { motion } from 'framer-motion'
import { Loader, Togglable } from 'src/components'
import { Match } from 'src/store/actions/types'
import { MatchItem } from './MatchItem'
import classes from './index.module.scss'

export interface MatchesListProps {
  matches: Match[] | undefined
  title: string
  isLoading: boolean
  matchItemButtonText: (condition: boolean, condition2: boolean) => any
  onMatchItemClick: (match: Match) => void
}

function MatchesList(props: MatchesListProps) {
  const {
    title, isLoading, matches, matchItemButtonText, onMatchItemClick
  } = props

  const [filteredMatches, setFilteredMaches] = useState(matches)
  // const [mountedScroll, setMountedScroll] = useState(true)

  const onMatchDeleteSuccess = (matchId: string) => {
    // TODO remove once delete match endpoint is properly working
    const updatedMatches = filteredMatches?.filter(match => match.id !== matchId)

    setFilteredMaches(updatedMatches)
  }

  // const container = useRef(Element)

  /* options = {
    wheelPropagation: false,
  }*/

  /* useEffect(() => {
    const scrollRef = new PerfectScrollbar(container.current.arguments)

    setMountedScroll(true)
    scrollRef.componentWillUnmount = () => setMountedScroll(false)
  }, [mountedScroll])*/

  return (
    <div className="homeTourStep3">
      <CardHeader>
        <h4 className="font-size-lg mb-0 py-2 font-weight-bold">{title}</h4>
      </CardHeader>
      <Row className="no-gutters">
        <Col className="d-flex z-over align-items-center">
          <div className="text-center p-0 w-100">
            {isLoading && <Loader />}
            <motion.ul className={classes.fixUL}>
              {/* TODO translate, add custom ZeroState component for fallback */}
              <Togglable
                enabled={Boolean(matches?.length)}
                fallback={
                  <div className="p-5 font-size-md">
                    No tienes partidas en curso, revisa tus Notificaciones
                  </div>
                }>
                <div className="scroll-area scroll-area-lg">
                  {' '}
                  {/* ref={container.current.arguments}*/}
                  {/* TODO continue testing the useRef and willMount() and then remove comments */}
                  {/* <PerfectScrollbar
                    options={{
                      wheelPropagation: false,
                    }}>*/}
                  {filteredMatches?.map((match, index: number) => (
                    <MatchItem
                      index={index}
                      key={index}
                      match={match}
                      matchItemButtonText={matchItemButtonText}
                      onCallbackClick={onMatchItemClick}
                      onMatchDeletedSuccess={onMatchDeleteSuccess}
                    />
                  ))}
                  {/* </PerfectScrollbar>*/}
                </div>
              </Togglable>
            </motion.ul>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export { MatchesList }
