import { config } from 'src/config'
import { removeFromStorage } from 'src/utils/localStorage'

function logout() {
  const key = config.localStorage.keys.session

  removeFromStorage(key)
}

export { logout }
