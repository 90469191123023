import React, { ReactNode } from 'react'
import { Button as BaseButton, ButtonProps as BaseButtonProps, Spinner } from 'reactstrap'
import { Togglable } from '../Togglable'
import { ButtonWrapper } from './ButtonWrapper/ButtonWrapper'
import classes from './Button.module.scss'

export type ButtonColor = 'primary' | 'secondary' | 'info' | 'warning' | 'danger' | 'link'

export interface ButtonProps extends BaseButtonProps {

  /** Optional look for the button. Primary will be the default one */
  look?: ButtonColor

  /** Optional children */
  children?: ReactNode
  className?: string
  showSpinner?: boolean
  disabled?: boolean

  /** true if you want a pill looking like button */
  pill?: boolean
  fixedWidth?: number

  /** true for icon buttons */
  icon?: boolean
  paddingx?: number
}

/**
 * Custom button component for Company Hike
 * @param {props} props ButtonProps for the button
 * @returns JSX
 */
function Button(props: ButtonProps) {
  const {
    children,
    className,
    look = 'primary',
    showSpinner = false,
    pill = false,
    icon = false,
    fixedWidth,
    disabled,
    paddingx,
    ...restProps
  } = props

  return (
    <ButtonWrapper>
      <BaseButton
        style={fixedWidth ? { width: `${fixedWidth}px` } : {}}
        className={`${pill ? 'btn-pill' : ''} ${icon &&
          'pt-0'} font-weight-bold px-${paddingx} font-size-sm ${className} ${classes.Button}`}
        color={look}
        disabled={disabled}
        {...restProps}>
        <Togglable enabled={!showSpinner} fallback={<Spinner type="border" size="sm" />}>
          {children}
        </Togglable>
      </BaseButton>
    </ButtonWrapper>
  )
}

export { Button }
