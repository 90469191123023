import { Dispatch } from 'redux'
import { client } from 'src/api/client'
import { snakeize } from 'src/utils/camelize'
import { CreateMatchDispatchTypes, MatchData } from '../types/match/createMatch'
import * as actionTypes from '../types/match'

// TODO: camelize
const getMatchSchema = () => async (dispatch: Dispatch<CreateMatchDispatchTypes>) => {
  try {
    dispatch({
      type: actionTypes.GET_MATCH_SCHEMA_LOADING
    })
    const res = await client.get('match/schema')

    // FIXME: temporal patch until backend fixes this typo
    const fixedRes = {
      ceo_parameters: {
        ...res.data.ceo_parameters
      },
      government_parameters: {
        ...res.data.goverment_parameters
      },
      match_parameters: {
        ...res.data.game_parameters,
        government_side: res.data.game_parameters.goverment_side
      }

    }

    dispatch({
      type: actionTypes.GET_MATCH_SCHEMA_SUCCESS,
      payload: fixedRes
    })
  } catch (e) {
    dispatch({
      type: actionTypes.GET_MATCH_SCHEMA_FAILS
    })
  }
}

const addMatchData = (data: MatchData) => ({
  type: actionTypes.ADD_MATCH_DATA,
  payload: data
})

const resetMatchData = () => ({
  type: actionTypes.RESET_MATCH_DATA
})

const postMatchData = (data: {
  // TODO: check where are the shared places were we send community_id and version and abstract

  community_id: string,
  data: MatchData,
  version: string
}) => async (dispatch: Dispatch<CreateMatchDispatchTypes>) => {
  try {
    dispatch({
      type: actionTypes.POST_MATCH_DATA_LOADING
    })

    // TEMPFIX: for backend typo on "government"
    const { governmentSide: _, ...dataWithoutGovernment } = data.data

    const fixedData = {
      ...data,
      data: {
        ...dataWithoutGovernment,
        govermentSide: data.data.governmentSide
      }
    }

    const res = await client.post('match/create', snakeize(fixedData))

    dispatch({
      type: actionTypes.POST_MATCH_DATA_SUCCESS,
      payload: res.data
    })
    return res
  } catch (e) {
    dispatch({
      type: actionTypes.POST_MATCH_DATA_FAILS
    })
  }
  return null
}

export {
  postMatchData, resetMatchData, addMatchData, getMatchSchema
}
