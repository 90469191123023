// import { AxiosResponse } from 'axios'
import { Dispatch } from 'react'
import { State } from 'src/store/reducers/rootReducer'
// import { client } from 'src/api'
// import { camelize } from 'src/utils/camelize'
// import { User } from '../types'
import {
  RemoveCommunityMemberDispatchTypes, removeCommunityMembersActions as actions
} from '../types/community/removeCommunityMembers'

function removeCommunityMember(userId: string) {
  return async (dispatch: Dispatch<RemoveCommunityMemberDispatchTypes>, getState: ()=> State) => {
    try {
      dispatch({
        type: actions.REMOVE_COMMUNITY_MEMBERS_LOADING
      })
      // TODO (BE blocker) call endpoint once it exists
      // const { data } = await client.delete<any, AxiosResponse<Record<string, unknown>[]>>(`community/member/${userId}`)
      // const camelizedResponse = data.map(communityMember => camelize(communityMember)) as unknown as User[]

      dispatch({
        type: actions.REMOVE_COMMUNITY_MEMBERS_SUCCESS,
        payload: {
          // FIXME: tempfix since we dont have delete endpoint working
          communityMembers: getState().communities.communityMembers.filter(member => member.id !== userId),
          userId
        }
      })
    } catch (e) {
      dispatch({
        type: actions.REMOVE_COMMUNITY_MEMBERS_FAIL
      })
    }
  }
}
export { removeCommunityMember }