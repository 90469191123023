export const SET_PREVIEW = 'SET_PREVIEW'
export const PREVIEW_LOADING = 'PREVIEW_LOADING'
export const PREVIEW_FAIL = 'PREVIEW_FAIL'
export const PREVIEW_SUCCESS = 'PREVIEW_SUCCESS'
export const RESET_PREVIEW = 'RESET_PREVIEW'

export interface Preview {
  match: Match;
}

type Match = {
  matchId: string,
  name: string,
  type: string,
  isGoverment: boolean,
  isCeo: boolean,
  playerPosition: number,
  currentStage: number,
  isAdmin: boolean, // se removió del back
  createdAt: string
}

/* { Ref: previous obj
  "match_id": 4,
  "name": "Test Match",
  "type": "scenario",
  "is_goverment": true,
  "is_ceo": false,
  "player_position": "-",
  "current_stage": 0,
  "created_at": "18/12/2021 16:29"
}*/

export interface SetPreview {
  type: typeof SET_PREVIEW;
  payload: Preview;
}

export interface PreviewLoading {
  type: typeof PREVIEW_LOADING;
}

export interface PreviewFail {
  type: typeof PREVIEW_FAIL;
}

export interface PreviewSuccess {
  type: typeof PREVIEW_SUCCESS;
  payload: Preview;
}

export interface ResetPreview {
  type: typeof RESET_PREVIEW;
  payload: Preview;
}

export type PreviewDispatchTypes =
  | PreviewLoading
  | PreviewFail
  | PreviewSuccess
  | ResetPreview;

export type SetPreviewDispatchT = SetPreview | Preview | ResetPreview;