import { Dispatch } from 'redux'
import { client } from 'src/api/client'
import {
  SetRankingDispatch, RankingDispatchTypes, Ranking, Player
} from './types/ranking'
import * as actionTypes from './types'
import { User } from './types'

const setRanking = (ranking: Ranking): SetRankingDispatch => ({
  type: actionTypes.RANKING_SET,
  payload: ranking
})

const setRankingData = (data: any) => {
  const ranking: Player[] = []
  const rankingLength: number = Object.keys(data.ranking).length

  for (let i = 0; i < rankingLength; i++) {
    const position = i + 1
    const player = data.ranking[position]

    ranking.push(player)
  }

  const rankingData: Ranking = {
    ranking,
    user_ranking: data.user_ranking
  }

  return rankingData
}

const getRankingGlobal = () => async (dispatch: Dispatch<RankingDispatchTypes>) => {
  try {
    dispatch({
      type: actionTypes.RANKING_GLOBAL_LOADING
    })

    const res = await client.get('ranking/global')

    const rankingData = setRankingData(res.data)

    dispatch({
      type: actionTypes.RANKING_GLOBAL_SUCCESS,
      payload: rankingData
    })
  } catch (e) {
    dispatch({
      type: actionTypes.RANKING_GLOBAL_FAIL
    })
  }
}

const getRankingByComunnity = (communityId: string, user: User | undefined) => async (dispatch: Dispatch<RankingDispatchTypes>) => {
  try {
    dispatch({
      type: actionTypes.RANKING_COMMUNITY_LOADING
    })

    const res = await client.get(`ranking/community/${communityId}`)

    const rankingData = setRankingData(res.data)

    const value = user
      ? `${user?.name} ${user?.lastName}: ${user?.id}`
      : 'No data available'

    dispatch({
      type: actionTypes.RANKING_COMMUNITY_SUCCESS,
      payload: rankingData,
      meta: {
        analytics: {
          event: 'fetch_community_ranking',
          category: 'Ranking test',
          action: 'Fetch Success',
          label: 'ranking',
          value
        }
      }
    })
  } catch (e) {
    dispatch({
      type: actionTypes.RANKING_COMMUNITY_FAIL
    })
  }
}

export { getRankingGlobal, setRanking, getRankingByComunnity }