import React, {
  FC, ReactElement, useEffect, useState
} from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
// import { Row, Col } from 'reactstrap'
import { Loader, Togglable } from 'src/components'
// import Ribbon from 'src/components/Ribbons/Ribbon'
import { CeoComparison, CeosData } from 'src/utils/dataMatchObj'
import { ceoPosition, compareCeosPropByStage } from 'src/utils/dataMatchServices'
import { useUser } from 'src/hooks/useUser'
import { setSwitchBackground } from '../../store/actions/theme'
import { useMatchInfo } from '../Dashboard/hooks/useMatchInfo'
import BannerResults from './BannerResults/BannerResults'

export type EndGameParams = {
  id: string
}

const EndGame: FC = (): ReactElement | any => {
  const history = useHistory()
  const [matchId, setMatchId] = useState<string | undefined>()

  const [ceosData, setCeosData] = useState<Array<CeoComparison>>([])
  const [isLoaded, setIsLoaded] = useState(false)
  const dispatch = useDispatch()
  const params: EndGameParams = useParams()
  const { matchInfo } = useMatchInfo(matchId)
  const id = useUser()?.id
  const matchData = matchInfo.data?.ceos
  const earnings = 'earnings'

  useEffect(() => {
    if (String(history.location.pathname).substr(0, 8) === '/endgame') {
      dispatch(setSwitchBackground(true, 'ROUND_ENDS'))
    } else {
      dispatch(setSwitchBackground(true))
    }
    if (matchInfo && matchData) {
      const data: CeosData = { ceos: [] }

      data.ceos = matchData

      const comparisons: CeoComparison[] = compareCeosPropByStage(
        { ceos: data.ceos },
        earnings,
        matchInfo.data?.lastResultsStage as number
      )

      setCeosData(comparisons)
      setIsLoaded(true)
    }
  }, [dispatch, history.location.pathname, matchData, matchInfo])

  useEffect(() => {
    if (params.id) {
      setMatchId(params.id)
    }
  }, [params])

  useEffect(() => {
    if (matchInfo.isMatchNotFound) {
      history.push('/')
    }
  }, [history, matchInfo.isMatchNotFound])

  return (
    <Togglable enabled={isLoaded} fallback={<Loader />}>
      <BannerResults position={ceoPosition(isLoaded, ceosData, Number(id))} matchId={matchId} />
    </Togglable>
  )
}

export default EndGame
export { EndGame }
