import * as actionTypes from './types'

export const setSidebarShadow = (sidebarShadow: any) => ({
  type: actionTypes.SET_SIDEBAR_SHADOW,
  sidebarShadow,
})

export const setSidebarFixed = (sidebarFixed: any) => ({
  type: actionTypes.SET_SIDEBAR_FIXED,
  sidebarFixed,
})

export const setSidebarStyle = (sidebarStyle: any) => ({
  type: actionTypes.SET_SIDEBAR_STYLE,
  sidebarStyle,
})

export const setSidebarFooter = (sidebarFooter: any) => ({
  type: actionTypes.SET_SIDEBAR_FOOTER,
  sidebarFooter,
})

export const setSidebarToggleMobile = (sidebarToggleMobile: any) => ({
  type: actionTypes.SET_SIDEBAR_TOGGLE_MOBILE,
  sidebarToggleMobile,
})

export const setSidebarToggle = (sidebarToggle: any) => ({
  type: actionTypes.SET_SIDEBAR_TOGGLE,
  sidebarToggle,
})

export const setSidebarUserbox = (sidebarUserbox: any) => ({
  type: actionTypes.SET_SIDEBAR_USERBOX,
  sidebarUserbox,
})

export const setHeaderFixed = (headerFixed: any) => ({
  type: actionTypes.SET_HEADER_FIXED,
  headerFixed,
})

export const setHeaderShadow = (headerShadow: any) => ({
  type: actionTypes.SET_HEADER_SHADOW,
  headerShadow,
})

export const setHeaderBgTransparent = (headerBgTransparent: any) => ({
  type: actionTypes.SET_HEADER_BG_TRANSPARENT,
  headerBgTransparent,
})

export const setHeaderSearchHover = (headerSearchHover: any) => ({
  type: actionTypes.SET_HEADER_SEARCH_HOVER,
  headerSearchHover,
})

export const setHeaderDrawerToggle = (headerDrawerToggle: any) => ({
  type: actionTypes.SET_HEADER_DRAWER_TOGGLE,
  headerDrawerToggle,
})

export const setContentBackground = (contentBackground: any) => ({
  type: actionTypes.SET_CONTENT_BACKGROUND,
  contentBackground,
})

export const setSwitchBackground = (isIlustrationBackground: boolean, matchStatusImage:string = '') => ({
  type: actionTypes.SET_SWITCH_BACKGROUND,
  isIlustrationBackground,
  matchStatusImage,
})

export const setThemeConfiguratorToggle = (themeConfiguratorToggle: any) => ({
  type: actionTypes.SET_THEME_CONFIGURATOR_TOGGLE,
  themeConfiguratorToggle,
})

export const setFooterFixed = (footerFixed: any) => ({
  type: actionTypes.SET_FOOTER_FIXED,
  footerFixed,
})

export const setFooterShadow = (footerShadow: any) => ({
  type: actionTypes.SET_FOOTER_SHADOW,
  footerShadow,
})

export const setFooterBgTransparent = (footerBgTransparent: any) => ({
  type: actionTypes.SET_FOOTER_BG_TRANSPARENT,
  footerBgTransparent,
})

export const setPageTitleStyle = (pageTitleStyle: any) => ({
  type: actionTypes.SET_PAGE_TITLE_STYLE,
  pageTitleStyle,
})

export const setPageTitleBackground = (pageTitleBackground: any) => ({
  type: actionTypes.SET_PAGE_TITLE_BACKGROUND,
  pageTitleBackground,
})

export const setPageTitleShadow = (pageTitleShadow: any) => ({
  type: actionTypes.SET_PAGE_TITLE_SHADOW,
  pageTitleShadow,
})

export const setPageTitleBreadcrumb = (pageTitleBreadcrumb: any) => ({
  type: actionTypes.SET_PAGE_TITLE_BREADCRUMB,
  pageTitleBreadcrumb,
})

export const setPageTitleIconBox = (pageTitleIconBox: any) => ({
  type: actionTypes.SET_PAGE_TITLE_ICON_BOX,
  pageTitleIconBox,
})

export const setPageTitleDescription = (pageTitleDescription: any) => ({
  type: actionTypes.SET_PAGE_TITLE_DESCRIPTION,
  pageTitleDescription,
})
