import { AxiosResponse } from 'axios'
import { client, LoginResponse } from 'src/api'
import { config } from 'src/config'
import { setInStorage, } from 'src/utils/localStorage'

interface UserCredentials {
  email: string
  password: string
}

const loginUrl = 'auth/login'
const isLoginRequest = (url: string | undefined): Boolean => url === loginUrl
const key = config.localStorage.keys.session

/**
   * Logins user with username and password
   * @param credentials UserCredentials
   * @returns User data
   * @author Company Hike
   */

async function login(credentials: UserCredentials): Promise<LoginResponse> {
  try {
    const userCredentials: AxiosResponse<LoginResponse> = await client.post(loginUrl, {
      email: credentials.email,
      password: credentials.password
    })
    const { access_token, expires_in } = userCredentials.data

    setInStorage(key, {
      token: access_token,
      expirationDate: Date.now() + expires_in * 1000
    })
    return userCredentials.data
  } catch (error) {
    throw new Error(`Error on login ${error}`)
  }
}


export { login, isLoginRequest }
