import React, { FC } from 'react'

import { CardBody, Card } from 'reactstrap'

interface Props {
  children: any
  marginBottom?: number
}

const ExampleWrapperSimple: FC<Props> = (props: Props) => {
  const { children, marginBottom } = props

  return (
    <Card className={`card-box mb-${marginBottom}`}>
      {/* <CardHeader>
          <div className="card-header--title font-size-md font-weight-bold py-2">
            {this.props.sectionHeading}
          </div>
        </CardHeader> */}
      <CardBody style={{ padding: '0.7rem' }}>{children}</CardBody>
    </Card>
  )
}

export default ExampleWrapperSimple
