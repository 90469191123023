import { combineReducers } from 'redux'
import { userReducer } from './user'
import { previewReducer } from './preview'
import { matchInfoReducer } from './matchInfo'
import { themeReducer } from './theme'
import { communitiesReducer } from './community'
import { rankingReducer } from './ranking'
import { decisionsReducer } from './createRoundDecisions'
import { matchesReducer } from './matches/matches'
import { userConfigReducer } from './userConfig'
import { notificationListReducer } from './notifications/notifications'
import { acceptMatchInvitationReducer } from './notifications/acceptInvitation'
import { invitationsReducer } from './invitations'
import ceoDecisionsReducer from './ceoDecisions/ceoDecisions'
import { imagesReducer } from './images'

const rootReducer = combineReducers({
  user: userReducer,
  userConfig: userConfigReducer,
  notifications: notificationListReducer,
  invitations: acceptMatchInvitationReducer,
  preview: previewReducer,
  matchInfo: matchInfoReducer,
  theme: themeReducer,
  match: matchesReducer,
  communities: communitiesReducer,
  ranking: rankingReducer,
  decisions: decisionsReducer,
  ceoDecisions: ceoDecisionsReducer,
  invitationsList: invitationsReducer,
  images: imagesReducer
})

export type State = ReturnType<typeof rootReducer>
export { rootReducer }