import { getCommunityMembersActions } from './getCommunityMembers'
import { removeCommunityMembersActions } from './removeCommunityMembers'
import { setCommunityActions } from './setCommunity'

const actionTypes = {
  ...getCommunityMembersActions,
  ...removeCommunityMembersActions,
  ...setCommunityActions
}

export { actionTypes }