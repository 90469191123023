export type CountriesProps = {
  selectedCountry: string | undefined

}

export type statsProps = {
  selectedCountry: string | undefined
  data: Country
}

export type Country = {
  code?: string
  economy?: string
  region?: string
  incomeGroup?: string
  pib: number
  easeDoingBusiness: number
  easeDoingBusinessScore: number
  internalCredit: number
  salary: number
  vatTax: number
  scorePayingTaxes: number
  totalTaxAndContributionRate: number
  profitTax: number
  laborTax: number
  otherTaxes: number
  interestrate: number
}

export const countryListData: Array<Country> = [
  {
    code: 'usa',
    economy: 'EE.UU',
    region: 'North America',
    incomeGroup: 'Altos ingresos',
    pib: 59.920,
    easeDoingBusiness: 6,
    easeDoingBusinessScore: 85,
    internalCredit: 179.28,
    salary: 1.187,
    vatTax: 12,
    scorePayingTaxes: 84.1,
    totalTaxAndContributionRate: 43.8,
    profitTax: 27.9,
    laborTax: 9.8,
    otherTaxes: 6.1,
    interestrate: 0.75,
  },

  {
    code: 'chn',
    economy: 'China',
    region: 'East Asia & Pacific',
    incomeGroup: 'Ingresos medio/altos',
    pib: 16.310,
    easeDoingBusiness: 84,
    easeDoingBusinessScore: 65,
    internalCredit: 161,
    salary: 301,
    vatTax: 17,
    scorePayingTaxes: 63.3,
    totalTaxAndContributionRate: 66.5,
    profitTax: 11.3,
    laborTax: 48.1,
    otherTaxes: 7,
    interestrate: 3.7,

  },

  {
    code: 'arg',
    economy: 'Argentina',
    region: 'America Latina y Caribe',
    incomeGroup: 'Ingresos medio/altos',
    pib: 19.691,
    easeDoingBusiness: 122,
    easeDoingBusinessScore: 57.3,
    internalCredit: 15.98,
    salary: 425,
    vatTax: 21,
    scorePayingTaxes: 49.3,
    totalTaxAndContributionRate: 106,
    profitTax: 35,
    laborTax: 29.3,
    otherTaxes: 72.8,
    interestrate: 49,
  },

  {
    code: 'bra',
    economy: 'Brazil',
    region: 'America Latina y Caribe',
    incomeGroup: 'Ingresos medio/altos',
    pib: 14.064,
    easeDoingBusiness: 128,
    easeDoingBusinessScore: 55.6,
    internalCredit: 61.22,
    salary: 207,
    vatTax: 17,
    scorePayingTaxes: 34.4,
    totalTaxAndContributionRate: 65.1,
    profitTax: 22.4,
    laborTax: 39.4,
    otherTaxes: 3.3,
    interestrate: 12.75,
  },

  {
    code: 'mex',
    economy: 'Mexico',
    region: 'America Latina y Caribe',
    incomeGroup: 'Ingresos medio/altos',
    pib: 17.852,
    easeDoingBusiness: 48,
    easeDoingBusinessScore: 72.5,
    internalCredit: 34.6,
    salary: 188,
    vatTax: 16,
    scorePayingTaxes: 66.5,
    totalTaxAndContributionRate: 53.4,
    profitTax: 26.9,
    laborTax: 25.6,
    otherTaxes: 0.9,
    interestrate: 7,
  },

  {
    code: 'col',
    economy: 'Colombia',
    region: 'America Latina y Caribe',
    incomeGroup: 'Ingresos medio/altos',
    pib: 13.449,
    easeDoingBusiness: 62,
    easeDoingBusinessScore: 69,
    internalCredit: 50.04,
    salary: 231,
    vatTax: 16,
    scorePayingTaxes: 57.9,
    totalTaxAndContributionRate: 72.3,
    profitTax: 21.8,
    laborTax: 18.6,
    otherTaxes: 31.8,
    interestrate: 6,
  },
  {
    code: 'uru',
    economy: 'Uruguay',
    region: 'America Latina y Caribe',
    incomeGroup: 'Altos ingresos',
    pib: 21.608,
    easeDoingBusiness: 101,
    easeDoingBusinessScore: 61.5,
    internalCredit: 27.35,
    salary: 433,
    vatTax: 22,
    scorePayingTaxes: 70.3,
    totalTaxAndContributionRate: 41.8,
    profitTax: 23.6,
    laborTax: 15.6,
    otherTaxes: 2.6,
    interestrate: 9.25,
  },
]


// TODO:
/*
{
    code: 'ecu',
    economy: 'Ecuador',
    region: 'America Latina y Caribe',
    incomeGroup: 'Ingresos medio/altos',
    pib: 10.329,
    easeDoingBusiness: 129,
    easeDoingBusinessScore: 57.7,
    internalCredit: 35.71,
    salary: 257,
    vatTax: 12,
    scorePayingTaxes: 58.4,
    totalTaxAndContributionRate: 32.5,
    profitTax: 16.3,
    laborTax: 13.7,
    otherTaxes: 2.5,
    interestrate: 8.28,
  },
  {
    code: 'chl',
    economy: 'Chile',
    region: 'Altos ingresos: OECD',
    incomeGroup: 'Altos ingresos',
    pib: 23.325,
    easeDoingBusiness: 59,
    easeDoingBusinessScore: 72.6,
    internalCredit: 116.65,
    salary: 410,
    vatTax: 19.00,
    scorePayingTaxes: 75.3,
    totalTaxAndContributionRate: 34,
    profitTax: 26.2,
    laborTax: 5.1,
    otherTaxes: 2.6,
    interestrate: 9.05,
  },

  {
    code: 'ury',
    economy: 'Uruguay',
    region: 'America Latina y Caribe',
    incomeGroup: 'Altos ingresos',
    pib: 21.608,
    easeDoingBusiness: 101,
    easeDoingBusinessScore: 61.5,
    internalCredit: 27.35,
    salary: 433,
    vatTax: 22,
    scorePayingTaxes: 70.3,
    totalTaxAndContributionRate: 41.8,
    profitTax: 23.6,
    laborTax: 15.6,
    otherTaxes: 2.6,
    interestrate: 9.25,
  },

  {
    code: 'per',
    economy: 'Peru',
    region: 'America Latina y Caribe',
    incomeGroup: 'Ingresos medio/altos',
    pib: 6.711,
    easeDoingBusiness: 65,
    easeDoingBusinessScore: 67.8,
    internalCredit: 44.01,
    salary: 269,
    vatTax: 18,
    scorePayingTaxes: 66.2,
    totalTaxAndContributionRate: 35.6,
    profitTax: 21.4,
    laborTax: 11,
    otherTaxes: 3.2,
    interestrate: 5.5,
  },

  {
    code: 'pry',
    economy: 'Paraguay',
    region: 'America Latina y Caribe',
    incomeGroup: 'Ingresos medio/altos',
    pib: 11.261,
    easeDoingBusiness: 118,
    easeDoingBusinessScore: 58,
    internalCredit: 43.64,
    salary: 343,
    vatTax: 10,
    scorePayingTaxes: 64.1,
    totalTaxAndContributionRate: 35,
    profitTax: 9.6,
    laborTax: 18.6,
    otherTaxes: 6.7,
    interestrate: 7.25,
  },

  {
    code: 'bol',
    economy: 'Bolivia',
    region: 'America Latina y Caribe',
    incomeGroup: 'Lower middle income',
    pib: 7.845,
    easeDoingBusiness: 154,
    easeDoingBusinessScore: 51,
    internalCredit: 65.92,
    salary: 289,
    vatTax: 13,
    scorePayingTaxes: 21.6,
    totalTaxAndContributionRate: 83.7,
    profitTax: 0,
    laborTax: 18.8,
    otherTaxes: 64.8,
    interestrate: 2.94,
  },
  {
    code: 'esp',
    economy: 'Spain',
    region: 'Europe',
    incomeGroup: 'Altos ingresos',
    pib: 20.333,
    easeDoingBusiness: 37,
    easeDoingBusinessScore: 65,
    internalCredit: 43,
    salary: 850,
    vatTax: 15,
    scorePayingTaxes: 45,
    totalTaxAndContributionRate: 50,
    profitTax: 29,
    laborTax: 30,
    otherTaxes: 30,
    interestrate: 2.94,
  }*/

export const getCountryHeadline = (selection: string): string => {
  const selected = countryListData[countryListData.findIndex(el => el.code === selection)]

  return ` | ${selected.region} | ${selected.incomeGroup} | `
}