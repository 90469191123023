import { Dispatch } from 'react'
import { client } from 'src/api'
// import { camelize } from 'src/utils/camelize'
// import { User } from '../types'
import { actions, RemoveMatchDispatchTypes } from '../types/match/removeMatch'

function removeMatch(matchId: string) {
  return async (dispatch: Dispatch<RemoveMatchDispatchTypes>) => {
    try {
      dispatch({
        type: actions.REMOVE_MATCH_LOADING
      })
      // TODO (BE blocker) call endpoint once it exists
      // const { data } = await client.post<any, AxiosResponse<Record<string, unknown>[]>>(`/api/match/delete_match/${matchId}`)
      const res = await client.post('game/delete_game', { game_id: matchId })
      // const camelizedResponse = data.map(match => camelize(...))

      dispatch({
        type: actions.REMOVE_MATCH_SUCCESS,
        payload: res.data
      })
    } catch (e) {
      dispatch({
        type: actions.REMOVE_MATCH_FAIL
      })
    }
  }
}
export { removeMatch }