export const SET_USER = 'SET_USER'
export const USER_LOADING = 'USER_LOADING'
export const USER_FAIL = 'USER_FAIL'
export const USER_SUCCESS = 'USER_SUCCESS'

export interface User {
  id: string
  isAdmin: boolean
  name: string
  lastName: string
  language: string
  email: string
  communities: UserCommunity[]
  firstLogin: boolean
  pendingMatchInvitation: boolean
  avatar?: string
  user_data: any
  stats: any
}

export interface UserApi {
  id: string
  is_admin: boolean
  name: string
  last_name: string
  language: string
  email: string
  communities: UserCommunity[]
  first_login: boolean
  pending_match_invitation: boolean
  avatar?: string
  user_data: any
  stats: any
}


export type UserCommunity = {
  id: string
  name: string
  isAdmin: boolean
  logo?: string
}

export interface SetUser {
  type: typeof SET_USER
  payload: User
}

export interface UserLoading {
  type: typeof USER_LOADING
}

export interface UserFail {
  type: typeof USER_FAIL
}

export interface UserSuccess {
  type: typeof USER_SUCCESS
  payload: User
}

export type UserDispatchTypes = UserLoading | UserFail | UserSuccess
export type SetUserDispatch = SetUser | User
