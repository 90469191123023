import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { AnimatePresence } from 'framer-motion'
import { SuspenseLoading } from './components'
import { BaseRouter } from './router/router'
import { ScrollToTop } from './utils/ScrollToTop'
import { store } from './store'
import './assets/base.scss'

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <AnimatePresence>
            <Suspense fallback={<SuspenseLoading />}>
              <BaseRouter />
            </Suspense>
          </AnimatePresence>
        </ScrollToTop>
        <ToastContainer enableMultiContainer={true} containerId="A" position={toast.POSITION.BOTTOM_RIGHT} />
      </BrowserRouter>
    </Provider>
  )
}

export { App }
