import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal as BaseModal } from 'reactstrap'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center ;
  justify-content: center ;
  flex-wrap : wrap;
`

const Modal = styled(BaseModal)``

const ModalWrapper = styled.div`
  text-align: center;
  padding: 3rem;
`

const IconContainer = styled.div`
  display: inline-block;
  position: relative;
  font-size: 1rem;
  border-radius: 100%;
  margin: 0;
  display: inline-flex;
  justify-content: center ;
  padding: 0;
  /* text-success d-130 bg-neutral-success */
  background-color: red;

  .badge {
    position: absolute;
    right: 0;
    bottom: 0;
  }
          
`
const Icon = styled(FontAwesomeIcon)`
  display: flex;
  align-self: center ;
  font-size: 4rem;
`

const QuestionText = styled.h4`
  font-weight: bold ;
  margin-top: 2rem;
`

const Description = styled.div`
margin-bottom:0;
font-size: 1.2em;
/* TODO: save as text muted color in template */
color: #d1d2db
`


export {
  Container, Modal, ModalWrapper, IconContainer, Icon, QuestionText, Description
}