import React, { FC } from 'react'

import { Row, Col } from 'reactstrap'
import { ExampleWrapperSeamless } from '../../layoutComponents/'
import RibbonContent from './ElementsRibbons/Ribbons2/RibbonContent'

interface RibbonProps {
  matchId: any
}

const Ribbon: FC<RibbonProps> = (props: RibbonProps) => {
  const { matchId } = props

  return (
    <Row xl="12" style={{ marginTop: '25%' }}>
      <Col md="6" />
      <Col md="6" className="flex-end">
        <ExampleWrapperSeamless className="flex-end">
          <RibbonContent matchId={matchId} />
        </ExampleWrapperSeamless>
      </Col>
    </Row>
  )
}

export default Ribbon
