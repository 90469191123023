import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { ApprovedDecisions, DepartmentName, State } from 'src/store'
import DepartmentCard from './DepartmentCard'

const ExecutiveCards: FC = () => {
  const headerDrawerToggle = useSelector((state: State) => state.theme.headerDrawerToggle)
  const { approved } = useSelector((state: State) => state.ceoDecisions.data)

  const departmentCardIcons = useMemo(
    () => ({
      marketing: 'lightbulb',
      production: 'industry',
      finance: 'poll'
    }),
    []
  )

  const renderDepartmentCards = useMemo(
    () => approved &&
      Object.entries(approved).map((entry, index) => {
        const [dep, status] = entry
        const iconName = departmentCardIcons[dep as DepartmentName] as IconName

        return (
          <DepartmentCard
            approved={status}
            department={dep as keyof ApprovedDecisions}
            key={index}
            icon={['fas', iconName]}
            animateDelay={0.02 * (index + 1)}
          />
        )
      }),
    [approved, departmentCardIcons]
  )

  return <>{headerDrawerToggle && renderDepartmentCards}</>
}

export default ExecutiveCards
