export const ADD_USER_TO_MATCH_LOADING = 'ADD_USER_TO_MATCH_LOADING'
export const ADD_USER_TO_MATCH_FAIL = 'ADD_USER_TO_MATCH_FAIL'
export const ADD_USER_TO_MATCH_SUCCESS = 'ADD_USER_TO_MATCH_SUCCESS'
export const REMOVE_USER_FROM_MATCH_FAIL = 'REMOVE_USER_FROM_MATCH_FAIL'
export const REMOVE_USER_FROM_MATCH_LOADING = 'REMOVE_USER_FROM_MATCH_LOADING'
export const REMOVE_USER_FROM_MATCH_SUCCESS = 'REMOVE_USER_FROM_MATCH_SUCCESS'
export const GET_INVITATIONS_LOADING = 'GET_INVITATIONS_LOADING'
export const GET_INVITATIONS_FAILS = 'GET_INVITATIONS_FAILS'
export const GET_INVITATIONS_SUCCESS = 'GET_INVITATIONS_SUCCESS'

export interface InvitationSent {
  communityId: string
  matchId: string
  userId: string
  accepted?: boolean | null
  invitationId?: number
}

export interface AddUserToMatchLoading {
  type: typeof ADD_USER_TO_MATCH_LOADING;
}

export interface AddUserToMatchFail {
  type: typeof ADD_USER_TO_MATCH_FAIL;
}

export interface AddUserToMatchSuccess {
  type: typeof ADD_USER_TO_MATCH_SUCCESS;
  payload: InvitationSent;
}

export interface RemoveUserFromMatchLoading {
  type: typeof REMOVE_USER_FROM_MATCH_LOADING
}

export interface RemoveUserFromMatchFail {
  type: typeof REMOVE_USER_FROM_MATCH_FAIL
}

export interface RemoveUserFromMatchSuccess {
  type: typeof REMOVE_USER_FROM_MATCH_SUCCESS,
  payload: InvitationSent
}

export interface GetInvitationsLoading {
  type: typeof GET_INVITATIONS_LOADING
}

export interface GetInvitationsFails {
  type: typeof GET_INVITATIONS_FAILS
}

export interface GetInvitationsSuccess {
  type: typeof GET_INVITATIONS_SUCCESS,
  payload: InvitationSent[]
}

export type InvitationsDispatchTypes =
  | AddUserToMatchLoading
  | AddUserToMatchFail
  | AddUserToMatchSuccess
  | RemoveUserFromMatchLoading
  | RemoveUserFromMatchFail
  | RemoveUserFromMatchSuccess
  | GetInvitationsLoading
  | GetInvitationsFails
  | GetInvitationsSuccess