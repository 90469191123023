import {
  CreateRoundDecisionsDispatchTypes,
  DecisionsData,
  DecisionsSchema
} from '../actions/types'
import * as actionTypes from '../actions/types'

interface DecisionsState {
  loading: boolean
  data: DecisionsData

  schema?: DecisionsSchema
  postRes?: unknown
}

const initialDecisionsData = {
  step: 0
}

const initialState: DecisionsState = {
  loading: false,
  schema: undefined,
  data: initialDecisionsData
}

const decisionsReducer = (
  state: DecisionsState = initialState,
  action: CreateRoundDecisionsDispatchTypes
): DecisionsState => {
  switch (action.type) {
    case actionTypes.GET_DECISIONS_SCHEMA_FAILS:
      return {
        ...state,
        loading: false
      }
    case actionTypes.GET_DECISIONS_SCHEMA_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.GET_DECISIONS_SCHEMA_SUCCESS:
      return {
        ...state,
        loading: false,
        schema: {
          ...action.payload,
          decisions_parameters: {
            ...action.payload.decisions_parameters
          }
        }
      }
    case actionTypes.ADD_DECISIONS_DATA:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...action.payload
        }
      }
    case actionTypes.RESET_DECISIONS_DATA:
      return {
        ...state,
        loading: false,
        data: initialDecisionsData
      }
    case actionTypes.POST_DECISIONS_DATA_FAILS:
      return {
        ...state,
        loading: false
      }
    case actionTypes.POST_DECISIONS_DATA_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.POST_DECISIONS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: initialDecisionsData,
        postRes: action.payload
      }
    default:
      return state
  }
}

export default decisionsReducer
export { decisionsReducer }
export type { DecisionsState }