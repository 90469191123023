/* eslint-disable valid-jsdoc */
// eslint-disable-next-line max-len
/**
 * Limit the max amount of words of a string
 */
export const limitMaxWords = (string: string, max: number): string => `${string
  .split(' ')
  .slice(0, max)
  .join(' ')}`

/**
 * Returns the current year
 */
export const currentYear = () => new Date().getFullYear()

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export const getRandomInt = (_min: number, _max: number): number => {
  const min = Math.ceil(_min)
  const max = Math.floor(_max)

  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * Returns a number with only one decimal
 * @param num number
 */
export const toOneDec = (num: number) => Math.round(num * 10) / 10

/** Custom function that calculates the percent of a number.
 * @param float percent The percent that you want to get.
 * @param float|int num The number that you want to calculate the percent of.
 * @returns {Number}
 */
export const calculatePercent = (percent: number, num: number): number => (percent / 100) * num

/**
 * get the percentage representation of a number in relation with other
 * @param number the partial number
 * @param total the total you want to compare with
 */
export const percentageRepresented = (number: number, total: number): number => (number * 100) / total

/**
 * Returns an array on number between the given interval
 * @param min number
 * @param max number
 */

export const getIntervalArray = (min: number = 0, max: number = 10): number[] => {
  const res = []

  for (let i = min; i <= max; i++) {
    res.push(i)
  }
  return res
}
