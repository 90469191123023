import React from 'react'
import { BeatLoader } from 'react-spinners'

/**
 * Blue Loader centered on container (color to be defined with palette)
 * @returns undefined
 */
function SuspenseLoading() {
  return (
    <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
      <div className="d-flex align-items-center flex-column px-4">
        {/* TODO: use theme colors */}
        <BeatLoader color="#3c44b1" loading={true} />
      </div>
    </div>
  )
}

export { SuspenseLoading }
