import { toast, Flip } from 'react-toastify'
import {
  MatchInfoDispatchTypes, MatchInfo, Company, Ceo
} from '../actions/types/matchInfo'
import * as actionTypes from '../actions/types'

export interface MatchInfoStateI {
  loading: boolean,
  data?: MatchInfo,
  companyCreated: boolean,
  companyDetails?: Company,
  isMatchNotFound?: boolean,
  ceoSelectedInAdminMode?: Ceo,
  departmentSelectedInAdminMode?: string,
  firstCeoAsDefaultInAdminMode?: boolean
}
const initialState: MatchInfoStateI = {
  loading: false,
  data: undefined,
  companyCreated: false,
  companyDetails: undefined,
  isMatchNotFound: undefined,
  ceoSelectedInAdminMode: undefined,
  departmentSelectedInAdminMode: undefined,
  firstCeoAsDefaultInAdminMode: false
}

const matchInfoReducer = (
  state: MatchInfoStateI = initialState,
  action: MatchInfoDispatchTypes,
): MatchInfoStateI => {
  switch (action.type) {
    case actionTypes.MATCH_INFO_FAIL:
      // todo: manage toasts outside reducer
      toast.error('Match not found, please try again.', {
        containerId: 'A',
        transition: Flip,
      })
      return {
        ...state,
        loading: false,
        companyCreated: false,
        isMatchNotFound: true
      }
    case actionTypes.MATCH_INFO_LOADING:
      return {
        ...state,
        loading: true,
        isMatchNotFound: undefined
      }
    case actionTypes.MATCH_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        isMatchNotFound: false
      }
    case actionTypes.SET_COMPANY:
      return {
        ...state,
        loading: false,
        companyCreated: action.payload,
      }
    case actionTypes.SET_CEO_IN_ADMIN_MODE:
      return {
        ...state,
        loading: false,
        ceoSelectedInAdminMode: action.payload,
      }
    case actionTypes.SET_DEPARTMENT_IN_ADMIN_MODE:
      return {
        ...state,
        loading: false,
        departmentSelectedInAdminMode: action.payload,
      }
    case actionTypes.SET_FIRST_CEO_AS_DEFAULT_IN_ADMIN_MODE:
      return {
        ...state,
        loading: false,
        firstCeoAsDefaultInAdminMode: action.payload,
      }
    case actionTypes.ADD_COMPANY_LOADING:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.ADD_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        companyCreated: true,
        companyDetails: {
          matchId: action.payload.matchId,
          companyName: action.payload.companyName,
          logo: action.payload.logo
        }
      }
    default:
      return state
  }
}

export { matchInfoReducer }
