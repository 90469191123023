/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import clsx from 'clsx'
import { Link, NavLink as RouterLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { UncontrolledTooltip, Button } from 'reactstrap'
import { connect } from 'react-redux'
import {
  Settings, CloudDrizzle, Search, Grid, Users, LifeBuoy, Coffee, Bell
} from 'react-feather'
import * as actionTypes from '../../store/actions'
import projectLogo from '../../assets/images/react.svg'

class SidebarCollapsed extends Component {
  toggleSidebarMobile = () => {
    const { sidebarToggleMobile, setSidebarToggleMobile } = this.props

    setSidebarToggleMobile(!sidebarToggleMobile)
  }

  render() {
    const { sidebarShadow, sidebarStyle, sidebarToggleMobile } = this.props

    return (
      <>
        <div
          className={clsx('app-sidebar app-sidebar--collapsed app-sidebar--mini', sidebarStyle, {
            'app-sidebar--shadow': sidebarShadow
          })}>
          <div className="app-sidebar--header">
            <div className="nav-logo">
              <RouterLink to="/" activeClassName="active" title="Company Hike">
                <i>
                  <img alt="Company Hike" src={projectLogo} />
                </i>
                <span>Company Hike</span>
              </RouterLink>
            </div>
          </div>

          <div className="app-sidebar--content">
            <PerfectScrollbar>
              <ul className="sidebar-menu-collapsed">
                <li>
                  <RouterLink activeClassName="active" to="/home" id="DashboardTooltip1">
                    <span>
                      <Settings className="line-height-1" />
                    </span>
                  </RouterLink>
                  <UncontrolledTooltip
                    popperClassName="tooltip-secondary"
                    placement="right"
                    target="DashboardTooltip1"
                    container="body"
                    boundariesElement="window">
                    Dashboard
                  </UncontrolledTooltip>
                </li>
                <li>
                  <RouterLink
                    activeClassName="active"
                    to="/applications-calendar"
                    id="EventsCalendarTooltip1">
                    <span>
                      <CloudDrizzle className="line-height-1" />
                    </span>
                  </RouterLink>
                  <UncontrolledTooltip
                    popperClassName="tooltip-secondary"
                    placement="right"
                    target="EventsCalendarTooltip1"
                    container="body"
                    boundariesElement="window">
                    Events calendar
                  </UncontrolledTooltip>
                </li>
                <li>
                  <RouterLink activeClassName="active" to="/applications-chat" id="ChatTooltip1">
                    <span>
                      <Search className="line-height-1" />
                    </span>
                  </RouterLink>
                  <UncontrolledTooltip
                    popperClassName="tooltip-secondary"
                    placement="right"
                    target="ChatTooltip1"
                    container="body"
                    boundariesElement="window">
                    Chat
                  </UncontrolledTooltip>
                </li>
                <li>
                  <RouterLink activeClassName="active" to="/applicationsContacts" id="ContactsTooltip1">
                    <span>
                      <Grid className="line-height-1" />
                    </span>
                  </RouterLink>
                  <UncontrolledTooltip
                    popperClassName="tooltip-secondary"
                    placement="right"
                    target="ContactsTooltip1"
                    container="body"
                    boundariesElement="window">
                    Contacts
                  </UncontrolledTooltip>
                </li>
                <li>
                  <RouterLink activeClassName="active" to="/applicationsFileManager" id="FileManagerTooltip1">
                    <span>
                      <Users className="line-height-1" />
                    </span>
                  </RouterLink>
                  <UncontrolledTooltip
                    popperClassName="tooltip-secondary"
                    placement="right"
                    target="FileManagerTooltip1"
                    container="body"
                    boundariesElement="window">
                    File manager
                  </UncontrolledTooltip>
                </li>
                <li>
                  <RouterLink activeClassName="active" to="/applicationsMail" id="MailTooltip1">
                    <span>
                      <LifeBuoy className="line-height-1" />
                    </span>
                  </RouterLink>
                  <UncontrolledTooltip
                    popperClassName="tooltip-secondary"
                    placement="right"
                    target="MailTooltip1"
                    container="body"
                    boundariesElement="window">
                    Mail
                  </UncontrolledTooltip>
                </li>
                <li>
                  <RouterLink activeClassName="active" to="/applicationsProjects" id="ProjectsTooltip1">
                    <span>
                      <Coffee className="line-height-1" />
                    </span>
                  </RouterLink>
                  <UncontrolledTooltip
                    popperClassName="tooltip-secondary"
                    placement="right"
                    target="ProjectsTooltip1"
                    container="body"
                    boundariesElement="window">
                    Projects
                  </UncontrolledTooltip>
                </li>
                <li>
                  <RouterLink activeClassName="active" to="/applicationsWidgets" id="AppWidgetsTooltip1">
                    <span>
                      <Bell className="line-height-1" />
                    </span>
                  </RouterLink>
                  <UncontrolledTooltip
                    popperClassName="tooltip-secondary"
                    placement="right"
                    target="AppWidgetsTooltip1"
                    container="body"
                    boundariesElement="window">
                    App widgets
                  </UncontrolledTooltip>
                </li>
              </ul>
              <div className="text-center mb-2">
                <Button
                  tag={Link}
                  color="warning"
                  className="m-1 p-0 d-inline-block shadow-none text-center font-size-lg d-40 rounded"
                  to="/home"
                  id="BacktoPreviousPageTooltip1">
                  <FontAwesomeIcon icon={['fas', 'arrow-left']} />
                </Button>
                <UncontrolledTooltip
                  popperClassName="tooltip-secondary"
                  placement="right"
                  target="BacktoPreviousPageTooltip1"
                  container="body"
                  boundariesElement="window">
                  Back to dashboard
                </UncontrolledTooltip>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
        <div
          onClick={this.toggleSidebarMobile}
          role="button"
          className={clsx('app-sidebar-overlay', {
            'is-active': sidebarToggleMobile
          })}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  sidebarShadow: state.theme.sidebarShadow,
  sidebarStyle: state.theme.sidebarStyle,
  sidebarToggleMobile: state.theme.sidebarToggleMobile
})

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(actionTypes.setSidebarToggleMobile(enable))
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarCollapsed)
