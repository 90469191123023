import { produce } from 'immer'
import { AcceptMatchInvitationDispatchTypes } from 'src/store/actions/types/acceptMatchInvitation'
import * as actionTypes from 'src/store/actions/types/acceptMatchInvitation'
import { Flip, toast } from 'react-toastify'

export interface InvitationState {
  loading: boolean
  error: boolean
}

const initialState:InvitationState = {
  loading: false,
  error: false,
}

function acceptMatchInvitationReducer(state: InvitationState = initialState, action: AcceptMatchInvitationDispatchTypes): InvitationState {
  switch (action.type) {
    case actionTypes.ACCEPT_INVITATION_LOADING:
      return produce(state, draft => {
        draft.loading = true
        draft.error = false
      })
    case actionTypes.ACCEPT_INVITATION_FAIL:
      toast.error('Problem Accepting the invitation, please try again.', {
        containerId: 'A',
        transition: Flip,
      })
      return produce(state, draft => {
        draft.loading = false
        draft.error = true
      })
    case actionTypes.ACCEPT_INVITATION_SUCCESS:
      toast.success('La Partida ya está disponible en Tus Partidas', {
        containerId: 'A',
        transition: Flip,
      })
      return produce(state, draft => {
        draft.error = false
        draft.loading = false
      })
    default:
      return state
  }
}

export { acceptMatchInvitationReducer }