import React, {
  ReactNode, useCallback, useEffect, useRef, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Togglable } from 'src/components'
import { useIsLoggedIn } from 'src/router/components/useIsLoggedIn'
import {
  getNotificationList, getUser, setCommunity, setCompany, State, User
} from 'src/store'
import { getMatchList } from 'src/store/actions/match'
// import { getNotificationList } from 'src/store/actions/notifications'
import { getUserConfig } from 'src/store/actions/userConfig/getUserConfig'

interface GlobalDataConsumerProps {
  children: ReactNode
  fallback: ReactNode
}

function GlobalDataConsumer(props: GlobalDataConsumerProps) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const { children, fallback } = props
  const selectedCommunity: any = useRef(null)
  const userConfig: any = useRef(null)
  const isLoggedIn = useIsLoggedIn()
  const history = useHistory()

  const logUserOut = useCallback(() => {
    if (!isLoggedIn) {
      history.push('/login')
    }
  }, [history, isLoggedIn])

  /**
   * Wrap components requiring User, Community and MatchesList
   * @param props children, fallback
   * @returns children
   */

  /**
   * API Call
   * @returns Config Data
   */
  const fetchConfig = useCallback(async () => {
    setIsLoading(true)

    await dispatch(setCompany(false))
    userConfig.current = await dispatch(getUserConfig())

    if (!userConfig.current) logUserOut()

    return userConfig.current
  }, [dispatch, logUserOut])

  /**
   * API Call
   * @returns User data
   */
  const fetchUser = useCallback(async () => {
    await dispatch(setCompany(false))

    const user = ((await dispatch(getUser())) as any) as User

    if (!user) logUserOut()

    const fallbackId =
      userConfig?.current?.payload?.data?.communityId ||
      JSON.parse(userConfig.current.payload.data).communityId

    // TODO: CommunityId type to be concistent
    const communityIndex = user.communities.findIndex(
      community => Number(community.id) === Number(fallbackId)
    )

    selectedCommunity.current = user.communities[communityIndex]

    dispatch(setCommunity(selectedCommunity.current))

    return user
  }, [dispatch, logUserOut])

  useEffect(() => {
    let config: any;

    (async () => {
      config = await fetchConfig()

      if (config !== undefined) {
        const userData = await fetchUser()

        if (!userData) logUserOut()

        if (userData !== undefined) {
          setIsLoading(false)
        }
      }
    })()
  }, [fetchConfig, fetchUser, logUserOut, selectedCommunity])

  const community = useSelector((state: State) => state.communities.userCommunity.id)

  useEffect(() => {
    // TODO: CommunityID type to be concistent
    // eslint-disable-next-line valid-typeof
    if (typeof community !== 'string') {
      dispatch(getMatchList(community))
      dispatch(getNotificationList())
    }
  }, [community, dispatch])

  return (
    <Togglable enabled={!isLoading} fallback={fallback}>
      {children}
    </Togglable>
  )
}

export { GlobalDataConsumer }

/*
const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

   const fetchNotifications = useCallback(async () => {
    setIsLoading(true)
    const userConfig = await dispatch(getNotificationList())

    setIsLoading(false)
    return userConfig
  }, [dispatch])

  useEffect(() => {
    if (userName !== '') fetchNotifications()
  }, [userName])
*/
