import React from 'react'
import { Bell } from 'react-feather'
import { Badge, Button } from 'reactstrap'

function IdleNotifications() {
  return (
    <span className="d-inline-block pr-2">
      <Button
        id="alertsPopover"
        color="neutral-success"
        className="bg-neutral-gray text-muted font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative">
        <span>
          <Badge color="gray" className="badge-circle">
            New notifications
          </Badge>
          <Bell />
        </span>
      </Button>
    </span>
  )
}

export { IdleNotifications }
