import React, { useMemo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select, { SingleValue } from 'react-select'

export interface Option<T = string> {
  label: string
  value: T
}

interface TranslableSelectorProps {
  translationKey: string | null
  optionsKeys: string[]
  onChange: (newValue: string | undefined) => void
  defaultValue?: string
}
function TranslableSelector(props: TranslableSelectorProps) {
  const {
    translationKey, optionsKeys, onChange, defaultValue
  } = props
  const { t } = useTranslation()

  const options = useMemo(
    () => optionsKeys.map(optionKey => ({
      label: translationKey ? t(`${translationKey}.${optionKey}`) : optionKey,
      value: optionKey
    })),
    [t, optionsKeys, translationKey]
  )

  const defaultSelected: Option[] = useMemo(() => options.filter(option => option.value === defaultValue), [
    defaultValue,
    options
  ])

  const [selectedOption, setSelectedOption] = useState<Option | null>(defaultSelected[0] || options[0])

  const onChangeCallback = useCallback(
    (newValue: SingleValue<Option<string>>) => {
      setSelectedOption(newValue)
      onChange(newValue?.value)
    },
    [onChange]
  )

  return <Select options={options} onChange={onChangeCallback} value={selectedOption} />
}

export { TranslableSelector }
