import { UploadUserAvatarDispatchTypes } from '../actions/types/images'
import * as actionTypes from '../actions/types'

interface ImagesState {
  loading: boolean
  userAvatar?: string
}
const initialState: ImagesState = {
  loading: false,
  userAvatar: undefined
}

const imagesReducer = (state: ImagesState = initialState, action: UploadUserAvatarDispatchTypes): ImagesState => {
  switch (action.type) {
    case actionTypes.UPLOAD_USER_AVATAR_FAIL:
      return {
        loading: false
      }
    case actionTypes.UPLOAD_USER_AVATAR_LOADING:
      return {
        loading: true
      }
    case actionTypes.UPLOAD_USER_AVATAR_SUCCESS:
      return {
        loading: false,
        userAvatar: action.payload
      }
    default:
      return state
  }
}

export { imagesReducer }