import React, {
  FC, useCallback, useEffect, useState
} from 'react'
import {
  Badge,
  Button,
  // NavItem,
  // Nav,
  // NavLink as NavLinkStrap,
  TabContent,
  TabPane,
  Popover
} from 'reactstrap'
import { Bell } from 'react-feather'
// import clsx from 'clsx'
// import PerfectScrollbar from 'react-perfect-scrollbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import avatar7 from '../../assets/images/avatars/avatar7.jpg'
import { Notification } from 'src/store/actions/types/notification'
import { useTranslation } from 'react-i18next'
import { InvitationsList } from './InvitationsList/InvitationsList'

type Props = {
  userName: string | undefined
  data: Notification[]
}

const Notifications: FC<Props> = ({ userName = '', data = [] }) => {
  const [activeTab, setActiveTab] = useState('1')
  const [fixClose, setFixClose] = useState('false')
  const [open, setOpen] = useState(false)
  const [focused, setFocused] = useState(false)
  const { t } = useTranslation()

  /* const toggle = (tab: React.SetStateAction<string>) => {
    if (activeTab !== tab) setActiveTab(tab)
  }*/

  const hideShowPopover = useCallback(() => {
    setOpen(!open)
  }, [open])

  const onBlurHandler = () => {
    setFocused(false)
  }

  const onFocusHandler = () => {
    setFocused(true)
  }

  useEffect(() => {
    setActiveTab('1')
    return () => {
      if (open || focused) {
        hideShowPopover()
        setFixClose('true')
      }
    }
  }, [data, focused, hideShowPopover, open])

  return data && userName ? (
    <div
      className="d-inline-block pr-2 rounded"
      id="story--components-popover--popover"
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}>
      <Button
        id="alertsPopover"
        color={data.length > 0 ? 'neutral-success' : 'neutral'}
        className={
          data.length > 0
            ? 'bg-neutral-success text-success font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative'
            : 'bg-neutral-primary text-neutral font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative'
        }>
        <small className="bg-neutral-success text-success font-size-xs">
          {data.length > 0 && data.length}
        </small>
        <span>
          <Badge color={data.length > 0 ? 'success' : 'neutral'} className="badge-circle">
            Nuevas Invitaciones
          </Badge>
          <Bell />
        </span>
      </Button>
      <Popover
        target="alertsPopover"
        trigger="legacy"
        isOpen={open}
        disable={fixClose}
        toggle={hideShowPopover}
        className="popover popover-custom-lg"
        placement="left">
        {/* container=".d-inline-block"*/}
        <div className="bg-composed-wrapper bg-midnight-bloom mx-3 mt-3 border-0 text-center rounded-md">
          <div className="bg-composed-img-3 bg-composed-wrapper--image" />
          <div className="bg-composed-wrapper--content text-white px-2 py-4">
            <h4 className="font-size-xl font-weight-bold mb-2">{t('page.notifications.title')}</h4>
            <p
              className="opacity-8 mb-0 text-size-md"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t('page.notifications.subtitle', { amount: data && data.length })
              }}
            />
          </div>
        </div>
        <div className="tabs-animated tabs-animated-shadow tabs-bordered">
          {/* <Nav tabs={true} justified={true}>
              <NavItem>
                <NavLinkStrap
                  className={clsx({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1')
                  }}>
                  <span>Nuevas Invitaciones</span>
                </NavLinkStrap>
              </NavItem>
               <NavItem>
                <NavLinkStrap
                  className={clsx({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2')
                  }}>
                  <span>En curso</span>
                </NavLinkStrap>
                </NavItem>
            </Nav>*/}
        </div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="scroll-area scroll-area-lg shadow-overflow rounded">
              <div className="py-3">
                <InvitationsList showAcceptInvite={true} />
              </div>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="scroll-area scroll-area-lg shadow-overflow">
              <div className="text-center my-5">
                <div className="avatar-icon-wrapper rounded-circle m-0">
                  <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                    <FontAwesomeIcon icon={['fas', 'times']} className="d-flex align-self-center display-3" />
                  </div>
                </div>
                <h6 className="font-weight-bold font-size-lg mb-1 mt-4 text-black">Mensajes</h6>
                <p className="text-black-50 mb-0">No tienes nuevos mensajes</p>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </Popover>
    </div>
  ) : (
    <div />
  )
}

export default Notifications
