/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'

import { useMatchInfo } from 'src/views/Dashboard/hooks/useMatchInfo'
import { Ceo } from 'src/store/actions/types/matchInfo'
import { useUser } from 'src/hooks/useUser'
import { getMatchGlobalProperty, getMatchParameters } from 'src/utils/dataMatchServices'
import ExampleWrapperSimple from 'src/layoutComponents/ExampleWrapperSimple'
import { useMatches } from 'src/hooks/useMatchList'
import { formatIndustry } from 'src/utils/calculations'
import { Card, CardHeader } from 'reactstrap'
import DataProgressBarTwo from 'src/components/Reports/DataProgressBarTwo/DataProgressBarTwo'
import { DataProps } from 'src/layoutComponents/Tabs/AdviserReport/ReportCategories/types'
import { useTranslation } from 'react-i18next'

interface CeosData {
  ceos: Ceo[]
}

type ObjectivesResultsState = {
  id_sensibility: number
  price_sensibility: number
  positive_random_events: number
  pms: number
  easy_business_score_tax: number
  out_zone: number
}

type ObjetivesResultsPropNames<K extends string = keyof ObjectivesResultsState> = {
  [key in K]: string
}

const resultsProps: DataProps<keyof ObjetivesResultsPropNames> = {
  id_sensibility: 'id_sensibility',
  price_sensibility: 'price_sensibility',
  positive_random_events: 'positive_random_events',
  pms: 'pms',
  easy_business_score_tax: 'easy_business_score_tax',
  out_zone: 'out_zone'
}

type ChartKeys = Partial<keyof ObjectivesResultsState>[]

const IndustryPercentages = () => {
  const { matchInfo } = useMatchInfo()
  const id = useUser()?.id
  const ceosData = matchInfo.data?.ceos
  const matchData = matchInfo.data
  const fundsApplication: ChartKeys = [
    'id_sensibility',
    'price_sensibility',
    'positive_random_events',
    'pms',
    'easy_business_score_tax',
    'out_zone'
  ]
  const [objectivesResults, setObjectivesResults] = useState<ObjectivesResultsState>()
  const { matches } = useMatches()
  const { t } = useTranslation()

  const data: CeosData = { ceos: [] }

  if (ceosData) {
    data.ceos = ceosData
  }

  const variables = {
    industry: 'industry',
    game_parameters: 'game_parameters',
    pms: 'pms'
  }

  const id_sensibility = getMatchGlobalProperty(id, variables.industry, 0, matchData).id_sensibility || 0
  const price_sensibility =
    getMatchGlobalProperty(id, variables.industry, 0, matchData).price_sensibility / 10 || 0
  const positive_random_events =
    getMatchGlobalProperty(id, variables.industry, 0, matchData).positive_random_events || 0
  const pms = getMatchParameters(id, variables.pms, matchData?.gameParameters) * 10

  useEffect(() => {
    if (matchInfo.data) {
      const currentObjectiveResults = {} as ObjectivesResultsState
      let key: keyof ObjectivesResultsState
      const keys = Object.keys(resultsProps) as Array<keyof typeof resultsProps>

      for (key of keys) {
        currentObjectiveResults[key] = getMatchParameters(
          id,
          resultsProps[key],
          matchData?.gameParameters
        ) as number
      }

      currentObjectiveResults.pms = pms
      currentObjectiveResults.id_sensibility = id_sensibility
      currentObjectiveResults.price_sensibility = price_sensibility
      currentObjectiveResults.positive_random_events = positive_random_events

      setObjectivesResults(currentObjectiveResults)
    }
  }, [matchInfo.data, data.ceos, id, matches])

  return (
    <ExampleWrapperSimple>
      {objectivesResults && (
        <Card className="card-box">
          <CardHeader className="mb-4 d-flex flex-column align-items-start">
            <h5 className="font-weight-bold mb-2">
              {t('charts.objectives.industry.status.title')}
              {' '}
              {formatIndustry(matchInfo?.data?.industry as string)}
            </h5>
            <span className="text-black-50">{t('charts.objectives.industry.status.description')}</span>
          </CardHeader>
          <div className="p-4">
            {fundsApplication.map((key, index) => (
              <div className="mb-4" key={index}>
                <DataProgressBarTwo dataProp={resultsProps[key]} value={objectivesResults[key]} />
              </div>
            ))}
          </div>
        </Card>
      )}
    </ExampleWrapperSimple>
  )
}

export default IndustryPercentages
