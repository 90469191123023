import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Button } from 'reactstrap'
import svgImage3 from '../../assets/images/composed-bg/bankrupt-sherpa.jpg'

function PagesError404Content() {
  return (
    <div className="app-wrapper bg-white">
      <div className="app-main">
        <Button tag={Link} to="/home" size="lg" color="primary" className="btn-go-back">
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={['fas', 'arrow-left']} />
          </span>
          <span className="btn-wrapper--label">Volver</span>
        </Button>
        <div className="app-content p-0">
          <div className="app-inner-content-layout--main">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div className="bg-composed-wrapper--content">
                <div className="hero-wrapper bg-composed-wrapper min-vh-100">
                  <div className="flex-grow-1 w-100 d-flex align-items-center">
                    <Col lg="9" md="9" className="px-4 px-lg-0 mx-auto text-center text-black">
                      <img
                        src={svgImage3}
                        className="rounded w-50 mx-auto d-block my-5 img-fluid"
                        alt="..."
                      />
                      <h4 className="font-size-xl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                        La ruta no existe, vuelve a la home por favor.
                      </h4>

                      {/* <p>
                        Puedes haber tipeado mal. Try searching below.
                      </p>
                      <Row className="no-gutters">
                        <Col sm="12" md="8" className="mt-4 mt-lg-3 px-2">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Search terms here..."
                          />
                        </Col>
                        <Col sm="12" md="4" className="mt-4 mt-lg-3 px-2">
                          <Button className="d-block w-100" color="first" size="lg">
                            <span className="btn-wrapper--label">Search</span>
                          </Button>
                        </Col>
                      </Row>*/}
                    </Col>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { PagesError404Content }
