import { CeoDecisionsData, CeoBalance, CeoDecisionsPostSchema } from '../types'

export function calcCeoDecisionsParameters(data: CeoDecisionsData, balance: CeoBalance): CeoDecisionsPostSchema {
  const { finalCash, financialDebt } = balance
  const {
    design, ibk, mkt, price, production, qualityControl, recycle, safety, survey, lending
  } = data.decisions

  const parameterValues = {
    design: finalCash * (design / 100),
    new_debt: financialDebt + lending,
    ibk: finalCash * (ibk / 100),
    mkt: finalCash * (mkt / 100),
    price,
    production,
    quality_control: qualityControl,
    recycle,
    safety,
    survey: finalCash * (survey / 100),
  }

  return parameterValues
}