import { Match } from './matchList'

const actions = {
  REMOVE_MATCH_LOADING: 'REMOVE_MATCH_LOADING',
  REMOVE_MATCH_FAIL: 'REMOVE_MATCH_FAIL',
  REMOVE_MATCH_SUCCESS: 'REMOVE_MATCH_SUCCESS'
}

export interface RemoveMatchLoading {
  type: typeof actions.REMOVE_MATCH_LOADING
}

export interface RemoveMatchFail {
  type: typeof actions.REMOVE_MATCH_FAIL
}

export interface RemoveMatchSuccess {
  type: typeof actions.REMOVE_MATCH_SUCCESS
  payload: {
    matches: Match[] | undefined
  }
}

export type RemoveMatchDispatchTypes =
  | RemoveMatchLoading
  | RemoveMatchSuccess
  | RemoveMatchFail

export { actions }