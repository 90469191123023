import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence, motion } from 'framer-motion'
import {
  Col, Card, CardBody, Badge
} from 'reactstrap'
import { DepartmentName } from 'src/store'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useTranslation } from 'react-i18next'

interface DepartmentCardProps {
  approved: boolean
  department: DepartmentName
  icon: IconProp
  animateDelay?: number
}

const DepartmentCard: FC<DepartmentCardProps> = props => {
  const {
    approved, department, icon, animateDelay
  } = props
  const { t } = useTranslation()

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.5
    },
    in: {
      opacity: 1,
      scale: 1,
      animate: { scale: 1.3 }
      // transition: { type: 'spring', damping: 8 },
    },
    out: {
      opacity: 1,
      scale: 1
    }
  }

  const pageTransition = {
    delay: animateDelay || 0.2,
    type: 'tween',
    ease: 'easeIn',
    duration: 0.45
  }

  return (
    <Col xs="8" sm="7" md="8" xl="8" style={{ zIndex: 9999 }}>
      <AnimatePresence>
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={{ ...pageTransition, delay: pageTransition.delay * 1.8 }}>
          <Card className="card-box mb-1 op-1" style={{ background: 'rgba(255, 255, 255, 0.65)' }}>
            <div className={`card-indicator bg-${department}`} />
            <CardBody className="px-4 py-3">
              <div className="pb-0 d-flex justify-content-between">
                <span>{t(`page.ceo_decisions.departments.${department}`)}</span>
                <span
                  // eslint-disable-next-line max-len
                  className={`${
                    approved ? `bg-${department}` : 'bg-dark'
                  } btn-wrapper--icon btn-icon font-size-lg d-40 text-white rounded-pill d-inline-block mb-0 d-flex justify-content-center align-items-center `}>
                  <FontAwesomeIcon icon={icon} />
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-start">
                <Badge color={approved ? department : 'dark'} className="px-3">
                  {t(`page.ceo_decisions.status_cards.${approved ? 'decision_ready' : 'pending_decision'}`)}
                </Badge>
                {/* <div className="font-size-sm text-dark px-2">
              <FontAwesomeIcon icon={['far', 'clock']} className="mr-1" />
              17:56
            </div> */}
              </div>
            </CardBody>
          </Card>
        </motion.div>
      </AnimatePresence>
    </Col>
  )
}

export default DepartmentCard
