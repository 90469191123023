/* eslint-disable max-len */
import { Radar } from 'react-chartjs-2'

import React, { useEffect, useState } from 'react'

import { useMatchInfo } from 'src/views/Dashboard/hooks/useMatchInfo'
import { Ceo } from 'src/store/actions/types/matchInfo'
import { useUser } from 'src/hooks/useUser'
import { getMatchGlobalProperty } from 'src/utils/dataMatchServices'
import ExampleWrapperSimple from 'src/layoutComponents/ExampleWrapperSimple'
import { useMatches } from 'src/hooks/useMatchList'
import { formatIndustry } from 'src/utils/calculations'

interface CeosData {
  ceos: Ceo[]
}

const IndustryRadar = () => {
  const { matchInfo } = useMatchInfo()
  const id = useUser()?.id
  const ceosData = matchInfo.data?.ceos
  const matchData = matchInfo.data
  const [radarData, setRadarData] = useState({})
  const { matches } = useMatches()

  const data: CeosData = { ceos: [] }

  if (ceosData) {
    data.ceos = ceosData
  }

  const variables = {
    industry: 'industry',
    uDemUindustry: 'u_dem_industry',
    uProdIndustry: 'u_prod_industry',
    soldUIndustry: 'sold_u_industry',
    depreciation: 'depreciation'
  }

  const alfa = getMatchGlobalProperty(id, variables.industry, 0, matchData).alfa * 100
  const { p_price } = getMatchGlobalProperty(id, variables.industry, 0, matchData)
  const { p_mkt } = getMatchGlobalProperty(id, variables.industry, 0, matchData)
  const { p_id } = getMatchGlobalProperty(id, variables.industry, 0, matchData)
  const { depreciation_rate } = getMatchGlobalProperty(id, variables.industry, 0, matchData)
  const options = {
    legend: {
      labels: {
        fontColor: 'grey',
        fontSize: 16
      }
      // position: 'top',
    },
    scale: {
      // reverse: false,

      /* gridLines: {
        color: ['black', 'red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'violet']
      },*/
      /* ticks: {
        beginAtZero: true
      },*/
      pointLabels: {
        fontSize: 13.8
      }
    }

    /* elements: {
      point: {
        radius: 0
      }
    }*/
  }

  useEffect(() => {
    if (data.ceos !== undefined) {
      setRadarData({
        labels: [
          'Productividad del capital',
          'Tasa de Depreciación',
          'Ponderación Id',
          'Ponderación Precio',
          'Ponderación Marketing'
        ],
        datasets: [
          {
            label: 'Características de largo plazo',
            backgroundColor: 'rgba(179,181,198,0.2)',
            borderColor: 'rgba(179,90,198,1)',
            pointBackgroundColor: 'rgba(179,181,198,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(179,181,198,1)',
            data: [alfa, depreciation_rate, p_price, p_id, p_mkt]
          }
        ],
        pointLabels: {
          font: {
            size: 150
          }
        }
      })
    }
  }, [data.ceos, id, depreciation_rate, alfa, p_id, p_price, p_mkt, matches])

  return (
    <ExampleWrapperSimple>
      <div className="card-header-alt d-flex justify-content-start align-items-center">
        <h6 className="pl-3 font-weight-bold font-size-lg mb-0 text-black pt-3">
          {formatIndustry(matchInfo?.data?.industry as string)}
        </h6>
      </div>
      <div className="w-100 mx-auto py-3">
        <Radar data={radarData} options={options} />
      </div>
    </ExampleWrapperSimple>
  )
}

export default IndustryRadar
