import { MatchType } from 'src/views/CreateMatch/types'

export const GET_MATCH_SCHEMA_LOADING = 'GET_MATCH_SCHEMA_LOADING'
export const GET_MATCH_SCHEMA_FAILS = 'GET_MATCH_SCHEMA_FAILS'
export const GET_MATCH_SCHEMA_SUCCESS = 'GET_MATCH_SCHEMA_SUCCESS'

export const ADD_MATCH_DATA = 'ADD_MATCH_DATA'
export const RESET_MATCH_DATA = 'RESET_MATCH_DATA'

export const POST_MATCH_DATA_LOADING = 'POST_MATCH_DATA_LOADING'
export const POST_MATCH_DATA_FAILS = 'POST_MATCH_DATA_FAILS'
export const POST_MATCH_DATA_SUCCESS = 'POST_MATCH_DATA_SUCCESS'

export type Proficiency = 'rookie' | 'amateur' | 'semi-senior' | 'pro' | 'expert'
export interface MatchParameters {
  players: Interval,
  name: string,
  proficiency_rate: {
    options: Proficiency[]
  },
  industry: {
    options: 'cars'[]
  },
  type: ('scenario' | 'country' | 'custom')[],
  scenario: {
    options: ('coronavirus-2020' | 'argentina-crisis-2001')[],
  },
  country: {
    options: string[]
  },
  stages: Interval,
  country_income_level: {
    options: ('low' | 'medium-low' | 'medium-high' | 'high')[]
  },
  government_side: {
    options: ('government_side_liberal' | 'government_side_moderate' | 'government_side_intervencionist' | 'custom')[]
  },
  industry_status: {
    options: ('preset1' | 'preset2' | 'preset3' | 'preset4')[]
  },
  accounting_period: {
    options: number[],
  },
  positive_random_events: {
    options: ('none' | 'low' | 'medium' | 'high')[]
  },
  risk_limit_min: Interval,
  risk_limit_max: Interval,
  initial_eq: Interval,
  profit_tax: Interval,
  other_tax: Interval,
  vat_tax: Interval,
  labor_tax: Interval,
  easy_business_score: {
    options: ('low' | 'medium-low' | 'medium-high' | 'high')[],
  },
  interest_rate: Interval,
  financial_cost: number,
  compensation_cost: Interval,
  company_type: {
    options: string[]
  }
}

export interface GovernmentParameters {
  demand_pull: Interval,
  price_control: Interval,
  interest_rate: Interval,
  financial_cost: number,
}
export interface CeoParameters {
  price: Interval,
  production_level: Interval,
  stage_1: {}
}

export interface MatchSchema {
  match_parameters: MatchParameters,
  government_parameters: GovernmentParameters,
  ceo_parameters: CeoParameters
}

export interface MatchData {
  step: number
  accountingPeriod?: number,
  communityId?: string
  companies?: number,
  compensationCost?: number,
  companyType?: string,
  countryIncomeLevel?: string,
  governmentSide?: string,
  country?: string,
  countryDevelopment?: string,
  description?: string
  easyBusinessScore?: string,
  experience?: string,
  financialCost?: number,
  governmentType?: string,
  industry?: string,
  industryStatus?: string,
  initialEq?: number,
  interestRate?: number,
  laborTax?: number,
  otherTax?: number,
  name?: string,
  players?: number,
  proficiencyRate?: string
  profitTax?: number,
  positiveRandomEvents?: string,
  riskLimitMax?: number,
  riskLimitMin?: number,
  scenario?: string,
  stages?: number,
  type?: MatchType,
  vatTax?: number,
  version?: string
}

interface Interval {
  min: number,
  max: number,
}
export interface GetMatchSchemaLoading {
  type: typeof GET_MATCH_SCHEMA_LOADING;
}

export interface GetMatchSchemaFails {
  type: typeof GET_MATCH_SCHEMA_FAILS;
}

export interface GetMatchSchemaSuccess {
  type: typeof GET_MATCH_SCHEMA_SUCCESS;
  payload: MatchSchema;
}

interface addMatchData {
  type: typeof ADD_MATCH_DATA;
  payload: MatchData;
}

interface resetMatchData {
  type: typeof RESET_MATCH_DATA;
}

export interface PostMatchDataLoading {
  type: typeof POST_MATCH_DATA_LOADING;
}

export interface PostMatchDataFails {
  type: typeof POST_MATCH_DATA_FAILS;
}

export interface PostMatchDataSuccess {
  type: typeof POST_MATCH_DATA_SUCCESS;
  payload: CreateMatchPostRes;
}

export interface CreateMatchPostRes {
  status: boolean
  id: number
}

export type CreateMatchDispatchTypes =
  | GetMatchSchemaLoading
  | GetMatchSchemaFails
  | GetMatchSchemaSuccess
  | addMatchData
  | resetMatchData
  | PostMatchDataLoading
  | PostMatchDataFails
  | PostMatchDataSuccess

export type SetCreateMatchT = MatchSchema
