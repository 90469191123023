import React, { Component } from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as actionTypes from '../../store/actions'
import projectLogo from '../../assets/images/react.svg'

class SidebarHeader extends Component {
  toggleSidebarMobile = () => {
    const { sidebarToggleMobile, setSidebarToggleMobile } = this.props

    setSidebarToggleMobile(!sidebarToggleMobile)
  }

  toggleSidebar = () => {
    const { sidebarToggle, setSidebarToggle } = this.props

    setSidebarToggle(!sidebarToggle)
  }

  render() {
    const { sidebarToggleMobile } = this.props

    return (
      <>
        <div className="app-sidebar--header">
          <div className="nav-logo">
            <Link to="/" title="Company Hike">
              <i>
                <img alt="Company Hike" src={projectLogo} />
              </i>
              <span style={{ fontSize: '1.5rem' }}>Sherpa</span>
            </Link>
          </div>
          <button
            type="button"
            onClick={this.toggleSidebar}
            className="btn btn-sm collapse-sidebar-btn"
            id="CollapseSidebarTooltip">
            <FontAwesomeIcon icon={['far', 'dot-circle']} size="lg" />
          </button>
          <UncontrolledTooltip target="CollapseSidebarTooltip" container=".app-sidebar" placement="right">
            Collapse sidebar
          </UncontrolledTooltip>
          <button
            type="button"
            className={clsx('navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn', {
              'is-active': sidebarToggleMobile
            })}
            onClick={this.toggleSidebarMobile}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <button
            type="button"
            onClick={this.toggleSidebar}
            className="expand-sidebar-btn btn btn-sm"
            id="ExpandSidebarTooltip">
            <FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
          </button>
          <UncontrolledTooltip target="ExpandSidebarTooltip" container=".app-sidebar" placement="right">
            Expand sidebar
          </UncontrolledTooltip>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  sidebarToggle: state.theme.sidebarToggle,
  sidebarToggleMobile: state.theme.sidebarToggleMobile
})

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(actionTypes.setSidebarToggleMobile(enable)),
  setSidebarToggle: enable => dispatch(actionTypes.setSidebarToggle(enable))
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader)
