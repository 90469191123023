import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Button } from 'reactstrap'

function BackButton() {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <Button className="mr-2" onClick={() => history.goBack()}>
      {`${t('page.create_match.button_back')}`}
    </Button>
  )
}
export { BackButton }
