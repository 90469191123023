
import { produce } from 'immer'
import { actions, RemoveMatchDispatchTypes, RemoveMatchSuccess } from 'src/store/actions/types/match/removeMatch'
import { Match } from 'src/store/actions'

export interface MatchState {
  loading: boolean
  matches: Match[]
}

const initialState:MatchState = {
  loading: false,
  matches: []
}

function removeMatchReducer(state: MatchState = initialState, action: RemoveMatchDispatchTypes): MatchState {
  switch (action.type) {
    case actions.REMOVE_MATCH_LOADING:
      return produce(state, draft => {
        draft.loading = true
      })
    case actions.REMOVE_MATCH_FAIL:
      return produce(state, draft => {
        draft.loading = false
      })
    case actions.REMOVE_MATCH_SUCCESS:
      return produce(state, draft => {
        // once endpoint is properly working we dont need to pass userId in the payload
        // and instead of this:
        // draft.communityMembers = state.communityMembers.filter(communityMember => communityMember.id !== (action as RemoveCommunityMemberSuccess).payload.userId)
        // we should do:
        draft.loading = false
        draft.matches = (action as RemoveMatchSuccess).payload.matches ?? []
      })
    default:
      return state
  }
}

export { removeMatchReducer }