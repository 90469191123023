import React, { ReactNode } from 'react'
import { Card as BaseCard } from 'reactstrap'

interface Props {
  children: ReactNode
  isMobile: boolean
}

function Card(props: Props) {
  const { isMobile } = props

  return (
    <BaseCard
      className="card-box-t sm-5"
      style={{
        float: isMobile ? 'none' : 'right',
        maxWidth: '410px',
        minWidth: '40%',
        margin: 'auto',
        marginTop: '1.5rem'
      }}>
      {props.children}
    </BaseCard>
  )
}

export { Card }
