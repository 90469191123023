import { useTranslation } from 'react-i18next'
import { useIsAdmin } from './useIsAdmin'

const useSideBarMenu = () => {
  const { t } = useTranslation()
  const isAdmin = useIsAdmin()

  const sidebarMenuContentAdmin = [
    {
      label: t('layout.sidebar_menu.admin.create_match'),
      icon: 'pe-7s-plus',
      to: '/create-match'
    },
    {
      label: t('layout.sidebar_menu.admin.community'),
      icon: 'pe-7s-users',
      to: '/community'
    },
    {
      label: t('layout.sidebar_menu.admin.ranking'),
      icon: 'pe-7s-cup',
      to: '/ranking'
    },
    {
      label: t('layout.sidebar_menu.admin.help'),
      icon: 'pe-7s-help1',
      to: '/help'
    }
  ]

  const sidebarMenuContentUser = [
    {
      label: t('layout.sidebar_menu.user.ranking'),
      icon: 'pe-7s-cup',
      to: '/ranking'
    },
    {
      label: t('layout.sidebar_menu.user.help'),
      icon: 'pe-7s-help1',
      to: '/help'
    }
  ]

  return isAdmin ? sidebarMenuContentAdmin : sidebarMenuContentUser
}

export { useSideBarMenu }