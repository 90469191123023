import React, {
  FC, ReactNode, useEffect, useState
} from 'react'
import { useDispatch } from 'react-redux'
import { Card, Col, Row } from 'reactstrap'
import { BalanceStatus } from 'src/components/BalanceStatus'
import News from 'src/components/News'
import { useIsAdmin } from 'src/hooks/useIsAdmin'
import useWindowWidth from 'src/hooks/useWindowWidth'
import { HeaderDrawer, HeaderMatch } from '../../layoutComponents'
import { setSwitchBackground } from '../../store/actions/theme'

interface Props {
  children?: ReactNode
}

const FullLayout: FC<Props> = ({ children = () => <></> }) => {
  const isAdmin = useIsAdmin()
  const dispatch = useDispatch()
  const [screenSizeMobile, setScreenSizeMobile] = useState(false)
  const isMobile = useWindowWidth() <= 750

  useEffect(() => {
    dispatch(setSwitchBackground(false))
    setScreenSizeMobile(isMobile)
  }, [dispatch, isMobile])

  return (
    <>
      <HeaderMatch />
      <Card className="card-box-t mb-2 w-100 p-2 d-flex align-items-left">
        <div className="app-header--pane mb-2 d-flex flex-lg-row flex-column-reverse justify-content-between align-items-center">
          <div className="mr-lg-5 mr-0 my-ls-0 my-2">
            <News screenSizeMobile={screenSizeMobile} />
          </div>
          {!isAdmin && <BalanceStatus />}
        </div>
        {!isAdmin && (
          <Row>
            <Col>
              <HeaderDrawer />
            </Col>
          </Row>
        )}
        <div>{children}</div>
      </Card>
    </>
  )
}

export default FullLayout
