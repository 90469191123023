import { AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { client } from 'src/api/client'
import { SetUserDispatch } from './types/user'
import { User, UserApi } from './types'
import * as actionTypes from './types'

function setUser(user: User): SetUserDispatch {
  return {
    type: actionTypes.SET_USER,
    payload: user
  }
}

function getUser() {
  return async (dispatch: Dispatch): Promise<User> => {
    try {
      await dispatch({
        type: actionTypes.USER_LOADING
      })
      const { data: user } = await client.get<any, AxiosResponse<UserApi>>('user')

      const user2 = {
        communities: user.communities.map((community: any) => ({
          id: community.id,
          isAdmin: community.is_admin,
          logo: community.logo,
          name: community.name,
        })),
        firstLogin: user.first_login,
        name: user.name,
        lastName: user.last_name,
        language: user.language,
        email: user.email,
        isAdmin: Boolean(user.is_admin),
        id: user.id,
        pendingMatchInvitation: user.pending_match_invitation,
        user_data: user.user_data,
        stats: user.stats
      }

      await dispatch({
        type: actionTypes.USER_SUCCESS,
        payload: user2
      })

      return user2
    } catch (error) {
      dispatch({
        type: actionTypes.USER_FAIL
      })
      throw error
    }
  }
}

export { getUser, setUser }