import { useMemo } from 'react'

function usePageVariants() {
  return useMemo(() => ({

    initial: {
      opacity: 0,
      scale: 0.99,
    },
    in: {
      opacity: 1,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.01,
    },
  }), [])
}

export { usePageVariants }
