import { useSelector } from 'react-redux'
import { State } from 'src/store'
import { MatchData, MatchParameters, MatchSchema } from 'src/store/actions/types'

function useCreateMatch() {
  const {
    data, schema, postRes: matchCreatedRes, loading
  } = useSelector((state: State) => state.match.createMatch)

  return {
    data, schema, matchCreatedRes, loading
  }
}

function useMatchData(): MatchData {
  const { data } = useCreateMatch()

  return data
}

function useMatchCreationUtils() {
  const matchData = useMatchData()
  const isZeroStep = !matchData || matchData.step < 1
  const currentStep = matchData.step

  return { isZeroStep, currentStep }
}

function useMatchSchema(): MatchSchema | undefined {
  const { schema } = useCreateMatch()

  return schema
}

function useMatchParameters(): MatchParameters | undefined {
  const schema = useMatchSchema()

  return schema?.match_parameters
}

export {
  useCreateMatch, useMatchSchema, useMatchParameters, useMatchData, useMatchCreationUtils
}