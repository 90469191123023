import React, { ReactNode } from 'react'

interface TextProps {
  id?: string
  className?: string
  children: ReactNode
}

function GenericWrapper(props: TextProps) {
  const { children, ...otherProps } = props

  return <div {...otherProps}>{children}</div>
}

export { GenericWrapper }
