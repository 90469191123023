import React, {
  FC, Suspense, useCallback, useEffect, useState
} from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from 'src/hooks/useUser'
import { CommunitiesDropDown } from 'src/layoutBlueprints/LeftSidebar/components/CommunitiesDropDown/CommunitiesDropDown'
import { useNotifications } from 'src/hooks/useNotificationList'
import useWindowWidth from 'src/hooks/useWindowWidth'
import { State } from '../../store'
import HeaderDots from '../HeaderDots'
import { HeaderUserbox } from '../HeaderUserbox/index'
import { Multilanguage } from '../Multilanguage/Multilanguage'
import * as actionTypes from '../../store/actions'

const Header: FC = () => {
  const headerShadow = useSelector((state: State) => state.theme.headerShadow)
  const headerBgTransparent = useSelector((state: State) => state.theme.headerBgTransparent)
  const sidebarToggleMobile = useSelector((state: State) => state.theme.sidebarToggleMobile)
  // const sidebarToggle = useSelector((state: State) => state.theme.sidebarToggle)
  const user = useUser()
  const [screenSizeMobile, setScreenSizeMobile] = useState(false)
  const isMobile = useWindowWidth() <= 750

  const dispatch = useDispatch()
  const toggleSidebarMobile = useCallback(() => {
    dispatch(actionTypes.setSidebarToggleMobile(!sidebarToggleMobile))
  }, [dispatch, sidebarToggleMobile])

  const { notifications } = useNotifications()

  useEffect(() => {
    setScreenSizeMobile(isMobile)
    if (isMobile) {
      setTimeout(() => dispatch(actionTypes.setSidebarToggle(false)), 3500)
    } else {
      setTimeout(() => dispatch(actionTypes.setSidebarToggle(true)), 3500)
    }
  }, [dispatch, isMobile, screenSizeMobile])

  useEffect(() => {
    setScreenSizeMobile(isMobile)
    if (isMobile) {
      setTimeout(() => dispatch(actionTypes.setSidebarToggleMobile(false)), 100)
    } else {
      setTimeout(() => dispatch(actionTypes.setSidebarToggleMobile(true)), 100)
    }
  }, [dispatch, isMobile, screenSizeMobile])

  const SuspenseLoading = () => (
    <>
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">CARGANDO</div>
        <div className="text-muted font-size-xl text-center pt-3">
          Please wait while we load the live preview examples
          <span className="font-size-lg d-block text-dark">
            This live preview instance can be slower than a real production build!
          </span>
        </div>
      </div>
    </>
  )

  return (
    <>
      <Suspense fallback={<SuspenseLoading />}>
        <div
          className={clsx('app-header', {
            'app-header--shadow': headerShadow,
            'app-header--opacity-bg': headerBgTransparent
          })}>
          <div className="app-header--pane">
            <button
              type="button"
              className={clsx('navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn', {
                'is-active': sidebarToggleMobile
              })}
              onClick={toggleSidebarMobile}>
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
            <CommunitiesDropDown />
          </div>
          {user && notifications && (
            <div className="app-header--pane">
              <Multilanguage />
              <HeaderDots userName={user?.name} />
              {user && <HeaderUserbox user={user} />}
            </div>
          )}
        </div>
      </Suspense>
    </>
  )
}

export default Header
