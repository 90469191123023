/* eslint-disable no-param-reassign */
import {
  camelCase, isArray, transform, isObject, snakeCase
} from 'lodash'


const camelize = <T = unknown>(obj: Record<string, T>) => transform(obj, (acc: Record<string, unknown>, value: T, key: string, target) => {
  const camelKey = isArray(target) ? key : camelCase(key)

  acc[camelKey] = isObject(value) ? camelize(value as Record<string, T>) : value
})

const snakeize = <T = unknown>(obj: Record<string, T>) => transform(obj, (acc: Record<string, unknown>, value: T, key: string, target) => {
  const camelKey = isArray(target) ? key : snakeCase(key)

  acc[camelKey] = isObject(value) ? snakeize(value as Record<string, T>) : value
})


export { camelize, snakeize }