import { Dispatch } from 'redux'
import { client } from 'src/api/client'
import { UserConfigDispatchTypes } from '../types/userConfig'
import * as actionTypes from '../types/userConfig'

const postUserConfig = (data: {
  user_data: any,
}) => async (dispatch: Dispatch<UserConfigDispatchTypes>) => {
  try {
    dispatch({
      type: actionTypes.POST_USER_CONFIG_LOADING
    })

    const res = await client.post('user/update_user_data', data)

    dispatch({
      type: actionTypes.POST_USER_CONFIG_SUCCESS,
      payload: res.data
    })
    return res
  } catch (e) {
    dispatch({
      type: actionTypes.POST_USER_CONFIG_FAILS
    })
  }
  return null
}

export {
  postUserConfig,
}