import React from 'react'
import { Link } from 'react-router-dom'
import { UncontrolledTooltip } from 'reactstrap'
import { Bell, Activity } from 'react-feather'

const SidebarFooter = () => (
  <>
    <div className="app-sidebar--footer">
      <ul>
        <li>
          <Link
            to="/home"
            className="btn btn-sm btn-transition-none px-2 mx-2"
            id="ProjectsApplicationTooltip">
            <Activity />
          </Link>
          <UncontrolledTooltip target="ProjectsApplicationTooltip" container=".app-sidebar--footer">
            Home
          </UncontrolledTooltip>
        </li>
        <li>
          <Link to="/home" className="btn btn-sm btn-transition-none px-2 mx-2" id="HelpdeskDashboardTooltip">
            <Bell />
          </Link>
          <UncontrolledTooltip target="HelpdeskDashboardTooltip" container=".app-sidebar--footer">
            Notifications
          </UncontrolledTooltip>
        </li>
      </ul>
    </div>
  </>
)

export default SidebarFooter
