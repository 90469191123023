import React, { FC } from 'react'
import { useNotifications } from 'src/hooks/useNotificationList'
import { NotificationList } from 'src/store/actions/types/notification'
import { IdleNotifications } from './Notifications/InvitationsList/IdleNotifications'
import Notifications from './Notifications/Notifications'

type Props = {
  userName: string | undefined
}

const HeaderDots: FC<Props> = ({ userName = '' }) => {
  const { notifications, isLoading } = useNotifications() || []

  return !isLoading && notifications ? (
    <>
      <div className="d-flex align-items-center popover-header-wrapper homeTourStep1">
        {!isLoading && notifications ? (
          <Notifications userName={userName} data={notifications as NotificationList} />
        ) : (
          <IdleNotifications />
        )}

        {/* TODO: Profile completition to be added. Ref:

        <span className="d-inline-block pr-2">
          <Button
            id="alertsPopover"
            color="neutral-success"
            className="bg-neutral-success text-success font-size-lg p-0 d-inline-block
            shadow-none border-0 text-center d-44 rounded position-relative btn-transition-none homeTourStep1">
            <span>
              <Badge color="success" className="badge-circle">
                Profile Completition
              </Badge>
              <Bell />
            </span>
          </Button>
          <UncontrolledPopover
            target="alertsPopover"
            trigger="legacy"
            container="body"
            className="popover-custom-wrapper popover-custom-lg"
            placement="auto">
            <ul className="list-group list-group-flush text-left bg-transparent">
              <li className="list-group-item rounded-top">
                <div className="align-box-row">
                  <div>
                    <div className="avatar-icon-wrapper avatar-icon-md">
                      <div className="avatar-icon rounded-circle">
                        <img alt="..." src={avatar7} />
                      </div>
                    </div>
                  </div>
                  <div className="pl-2">
                    <span className="pb-1 d-block">{userName}</span>
                    <small className="pb-1 text-black-50 d-block">This is an accountant profile</small>
                    <div className="divider my-2" />
                    <small className="text-black-50">
                      Status:
                      {' '}
                      <b className="text-danger">Overdue</b>
                    </small>
                  </div>
                </div>
              </li>
              <li className="list-group-item bg-transparent py-2">
                <div className="align-box-row mb-1">
                  <div>
                    <small className="font-weight-bold">Profile completion</small>
                  </div>
                </div>
                <div className="progress-bar-sm progress-bar-animated-alt progress w-100">
                  <div
                    aria-valuemax={100}
                    aria-valuemin={0}
                    aria-valuenow={43}
                    className="progress-bar bg-success w-43"
                    role="progressbar"
                    aria-label="progress-bar"
                  />
                </div>
                <div className="align-box-row progress-bar--label mt-1 text-muted">
                  <small className="text-dark">0</small>
                  <small className="ml-auto">100%</small>
                </div>
              </li>
            </ul>
          </UncontrolledPopover>
        </span>*/}
      </div>
    </>
  ) : (
    <div />
  )
}

export default HeaderDots
