import React, { useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

interface ScrollToTopBaseProps extends RouteComponentProps {
  children: React.ReactElement
}
function ScrollToTopBase(props: ScrollToTopBaseProps) {
  const { children, location } = props
  const { pathname } = location

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [pathname])

  return children
}

const ScrollToTop = withRouter(ScrollToTopBase)

export { ScrollToTop }
