import { UserConfigDispatchTypes } from '../actions/types/userConfig'
import * as actionTypes from '../actions/types'

interface UserConfigState {
  loading: boolean
  data?: any
}
const initialState: UserConfigState = {
  loading: false,
  data: undefined
}

const userConfigReducer = (state: UserConfigState = initialState, action: UserConfigDispatchTypes): UserConfigState => {
  switch (action.type) {
    case actionTypes.GET_USER_CONFIG_FAIL:
      return {
        loading: false
      }
    case actionTypes.GET_USER_CONFIG_LOADING:
      return {
        loading: true
      }
    case actionTypes.GET_USER_CONFIG_SUCCESS:
      return {
        loading: false,
        data: action.payload
      }
    case actionTypes.POST_USER_CONFIG_FAILS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.POST_USER_CONFIG_LOADING:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.POST_USER_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      }
    default:
      return state
  }
}

export { userConfigReducer }