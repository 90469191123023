import React from 'react'
import { Button, CardHeader } from 'reactstrap'

interface UsersListHeaderProps {
  title: string
  subtitle?: string
  callbackButtonText?: string
  callbackClickHandler?: () => void
}

function UsersListHeader(props: UsersListHeaderProps) {
  const {
    title, subtitle, callbackButtonText, callbackClickHandler
  } = props

  return (
    <CardHeader>
      <div className="card-header--title">
        <small className="d-block text-uppercase mt-1">{subtitle}</small>
        <b>{title}</b>
      </div>
      {callbackButtonText && (
        <Button size="sm" className="text-nowrap mr-2" color="sherpa-success" onClick={callbackClickHandler}>
          {callbackButtonText}
        </Button>
      )}
    </CardHeader>
  )
}

export { UsersListHeader }
