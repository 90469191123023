import React, {
  FC, ReactElement, useEffect, useState
} from 'react'
import { Row, Col } from 'reactstrap'
import { useHistory, useParams } from 'react-router-dom'
import { ExampleWrapperSeamless } from 'src/layoutComponents'
import { useUser } from 'src/hooks/useUser'
import { useMatches } from 'src/hooks/useMatchList'
import { Ceo, setSwitchBackground } from 'src/store'
import { useDispatch } from 'react-redux'
import { CountriesTab } from 'src/components/Tables/Countries'
import GoalsCarousel from '../../layoutComponents/Carousels/Goals/index'
import { useMatchInfo } from '../Dashboard/hooks/useMatchInfo'
import { DashboardParams } from '../Dashboard/Dashboard'
import BannerInfo from './BannerInfo/BannerInfo'
import IndustryRadar from './IndustryCharts/IndustryRadar'
import IndustryPercentages from './IndustryCharts/IndustryPercentages'
import IndustryAmounts from './IndustryCharts/IndustryAmounts'

interface Props {
  match?: any
}

const Goals: FC<Props> = (): ReactElement | any => {
  const history = useHistory()
  const params: DashboardParams = useParams()
  const { matchInfo } = useMatchInfo(params.id)
  const user = useUser()
  const { matches } = useMatches()
  const [isUserOnBankrupt, setIsUserOnBankrupt] = useState<boolean>(false)
  const [selectedCountry, setSelectedCountry] = useState<string>('')
  const dispatch = useDispatch()

  // TODO: REMOVE IN CASE ALPHA STAYS UNIFORM WITHIN SCREENS
  // const bankrupt: any = null// matchInfo.data?.bankrupt

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const background = useBackgroundRedirect(dispatch, history.location.pathname)

  // TODO: REMOVE when next conditions apply accordinly:
  const ceo: Ceo | undefined = matchInfo?.data?.ceos?.filter(
    (ceo: { id: number }) => Number(ceo.id) === Number(user?.id)
  )[0]
  const stage = `stage_${matchInfo.data?.currentStage}`

  if (
    ceo?.ceoParameters &&
    (Object.prototype.hasOwnProperty.call(ceo.ceoParameters, stage) as any) // &&
  ) {
    try {
      dispatch(setSwitchBackground(true, 'ROUND_ENDS'))
      // eslint-disable-next-line no-empty
    } catch {}
  }

  useEffect(() => {
    if (String(history.location.pathname).substr(0, 9) === '/bankrupt') {
      dispatch(setSwitchBackground(true, 'BANKRUPT'))
    } /* else if (String(history.location.pathname).substr(0, 8) === '/endgame') {
      dispatch(setSwitchBackground(true, 'ROUND_ENDS'))
    } else {
      dispatch(setSwitchBackground(true, 'ROUND_STARTS'))
    }*/
  }, [dispatch, history.location.pathname])

  useEffect(
    () => history.listen(() => {
      if (matchInfo.data?.currentStage === 0 && history.action === 'POP') {
        history.replace('/')
      }
    }),
    [matchInfo.data, history, params.id]
  )

  useEffect(() => {
    if (matchInfo.data?.matchId && matches && matches?.length > 0) {
      setSelectedCountry(matchInfo.data.gameParameters.country)
      const bankrupt: any =
        (matchInfo.data?.ceos?.some(ceo => ceo.id === Number(user?.id) && ceo.dismissed) as boolean) ||
        matchInfo.data?.ceos?.some(
          (ceo: { id: number; bankrupt: any }) => ceo.id === Number(user?.id) && (ceo.bankrupt as boolean)
        )

      setIsUserOnBankrupt(bankrupt)
    }
  }, [
    matchInfo.data?.matchId,
    user,
    matchInfo.loading,
    matchInfo.data?.ceos,
    matches,
    matchInfo?.data?.gameParameters.country
  ])

  return (
    <>
      {/* conditions for first step on layouts: */}
      <>
        {matchInfo.data && matchInfo.data?.currentStage === 0 && !matchInfo.loading && (
          <Row>
            <Col md="12" lg="12" className={isUserOnBankrupt ? 'bankrupt-disabled' : ''}>
              <BannerInfo />
            </Col>
          </Row>
        )}
        {matchInfo.data && (
          <Row>
            <Col
              xl={matchInfo.data?.lastResultsStage >= 0 ? '6' : '9'}
              className={isUserOnBankrupt ? 'bankrupt-disabled' : ''}>
              {matchInfo.data.gameParameters.type === 'country' && selectedCountry && (
                <ExampleWrapperSeamless marginBottom={4}>
                  <CountriesTab selectedCountry={selectedCountry} />
                </ExampleWrapperSeamless>
              )}
              <ExampleWrapperSeamless marginBottom={4}>
                <GoalsCarousel />
              </ExampleWrapperSeamless>
              {matchInfo.data?.lastResultsStage >= 0 && (
                <ExampleWrapperSeamless className="mt-3">
                  <IndustryPercentages />
                </ExampleWrapperSeamless>
              )}
            </Col>
            <Col xl="6" className={isUserOnBankrupt ? 'bankrupt-disabled' : ''}>
              {matchInfo.data?.lastResultsStage >= 0 && (
                <ExampleWrapperSeamless marginBottom={4}>
                  <IndustryRadar />
                </ExampleWrapperSeamless>
              )}
              {matchInfo.data?.lastResultsStage >= 0 && (
                <ExampleWrapperSeamless>
                  <IndustryAmounts />
                </ExampleWrapperSeamless>
              )}
            </Col>
          </Row>
        )}
      </>
    </>
  )
}

export default Goals
