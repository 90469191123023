import React from 'react'
import { CreateMatchButton } from './CreateMatchButton'
import { ManageCommunityButton } from './ManageCommunityButton'

function AdminCtaButtons() {
  return (
    <>
      <CreateMatchButton />
      <ManageCommunityButton />
    </>
  )
}

export { AdminCtaButtons }
