import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/components'

function ManageCommunityButton() {
  const { t } = useTranslation()

  return (
    <Link to="/community">
      <Button size="md" className="text-nowrap" color="sherpa-primary" pill={false}>
        <span className="d-xl-block">{t('page.home.admin.cta_2')}</span>
      </Button>
    </Link>
  )
}

export { ManageCommunityButton }
