import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  Row, Col, Card, Input, ListGroup
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { User } from 'src/store/actions/types'
import { UserListItem } from './UserListItem'
import { UsersListHeader } from './UsersListHeader'

interface UsersListProps {
  users: User[]
  headerTitle: string
  // showUserAdd: boolean
  showUserAdd: (user: User) => any
  ctaText?: string
  onHeaderClick?: () => void
  onUserAdd?: (user: User) => void
  onUserRemove?: (userId: string) => void
  disabledListItem?: (user: User) => boolean
  loadingItem?: (userId: string) => boolean
}

function UsersList(props: UsersListProps) {
  const {
    users,
    headerTitle,
    onHeaderClick,
    ctaText: callbackButtonText,
    onUserAdd,
    onUserRemove,
    showUserAdd,
    disabledListItem,
    loadingItem
  } = props
  const [inputBg, setInputBg] = useState(false)
  const toggleInputBg = () => setInputBg(!inputBg)
  const [searchResultUsers, setSearchResultUsers] = useState<User[]>(users)

  useEffect(() => {
    setSearchResultUsers(users)
  }, [users, setSearchResultUsers])

  const onSearchResultChange = (value: string) => {
    if (!value) return
    const localFilteredUsers: User[] = users.filter(user => user.name.toLowerCase().includes(value.toLowerCase()))

    setSearchResultUsers(localFilteredUsers)
  }

  return (
    <div className="pending">
      <Row>
        <Col className="d-flex">
          <Card className="card-box-t w-100">
            <UsersListHeader
              title={headerTitle}
              callbackButtonText={callbackButtonText}
              callbackClickHandler={onHeaderClick}
            />
            <div className="divider" />
            <div
              className={clsx('d-flex align-items-center transition-base px-4 py-1 bg-white', {
                'py-3 bg-secondary': inputBg
              })}>
              <div className="search-wrapper w-100">
                <span className="icon-wrapper text-black">
                  <FontAwesomeIcon icon={['fas', 'search']} />
                </span>
                <Input
                  onFocus={toggleInputBg}
                  onBlur={toggleInputBg}
                  className="border-0 bg-white transition-base"
                  placeholder="Buscar jugadores..."
                  onChange={e => onSearchResultChange(e.target.value)}
                />
              </div>
            </div>
            <div className="divider" />
            <ListGroup flush={true}>
              {searchResultUsers.map(user => (
                <UserListItem
                  key={user.id}
                  user={user}
                  onUserAdd={onUserAdd}
                  onUserRemove={onUserRemove}
                  showUserAdd={showUserAdd(user)}
                  disabled={disabledListItem?.(user)}
                  loading={loadingItem?.(user.id)}
                />
              ))}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export { UsersList }
