import {
  Middleware, MiddlewareAPI, Dispatch, AnyAction
} from 'redux'

interface AnalyticsAction extends AnyAction {
  meta?: {
    analytics: {
      event: string;
      [key: string]: any;
    };
  };
}

const analyticsMiddleware: Middleware = (_store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (action: AnalyticsAction) => {
  if (action.meta && action.meta.analytics) {
    const { event, ...rest } = action.meta.analytics

    gtag('event', event, rest)
  }

  return next(action)
}

export default analyticsMiddleware