import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card } from 'reactstrap'

interface LoosingProps {
  position: number
  matchId: any
}

const Loosing: FC<LoosingProps> = (props: LoosingProps) => {
  const { position, matchId } = props

  enum pos {
    podio = 0,
    resto = 1
  }

  const greeting = position <= 2 ? pos.podio : pos.resto

  const greetings = ['Enhorabuena! Has logrado la posición #', 'Haz finalizado en la posición #']

  return (
    <>
      <Card className="p-3 mb-3 bg-arielle-smile-light composed-bg-border-radius-md">
        <div className="d-block text-md-left text-xl-left d-xl-flex justify-content-between align-items-center">
          <div className="text-white">
            <h5 className="display-4 font-weight-bold mb-1">
              {greetings[greeting]}
              {position}
            </h5>
            <p className="font-size-lg mb-2">Revisa tus métricas y continúa participando!</p>
            {' '}
            <span className="flex-end">
              <Link to={`/endgame/${matchId}/dashboard`}>
                <Button color="sherpa-primary" className="font-weight-bold">
                  Ver Estadísticas
                </Button>
              </Link>
              <Link to="/ranking">
                <Button color="sherpa-primary" className="font-weight-bold ml-2">
                  Ver Ranking
                </Button>
              </Link>
            </span>
            <div className="d-block text-md-left text-xl-left d-xl-flex" />
          </div>
        </div>
      </Card>
      <Card
        xl="5"
        className="bg-endgame-lost bg-composed bg-composed-wrapp composed-bg-border-radius-lg text-center p-4 text-white mb-3"
        style={{
          backgroundColor: 'rgb(31 23 92)',
          minHeight: '25vh',
          maxHeight: '55vh',
          transform: 'scaleX(-1)'
        }}>
        <div style={{ height: 'inherit' }} />
      </Card>
    </>
  )
}

export default Loosing
