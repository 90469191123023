import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Slider from 'react-slick'
import illustration1 from '../../../assets/images/illustrations/pack1/business_plan.svg'
import illustration2 from '../../../assets/images/illustrations/pack1/businessman.svg'
import illustration3 from '../../../assets/images/illustrations/pack1/handshake.svg'
import illustration4 from '../../../assets/images/illustrations/pack1/moving.svg'

function SliderArrowNext(props: any) {
  const { className, onClick } = props

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </div>
  )
}

function SliderArrowPrev(props: any) {
  const { className, onClick } = props

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-left']} />
    </div>
  )
}

const GoalsCarousel = () => {
  const widgetsCarousels2A = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
    responsive: [
      {
        breakpoint: 2000,
        settings: { slidesToShow: 1, slidesToScroll: 1 }
      },
      {
        breakpoint: 1450,
        settings: { slidesToShow: 1, slidesToScroll: 1 }
      },
      {
        breakpoint: 1300,
        settings: { slidesToShow: 1, slidesToScroll: 1 }
      }
    ]
  }

  return (
    <>
      {/* <Card className="card-box-t"> */}
      <Slider className="overflow-hidden" {...widgetsCarousels2A}>
        <div className="mb-0">
          <a
            href="#/"
            onClick={e => e.preventDefault()}
            className="mx-1 mx-xl-3 my-0 card shadow-xxl card-border-top br-tl br-tr card-box-hover-rise border-danger text-center p-3 p-xl-4 mb-2 d-block">
            <div className="btn-icon text-white mb-1 mx-auto">
              <img
                alt="..."
                className="img-fluid"
                style={{ minHeight: '100px', maxHeight: '170px' }}
                src={illustration4}
              />
            </div>

            <div className="text-center text-lg-center">
              <h5 className="font-weight-bold font-size-lg text-second mb-0">Hoja de ruta</h5>
              <p className="text-second text-left opacity-8 mt-3 mb-0">
                En esta pantalla tendrás siempre disponible la data que ofrecen los siguientes charts. Analiza
                el tipo de Industria seleccionado, existen tres tipos:
              </p>
              <p className="text-second text-left opacity-8 mt-0 mb-2">
                <b>Automotriz, Telefonía Movil y Agroinsumos.</b>
              </p>
              <ul>
                <li className="text-second text-left opacity-8 mt-0 mb-0">
                  Navega los siguientes slides y sigue los consejos para la toma de decisión!
                </li>
              </ul>
            </div>
          </a>
        </div>
        <div className="mb-0">
          <a
            href="#/"
            onClick={e => e.preventDefault()}
            className="mx-1 mx-xl-3 my-0 card shadow-xxl card-border-top br-tl br-tr card-box-hover-rise border-danger text-center p-3 p-xl-4 mb-2 d-block">
            <div className="btn-icon text-white mb-1 mx-auto">
              <img
                alt="..."
                className="img-fluid"
                style={{ minHeight: '100px', maxHeight: '170px', display: 'inline !important' }}
                src={illustration1}
              />
            </div>

            <div className="text-center text-lg-center">
              <h5 className="font-weight-bold font-size-lg text-second mb-0">Inversión in I+D</h5>
              <p className="text-second opacity-8 mt-3 mb-2">
                Puede ser difícil igualar a los competidores que han invertido en
                {' '}
                <b>I&D</b>
                {' '}
                cuando tu no lo
                haz hecho.
              </p>
              <p className="text-second text-left opacity-8 mt-0 mb-0">
                El incremento en los gastos de Investigación de Mercado y el Desarrollo del Producto no se
                traduce solamente en el crecimiento inmediato de las unidades demandadas a su empresa, sino
                que además impacta en la demanda futura.
              </p>
            </div>
          </a>
        </div>
        <div className="mb-0">
          <a
            href="#/"
            onClick={e => e.preventDefault()}
            className="mx-1 mx-xl-3 my-0 card shadow-xxl card-border-top br-tl br-tr card-box-hover-rise border-danger text-center p-3 p-xl-4 mb-2 d-block">
            <div className="btn-icon text-white mb-1 mx-auto">
              <img
                alt="..."
                className="img-fluid"
                style={{ minHeight: '100px', maxHeight: '170px' }}
                src={illustration2}
              />
            </div>

            <div className="text-center text-lg-center">
              <h5 className="font-weight-bold font-size-lg text-second mb-0">Innovación</h5>
              <p className="text-second opacity-8 mt-3 mb-2">
                Pero atención! ese efecto no es permanente porque con el tiempo nuestros productos empiezan a
                convertirse en obsoletos o fuera de moda.
              </p>
              <p className="text-second text-left opacity-8 mt-0 mb-0">
                De modo que la innovación siempre es necesaria para ganar en las partidas de
                {' '}
                <b>CompanyHike</b>
                .
              </p>
            </div>
          </a>
        </div>
        <div className="mb-0">
          <a
            href="#/"
            onClick={e => e.preventDefault()}
            className="mx-1 mx-xl-3 my-0 card shadow-xxl card-border-top br-tl br-tr card-box-hover-rise border-danger text-center p-3 p-xl-4 mb-3 d-block">
            <div className="btn-icon text-white mb-1 mx-auto">
              <img
                alt="..."
                className="img-fluid"
                style={{ minHeight: '100px', maxHeight: '170px' }}
                src={illustration3}
              />
            </div>

            <div className="text-center text-lg-center">
              <h5 className="font-weight-bold font-size-lg text-second mb-0">Cash Flow</h5>
              <p className="text-second opacity-8 mt-3 mb-2">
                <b>Reconociendo la importancia crítica del cash flow</b>
              </p>
              <p className="text-second text-left opacity-8 mt-0 mb-1">
                Trabajar diligentemente para mantener una salud financiera robusta te permitirá:
              </p>
              <ul>
                <li className="text-second text-left opacity-8 mt-0 mb-0">
                  Adaptarte eficazmente a los desafíos del mercado
                </li>
                <li className="text-second text-left opacity-8 mt-0 mb-0">
                  Respaldar iniciativas de responsabilidad social empresarial, asegurando que tus acciones
                  contribuyan al bienestar general y sostenible a largo plazo
                </li>
              </ul>
            </div>
          </a>
        </div>
      </Slider>
      {/* </Card> */}
    </>
  )
}

export default GoalsCarousel
