import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getMatchSchema, resetMatchData } from 'src/store/actions'
import { setSwitchBackground } from 'src/store'

function useAdminCtaButtonHandlers() {
  const history = useHistory()
  const dispatch = useDispatch()

  const [newMatchLoading, setNewMatchLoading] = useState(false)

  const createMatchButtonHandler = useCallback(async () => {
    setNewMatchLoading(true)
    await dispatch(resetMatchData())
    await dispatch(getMatchSchema())
    setNewMatchLoading(false)
    history.push('/create-match')
    await dispatch(setSwitchBackground(false))
  }, [dispatch, history])

  return { createMatchButtonHandler, newMatchLoading }
}

export { useAdminCtaButtonHandlers }
