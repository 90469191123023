import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { useUser } from 'src/hooks/useUser'
import { Ceo, CeoParameters } from 'src/store'
import { useMatchInfo } from 'src/views/Dashboard/hooks/useMatchInfo'

interface Props {
  matchId: any
  dashboardView: boolean
  simulatorView: boolean
  goalsView: boolean
}

const SimulatorNav: FC<Props> = ({
  matchId, dashboardView, simulatorView, goalsView
}: Props) => {
  const { t } = useTranslation()
  // create a EndRound Nav
  const { matchInfo } = useMatchInfo()
  const user = useUser()

  const ceo: Ceo | undefined = matchInfo?.data?.ceos?.filter(
    (ceo: { id: number }) => Number(ceo.id) === Number(user?.id)
  )[0]

  const checkRoundFinished = (ceoParams: CeoParameters, stage: string) => ceoParams && (Object.prototype.hasOwnProperty.call(ceoParams, stage) as any)

  return matchInfo && ceo ? (
    <div className="d-flex align-items-center" style={{ gap: '0.5em' }}>
      <Link to={`/dashboard/${matchId}`}>
        <Button size="lg" className="text-nowrap" color={dashboardView ? 'primary' : 'sherpa-success'}>
          <span className="d-xl-block">{t('page.navigation_buttons.dashboard')}</span>
        </Button>
      </Link>
      {ceo.ceoParameters && checkRoundFinished(ceo.ceoParameters, `stage_${matchInfo.data?.currentStage}`) ? (
        <Link to={`/dashboard/${matchId}/end-round`}>
          <Button size="lg" className="text-nowrap" color={simulatorView ? 'primary' : 'sherpa-success'}>
            <span className="d-xl-block">Fin de Turno</span>
          </Button>
        </Link>
      ) : (
        <Link to={`/dashboard/${matchId}/simulator`}>
          <Button size="lg" className="text-nowrap" color={simulatorView ? 'primary' : 'sherpa-success'}>
            <span className="d-xl-block">{t('page.navigation_buttons.management')}</span>
          </Button>
        </Link>
      )}
      <Link to={`/dashboard/${matchId}/goals`}>
        <Button size="lg" className="text-nowrap" color={goalsView ? 'primary' : 'sherpa-success'}>
          <span className="d-xl-block">{t('page.navigation_buttons.goals')}</span>
        </Button>
      </Link>
    </div>
  ) : null
}

export default SimulatorNav
