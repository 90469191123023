/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch } from 'redux'
import { client } from 'src/api/client'
import { AcceptMatchInvitationDispatchTypes } from './types/acceptMatchInvitation'
import * as actionTypes from './types/acceptMatchInvitation'

interface AcceptMatchInvitationConfig {
  invitation_id: string
}
const acceptMatchInvitation = (config: AcceptMatchInvitationConfig) => async (dispatch: Dispatch<AcceptMatchInvitationDispatchTypes>) => {
  const { invitation_id } = config

  try {
    dispatch({
      type: actionTypes.ACCEPT_INVITATION_LOADING
    })

    // TODO: REFACTOR-REMOVE, REQUEST TO BACKEND ABOUT WHICH API ???
    // accept_match_invitation/ ???
    // accept_game_invitation/ ???
    const temporaryHashHack = invitation_id.substring(invitation_id.indexOf('ion/') + 4)

    const res = await client.get(`accept_match_invitation/${temporaryHashHack}`)

    return dispatch({
      type: actionTypes.ACCEPT_INVITATION_SUCCESS,
    })
  } catch (e) {
    return dispatch({
      type: actionTypes.ACCEPT_INVITATION_FAIL,
    })
  }
}

export {
  acceptMatchInvitation
}
