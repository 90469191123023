import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/components'
import { useAdminCtaButtonHandlers } from './useAdminCtaButtonHandlers'

function CreateMatchButton() {
  const { createMatchButtonHandler, newMatchLoading } = useAdminCtaButtonHandlers()
  const { t } = useTranslation()

  return (
    <Button
      pill={false}
      size="md"
      showSpinner={newMatchLoading}
      className="mr-2"
      fixedWidth={170}
      color="sherpa-success"
      onClick={createMatchButtonHandler}>
      {t('page.home.admin.cta_1')}
    </Button>
  )
}

export { CreateMatchButton }
