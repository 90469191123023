import React from 'react'
import avatarPlaceholder from 'src/assets/images/avatars/avatar_placeholder_large.png'
import { Togglable } from '../Togglable'

interface AvatarProps {
  src: string | null | undefined
}

function Avatar(props: AvatarProps) {
  const { src } = props

  return (
    <div className="avatar-icon-wrapper d-50 mr-3">
      <div className="avatar-icon rounded-circle d-50">
        <Togglable enabled={Boolean(src)} fallback={<img src={avatarPlaceholder} alt="" />}>
          {/* TODO: create a generic component for images management */}
          {src && <img alt="..." src={src} />}
        </Togglable>
      </div>
    </div>
  )
}
export { Avatar }
