import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import { Grid } from 'react-feather'
import { useUser } from 'src/hooks/useUser'
import { UserCommunity } from 'src/store/actions/types'
import { useDispatch } from 'react-redux'
import { setCommunity } from 'src/store'
import { postUserConfig } from 'src/store/actions/userConfig/setUserConfig'
import useWindowWidth from 'src/hooks/useWindowWidth'
import { useTranslation } from 'react-i18next'

function CommunitiesDropDown() {
  const user = useUser()
  const dispatch = useDispatch()
  const [screenSizeMobile, setScreenSizeMobile] = useState(false)
  const isMobile = useWindowWidth() <= 1200 // 750
  const { t } = useTranslation()

  useEffect(() => {
    setScreenSizeMobile(isMobile)
  }, [isMobile])

  function selectCommunity(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, community: UserCommunity) {
    dispatch(setCommunity(community))
    // TODO: Refactor Front/Backend format
    dispatch(
      postUserConfig({
        user_data: `{"communityId": "${community.id}"}`
      })
    )
  }

  return user?.communities ? (
    <div className={`app-header${!screenSizeMobile && '-menu'}`}>
      <UncontrolledDropdown tag="span" className="position-relative">
        <DropdownToggle
          color="neutral-dark"
          size={isMobile ? 'sm' : 'md'}
          className="ml-2 mr-2 btn-transition-none">
          {t('page.community.selector')}
        </DropdownToggle>
        <DropdownMenu className="overflow-hidden border-0 bg-midnight-bloom p-3 mt-2">
          <div className="dropdown-mega-menu-md">
            {/* <div className="text-center">
              <div className="font-weight-bold font-size-lg mb-1 text-white">Comunidades</div>
            </div>*/}
            <div className="d-flex flex-wrap">
              {/* TODO: mark selected one */}
              {user?.communities.map((community: UserCommunity, index) => (
                <div className="w-50 p-2" key={community.id}>
                  <Link
                    to="/home"
                    onClick={e => selectCommunity(e, community)}
                    className="btn card card-box text-left d-flex justify-content-center px-3 py-2 w-100 border-0"
                    title={`${index}`}>
                    <div>
                      <Grid className="h1 d-block my-2 text-success" />
                      <div className="font-weight-bold font-size-lg text-black">{community.name}</div>
                      <div className="font-size-md mb-1 text-black-50">{community.name}</div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  ) : (
    <div />
  )
}

export { CommunitiesDropDown }
