export const SET_MATCH_LIST = 'SET_MATCH_LIST'
export const MATCH_LIST_LOADING = 'MATCH_LIST_LOADING'
export const MATCH_LIST_FAIL = 'MATCH_LIST_FAIL'
export const MATCH_LIST_SUCCESS = 'MATCH_LIST_SUCCESS'


export type MatchList = {
  Match: Match[]
  MatchInvitations: MatchInvitations[]
}

export type Match = {

  /* OLD TYPE DEFINITION: matchId: number,
    id: string,
    name: string,
    type: string,
    playerPosition: number,
    currentStage: number,
    isAdmin: boolean,
    createdAt: string,*/

  player_type: string,
  id: string,
  name: string,
  current_stage: number
  type: string,
  players: number,
  industry: string,
  stages: number,
  country: string,
  scenario: string,
  active: boolean,
  status_id: number

}

/*   NEW TYPE DEFINITION
    {
      "player_type": "goverment",
      "id": 9,
      "name": "marabaru",
      "current_stage": 0,
      "type": "scenario",
      "players": 4,
      "industry": "cars",
      "stages": 4,
      "country": "",
      "scenario": "argentina_crisis_2001",
      "active": false
    },*/

export type MatchInvitations = {

  /* OLD TYPE DEFINITION: matchId: number,
      id: string,
      name: string,
      type: string,
      playerPosition: number,
      currentStage: number,
      isAdmin: boolean,
      createdAt: string,*/
}

export interface SetMatchList {
  type: typeof SET_MATCH_LIST;
  payload: MatchList;
}

export interface MatchListLoading {
  type: typeof MATCH_LIST_LOADING;
}

export interface MatchListFail {
  type: typeof MATCH_LIST_FAIL;
}

export interface MatchListSuccess {
  type: typeof MATCH_LIST_SUCCESS;
  payload: MatchList;
}

export type MatchListDispatchTypes =
  | MatchListLoading
  | MatchListFail
  | MatchListSuccess;

export type SetMatchListDispatch = SetMatchList | MatchList;
