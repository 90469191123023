import { Dispatch } from 'redux'
import { client } from 'src/api/client'
import { State } from 'src/store/reducers/rootReducer'
import {
  SetNotificationListDispatch, NotificationsDispatchTypes, Notification
} from '../types/notification'
import * as actionTypes from '../types/notification'

const setNotificationList = (notificationList: Notification[]): SetNotificationListDispatch => ({
  type: actionTypes.SET_NOTIFICATIONS,
  payload: notificationList
})

function getNotificationList() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: Dispatch<NotificationsDispatchTypes>, getState: () => State) => {
    try {
      dispatch({
        type: actionTypes.NOTIFICATIONS_LOADING
      })

      const res = await client.get('notifications')

      dispatch({
        type: actionTypes.NOTIFICATIONS_SUCCESS,
        payload:
          res.data
      })
    } catch (e) {
      dispatch({
        type: actionTypes.NOTIFICATIONS_LOADING
      })
    }
  }
}

export { setNotificationList, getNotificationList }
