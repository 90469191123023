import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUserCommunityId } from 'src/features/communities'
import { State } from 'src/store'
import { getRankingByComunnity, getRankingGlobal } from 'src/store/actions/ranking'
import { useUser } from './useUser'

const useRanking = () => {
  const dispatch = useDispatch()
  const user = useUser()
  const communityId = useUserCommunityId()
  const rankingGlobal = useSelector((state: State) => state.ranking.rankingGlobal)
  const rankingCommunity = useSelector((state: State) => state.ranking.rankingCommunity)
  const isRankingCommunityLoading = useSelector((state: State) => state.ranking.loadingRankingCommunity)
  const isRankingGlobalLoading = useSelector((state: State) => state.ranking.loadingRankingCommunity)

  useEffect(() => {
    if (!rankingGlobal) {
      dispatch(getRankingGlobal())
    }

    if (!rankingCommunity) {
      dispatch(getRankingByComunnity(communityId, user))
    }
  }, [dispatch, rankingGlobal, communityId, rankingCommunity, user])

  return {
    rankingGlobal, rankingCommunity, isRankingCommunityLoading, isRankingGlobalLoading
  }
}

export { useRanking }