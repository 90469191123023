import { config } from 'src/config'
import { getFromStorage } from 'src/utils/localStorage'

interface SessionData {
  token: string
  expirationDate: number
}
const key = config.localStorage.keys.session

function getSessionDataFromLocalStorage(): SessionData | null{
  const sessionData = getFromStorage<SessionData>(key)

  if (!sessionData) return null
  return {
    token: sessionData.token,
    expirationDate: sessionData.expirationDate
  }
}

function isTimestampExpired(date: number) {
  return Date.now() > date
}

function isLoggedIn() {
  const sessionData = getSessionDataFromLocalStorage()

  if (!sessionData) return false
  const isSessionExpired = isTimestampExpired(sessionData.expirationDate)

  if (isSessionExpired) {
    return false
  }

  return true
}

export { isLoggedIn }
export type { SessionData }
