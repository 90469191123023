import React, { FC, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card } from 'reactstrap'
import Slider from 'react-slick'
// import { useTranslation } from 'react-i18next'
import { getImagePaths } from 'src/utils/companyLogos'
import { useMatches } from 'src/hooks/useMatchList'
import { useMatchInfo } from 'src/views/Dashboard/hooks/useMatchInfo'

interface SliderArrowProps {
  className?: string
  onClick?: any
}

const SliderArrowNext: FC<SliderArrowProps> = (props: SliderArrowProps) => {
  const { className, onClick } = props

  return (
    <div className={className} onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </div>
  )
}

const SliderArrowPrev: FC<SliderArrowProps> = (props: SliderArrowProps) => {
  const { className, onClick } = props

  return (
    <div className={className} onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>
      <FontAwesomeIcon icon={['fas', 'chevron-left']} />
    </div>
  )
}

interface PropsCarousel {
  companyName: string
  selectLogo: (logo: number) => void
}

const CarouselDashboard: FC<PropsCarousel> = (props: PropsCarousel) => {
  // const { t } = useTranslation()
  const { companyName, selectLogo } = props
  const [industry, setIndustry] = useState('')
  const { matches } = useMatches()
  const { matchInfo } = useMatchInfo()
  const matchId = String(matchInfo.data?.matchId)

  useEffect(() => {
    const getMatchProp = (matchId: any): any => {
      const matchType = matches?.filter(match => Number(matchId) === Number(match.id))

      const ind: any = matchType && matchType[0].industry

      return ind
    }

    setIndustry(getMatchProp(matchId))
  }, [industry, matchId, matches])

  const imagePath: Array<any> = getImagePaths(industry)

  const widgetsCarousels3A = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
    afterChange: (currentSlide: number) => selectLogo(currentSlide),
    onInit: () => selectLogo(0)
  }

  return imagePath.length > 0 && industry ? (
    <>
      <div className="rounded-lg br-xl-right-0">
        <Slider
          className="rounded-lg overflow-hidden slider-arrows-outside slider-dots-outside slider-dots-light"
          {...widgetsCarousels3A}>
          {imagePath &&
            imagePath.map((logo: any, index: number) => (
              <div key={index}>
                <Card className="text-center m-3">
                  <Card className="card-transparent mx-auto hover-scale-sm mt-3">
                    <a
                      href="#/"
                      onClick={() => selectLogo(index)}
                      className="card-img-wrapper card-box-hover rounded">
                      <img alt="..." className="card-img-top rounded-sm" src={logo} style={{ width: 100 }} />
                    </a>
                  </Card>
                  <div className="card-header-alt d-flex flex-column justify-content-center p-3">
                    <h6 className="font-weight-bold font-size-lg mb-2 text-black">{companyName}</h6>
                    {/* <p className="text-black-50 font-size-sm mb-0">{t('page.add_company.select_logo')}</p>*/}
                  </div>
                </Card>
              </div>
            ))}
        </Slider>
      </div>
    </>
  ) : (
    <div>cargando logos..</div>
  )
}

export default CarouselDashboard
