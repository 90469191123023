export const GET_USER_CONFIG = 'GET_USER_CONFIG'
export const GET_USER_CONFIG_LOADING = 'GET_USER_CONFIG_LOADING'
export const GET_USER_CONFIG_FAIL = 'GET_USER_CONFIG_FAIL'
export const GET_USER_CONFIG_SUCCESS = 'GET_USER_CONFIG_SUCCESS'

export const POST_USER_CONFIG_LOADING = 'POST_USER_CONFIG_LOADING'
export const POST_USER_CONFIG_FAILS = 'POST_USER_CONFIG_FAILS'
export const POST_USER_CONFIG_SUCCESS = 'POST_USER_CONFIG_SUCCESS'

export interface UserConfig {
  data?: any
}

export interface getUserConfig {
  type: typeof GET_USER_CONFIG
  payload: {}
}

export interface UserConfigLoading {
  type: typeof GET_USER_CONFIG_LOADING
}

export interface UserConfigFail {
  type: typeof GET_USER_CONFIG_FAIL
}

export interface UserConfigSuccess {
  type: typeof GET_USER_CONFIG_SUCCESS
  payload: {}
}

export interface PostUserConfigLoading {
  type: typeof POST_USER_CONFIG_LOADING;
}

export interface PostUserConfigFails {
  type: typeof POST_USER_CONFIG_FAILS;
}

export interface PostUserConfigSuccess {
  type: typeof POST_USER_CONFIG_SUCCESS;
  payload: string;
}

export type UserConfigDispatchTypes =
  | UserConfigLoading
  | UserConfigFail
  | UserConfigSuccess
  | PostUserConfigLoading
  | PostUserConfigFails
  | PostUserConfigSuccess

export type SetUserConfigDispatch = getUserConfig