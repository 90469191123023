import React from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import SimulatorNav from '../HeaderMatch/NavViews/SimulatorNav'

interface SubHeaderProps {
  pageTitleStyle?: string
  pageTitleBackground?: string
  pageTitleShadow?: any
  pageTitleBreadcrumb?: boolean
  pageTitleIconBox?: string
  title?: string
  description?: string
  extraContent?: React.ReactNode
  nav?: Boolean
  matchId?: any
}

function SubHeader(props: SubHeaderProps) {
  const {
    pageTitleBackground,
    pageTitleBreadcrumb,
    pageTitleStyle,
    pageTitleShadow,
    pageTitleIconBox,
    title,
    description,
    extraContent,
    nav = false,
    matchId
  } = props

  return (
    <>
      <div
        className={clsx('app-page-title', pageTitleStyle, pageTitleBackground, {
          'app-page-title-reduced--shadow': pageTitleShadow
        })}>
        <div>
          {pageTitleBreadcrumb && (
            <ol className="app-page-title-reduced--breadcrumb breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <FontAwesomeIcon icon={['fas', 'tachometer-alt']} />
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/home">Dashboards</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>{title}</span>
              </li>
            </ol>
          )}
          <div className="app-page-title-reduced--first">
            {pageTitleIconBox && (
              <div className="app-page-title-reduced--iconbox d-60">
                <div className="d-50 d-flex gradient-icon align-items-center justify-content-center">
                  <i className={`${pageTitleIconBox} display-3 gradient-icon bg-ripe-malin`} />
                </div>
              </div>
            )}
            <div className="app-page-title-reduced--heading">
              <h1>{title}</h1>
              {description && (
                <div className="app-page-title-reduced--description font-weight-bold">{description}</div>
              )}
            </div>
          </div>
        </div>
        {nav && (
          <div className="d-flex align-items-end">
            <SimulatorNav matchId={matchId} dashboardView={false} goalsView={false} simulatorView={true} />
          </div>
        )}
        {extraContent && <div className="d-flex align-items-center">{extraContent}</div>}
      </div>
    </>
  )
}

export default SubHeader
export { SubHeader }
