import { Dispatch } from 'redux'
import { client } from 'src/api'
import * as actionTypes from '../types'
import {
  CeoDecisionsDispatchTypes, CeoDecisionsData, CeoDecisionsSchema, Decision, ApprovedDecisions, CeoBalance
} from '../types'
import { calcCeoDecisionsParameters } from './utils'

const addCeoDecisionsData = (department: keyof ApprovedDecisions) => ({
  type: actionTypes.APPROVE_DEPARTMENT_DECISIONS,
  payload: department
})

const updateAvailableFunds = (data: number) => ({
  type: actionTypes.UPDATE_AVAILABLE_FUNDS,
  payload: data
})

const getCeoDecisionsSchema = (matchId: string, currentStage: number) => async (dispatch: Dispatch<CeoDecisionsDispatchTypes>) => {
  try {
    dispatch({
      type: actionTypes.GET_CEO_DECISIONS_SCHEMA_LOADING
    })

    const res = await client.get(`game/get_ceo_parameters/${matchId}/${currentStage}`)
    const schema: CeoDecisionsSchema = res.data

    const initialParameters: CeoDecisionsData = {
      matchId,
      decisions: {
        price: schema.price.min,
        production: schema.production.min,
        recycle: schema.recycle.options[0],
        qualityControl: schema.quality_control.options[0],
        safety: schema.safety.options[0],
        mkt: 0,
        survey: 0,
        design: 0,
        ibk: schema.ibk.min,
        lending: 0
      },
      approved: {
        marketing: false,
        production: false,
        finance: false
      }
    }

    dispatch({
      type: actionTypes.GET_CEO_DECISIONS_SCHEMA_SUCCESS,
      payload: schema as CeoDecisionsSchema
    })

    dispatch({
      type: actionTypes.SET_INITIAL_CEO_DECISIONS,
      payload: initialParameters
    })
  } catch (error) {
    dispatch({
      type: actionTypes.GET_CEO_DECISIONS_SCHEMA_FAIL
    })
  }
}

const updateCeoDecisions = (data: Decision) => ({
  type: actionTypes.UPDATE_CEO_DECISIONS,
  payload: data
})

const postCeoDecisions = (data: CeoDecisionsData, balance: CeoBalance) => async (dispatch: Dispatch<CeoDecisionsDispatchTypes>) => {
  try {
    dispatch({
      type: actionTypes.POST_CEO_DECISIONS_LOADING
    })

    const ceoDecisionsValues = JSON.stringify({ game_id: Number(data.matchId), parameters: calcCeoDecisionsParameters(data, balance) })

    const res = await client.post('game/set_ceo_parameters', ceoDecisionsValues, {
      headers: {
        'Content-Type': 'application/json'
      }
    })

    dispatch({
      type: actionTypes.POST_CEO_DECISIONS_SUCCESS,
      payload: res.data
    })
  } catch (error) {
    const value = data
      ? `${data?.matchId} ${data?.approved} ${data?.decisions}`
      : 'No data available'

    dispatch({
      type: actionTypes.POST_CEO_DECISIONS_FAIL,
      meta: {
        analytics: {
          event: actionTypes.POST_CEO_DECISIONS_FAIL,
          category: 'POST',
          action: 'Decisions Save',
          label: 'error',
          value
        }
      }
    })
  }
}

export {
  addCeoDecisionsData, getCeoDecisionsSchema, postCeoDecisions, updateAvailableFunds, updateCeoDecisions
}