import { User } from '../user'

const actions = {
  REMOVE_COMMUNITY_MEMBERS_LOADING: 'REMOVE_COMMUNITY_MEMBERS_LOADING',
  REMOVE_COMMUNITY_MEMBERS_FAIL: 'REMOVE_COMMUNITY_MEMBERS_FAIL',
  REMOVE_COMMUNITY_MEMBERS_SUCCESS: 'REMOVE_COMMUNITY_MEMBERS_SUCCESS'
}

export interface RemoveCommunityMemberLoading {
  type: typeof actions.REMOVE_COMMUNITY_MEMBERS_LOADING
}

export interface RemoveCommunityMemberFail {
  type: typeof actions.REMOVE_COMMUNITY_MEMBERS_FAIL
}

export interface RemoveCommunityMemberSuccess {
  type: typeof actions.REMOVE_COMMUNITY_MEMBERS_SUCCESS
  payload: {
    communityMembers: User[]
    userId: string
  }
}

export type RemoveCommunityMemberDispatchTypes =
  | RemoveCommunityMemberLoading
  | RemoveCommunityMemberSuccess
  | RemoveCommunityMemberFail

export { actions as removeCommunityMembersActions }