import { useMemo } from 'react'

function usePageTransition() {
  return useMemo(() => ({
    type: 'tween',
    ease: 'anticipate',
    duration: 1.2,
  }), [])
}

export { usePageTransition }
