import React, { useCallback, useState } from 'react'
import { Badge, Button, ListGroupItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Invitation } from 'src/store/actions/types/notification'
import { useDispatch, useSelector } from 'react-redux'
import { acceptMatchInvitation } from 'src/store/actions/acceptMatchInvitation'
import { useLocation } from 'react-router'
import { getMatchList, State } from 'src/store'
// import { Avatar } from '../../../../components/Avatar/Avatar'
import { Togglable } from '../../../../components'

interface UserListItemProps {
  invitation: Invitation
  showUserAdd: boolean
  onInvitationAccepted: (id: number) => void
}

function InvitationListItem(props: UserListItemProps) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { pathname: location } = useLocation()
  const { invitation, onInvitationAccepted, showUserAdd } = props
  const [clicked, setClicked] = useState(false)
  const community = useSelector((state: State) => state.communities.userCommunity.id)

  // TODO: REMOVE REF
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const clickHandler = () => showUserAdd ? onInvitationAccepted?.(invitation) : onUserRemove?.(String(invitation.match_id))

  const postAcceptInvite = useCallback(
    async (matchId, invitation_id) => {
      const userConfig = await dispatch(acceptMatchInvitation({ invitation_id }))

      if (JSON.stringify(userConfig) === '{"type":"ACCEPT_INVITATION_SUCCESS"}') {
        onInvitationAccepted(matchId)

        if (location === '/home') {
          typeof community !== 'string' && dispatch(getMatchList(community))
        }

        return setClicked(false)
      }
      return setClicked(false)
    },
    [dispatch, community, location, onInvitationAccepted]
  )

  const onAcceptMatchInvite = (match_id: number, invitation_id: string) => {
    // TODO: REDO setClicked(true) when database is consistent
    setClicked(true)
    postAcceptInvite(match_id, invitation_id)
  }

  return (
    <ListGroupItem
      action={true}
      href="#/"
      className="d-flex hover-color justify-content-between align-items-center py-3"
      style={{ maxWidth: 410, minWidth: 410 }}>
      <div className="d-flex align-items-center">
        {/* <Avatar src={invitation.match_name} />*/}
        {/* <div>
          <div className="font-weight-bold font-size-sm text-black">{invitation.match_name || `Partida ${invitation.match_invitation_id}` }</div>
        </div>*/}
        <div>
          <div className="font-weight-bold font-size-sm text-black">
            {invitation.match_name || `Partida ${invitation.match_id}`}
          </div>
          <div className="d-flex align-items-center font-size-xs">
            <Badge color="success" className="badge-circle border-white border-1 mr-2">
              Completed
            </Badge>
            <div className="text-success font-weight-bold">{invitation.community_name}</div>
          </div>
        </div>
      </div>
      <div>
        <Button
          size="sm"
          color={clicked ? 'success' : 'neutral-dark'}
          className="px-3"
          onClick={() => !clicked && onAcceptMatchInvite(invitation.match_id, invitation.action)}>
          <span className="btn-wrapper--icon">
            <Togglable enabled={clicked} fallback={<FontAwesomeIcon icon={['fas', 'plus']} />}>
              <FontAwesomeIcon icon={['fas', 'check']} />
            </Togglable>
          </span>
          <Togglable
            enabled={showUserAdd}
            fallback={
              <span className="btn-wrapper--label">{t('component.invitation_list_item.accept')}</span>
            }>
            <span className="btn-wrapper--label">
              {clicked ? 'Partida Aceptada' : t('component.invitation_list_item.accept')}
            </span>
          </Togglable>
        </Button>
      </div>
    </ListGroupItem>
  )
}

export { InvitationListItem }
