import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMatchInvitations, State } from 'src/store'

const useInvitations = (matchId?: string) => {
  const dispatch = useDispatch()
  const [loadingUser, setLoadingUser] = useState<string | null>(null)
  const { loading, data: invitations } = useSelector((state: State) => state.invitationsList)

  useEffect(() => {
    const fetchInvitations = async (matchId:string) => {
      await dispatch(getMatchInvitations(matchId))
    }

    if (matchId) {
      fetchInvitations(matchId)
    }
  }, [dispatch, matchId])

  useEffect(() => {
    if (!loading) {
      setLoadingUser(null)
    }
  }, [loading])

  const isInvited = (userId: string) => invitations.some(invitation => invitation.userId === userId.toString())

  const invitedUsersToMatch = () => invitations.filter(invitation => invitation.matchId === matchId)

  const isLoading = () => (userId: string) => {
    if (!loadingUser) return loading

    return loadingUser === userId
  }

  return {
    invitations, isInvited, invitedUsersToMatch, isLoading, setLoadingUser
  }
}

export { useInvitations }