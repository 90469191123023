import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router'

function useHistoryParamsSetter() {
  const history = useHistory()

  const currentPathRef:any = useRef()


  interface CustomLocation extends Location {
    action: string;
  }

  useEffect(() => {
    currentPathRef.current = history.location.pathname
    // console.log('currentPathRef.current')
    // console.log(currentPathRef.current)

    const unlisten = history.listen((update: any, action: CustomLocation | any) => {
      // console.log(update)
      // console.log(action)
      if (action === 'POP') {
        // The previous path is the one stored in ref before the change
        const previousPath:any = currentPathRef.current

        // Modify the location to add your desired params on back navigation
        const currentSearch = new URLSearchParams(update.search)
        // Set the parameter, here 'prevPath' is just an example

        currentSearch.set('prevPath', previousPath)

        // Replace the current entry in the history stack
        history.replace({
          pathname: update.pathname,
          search: currentSearch.toString()
        })
        currentPathRef.current = update.pathname
      } else {
        // Update the current path in the ref for any other navigation
        currentPathRef.current = update.pathname
      }
    })

    return () => {
      unlisten()
    }
  }, [history])

  return null
}

export default useHistoryParamsSetter