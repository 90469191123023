import React from 'react'

import {
  Row, Col, Card, CardHeader
} from 'reactstrap'

import CountUp from 'react-countup'
import { mapIso3ToIso2Country } from 'src/utils'
import { FlagIcon, FlagIconCode } from 'react-flag-kit'
// import { useTranslation } from 'react-i18next'
import { getCountryHeadline, statsProps } from './utils'

function CountriesStats(props: statsProps) {
  const { selectedCountry, data } = props
  // const { t } = useTranslation()

  return (
    <>
      <Card className="card-box mb-1 mt-3">
        <CardHeader>
          <div className="card-header--title">
            <span className="font-weight-bold">{data.economy}</span>
            {selectedCountry && getCountryHeadline(selectedCountry)}
            <span className="font-weight-bold text-primary font-size-l">
              #
              {data.easeDoingBusiness}
              {' '}
              Doing business
            </span>
          </div>
          {selectedCountry && (
            <>
              <div className="align-box-row">
                <FlagIcon
                  className="mr-2"
                  size={40}
                  code={mapIso3ToIso2Country(selectedCountry) as FlagIconCode}
                />
              </div>
            </>
          )}
        </CardHeader>
        <Row className="no-gutters">
          <Col xl="4" className="p-4">
            <div className="divider-v divider-v-lg" />
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <b>PIB</b>
                <div className="text-black-50">PPA (Paridad Poder Adquisitivo)</div>
              </div>
              <div className="font-weight-bold text-success font-size-xl">
                <CountUp
                  start={0}
                  end={data.pib}
                  duration={5}
                  delay={1.5}
                  separator=""
                  prefix="$"
                  decimals={3}
                  decimal=","
                />
              </div>
            </div>
            <div className="divider my-3" />
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <b>Crédito interno</b>
                <div className="text-black-50" />
              </div>
              <div className="font-weight-bold text-success font-size-xl">
                <CountUp
                  start={0}
                  end={data.internalCredit}
                  duration={5}
                  delay={2.5}
                  separator=""
                  suffix="%"
                  decimals={0}
                  decimal=","
                />
              </div>
            </div>
            <div className="divider my-3" />
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <b>Impuesto a las Ganancias</b>
                <div className="text-black-50" />
              </div>
              <div className="font-weight-bold text-success font-size-xl">
                {data.profitTax}
                %
              </div>
            </div>
          </Col>
          <Col xl="4" className="p-4">
            <div className="divider-v divider-v-lg" />

            <div className="d-flex align-items-center justify-content-between">
              <div>
                <b>Score Paying Taxes</b>
                <div className="text-black-50">Ranking Mundial</div>
              </div>
              <div className="font-weight-bold text-success font-size-xl">
                <CountUp
                  start={0}
                  end={data.scorePayingTaxes}
                  duration={5}
                  delay={1.5}
                  prefix="#"
                  separator=""
                  decimals={0}
                  decimal=","
                />
              </div>
            </div>
            <div className="divider my-3" />
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <b>Tasa de Interés</b>
                <div className="text-black-50" />
              </div>
              <div className="font-weight-bold text-success font-size-xl">
                <CountUp
                  start={0}
                  end={data.interestrate}
                  duration={5}
                  delay={2.5}
                  suffix="%"
                  separator=""
                  decimals={1}
                  decimal=","
                />
              </div>
            </div>
            <div className="divider my-3" />
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <b>Impuesto al Trabajo</b>
                <div className="text-black-50" />
              </div>
              <div className="font-weight-bold text-warning font-size-xl">
                {data.laborTax}
                %
              </div>
            </div>
          </Col>
          <Col xl="4" className="p-4">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <b>Ease of Doing Business</b>
                <div className="text-black-50">Ranking Mundial</div>
              </div>
              <div className="font-weight-bold text-success font-size-xl">
                #
                {data.easeDoingBusiness}
              </div>
            </div>
            <div className="divider my-3" />
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <b>Salario</b>
                <div className="text-black-50">Mensual en USD</div>
              </div>
              <div className="font-weight-bold text-danger font-size-xl">
                $
                {data.salary}
              </div>
            </div>
            <div className="divider my-3" />
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <b>IVA | Vat Tax</b>
                <div className="text-black-50" />
              </div>
              <div className="font-weight-bold text-danger font-size-xl">
                {data.vatTax}
                %
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  )
}
export { CountriesStats }
