/* eslint-disable no-nested-ternary */
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import useWindowWidth from '../../hooks/useWindowWidth'

// TODO: IMAGEN DE FONDO PARA UN FUTURO DARKMODE INTERMEDIO
// ..... Y PARA NOSOTROS: DESARROLLO MODO NOCTURNO
import desktopImageStart from '../../assets/images/inicio.jpg'
// import desktopImageStart from '../../assets/images/iniciodark.png'
import desktopImageEnd from '../../assets/images/final.jpg'
import desktopImageBankrupt from '../../assets/images/composed-bg/bankrupt-sherpa.jpg'
import mobileImageBankrupt from '../../assets/images/composed-bg/bankrupt-sherpa-mobile.jpg'
import mobileImageInicio from '../../assets/images/mobile/inicio.jpg'
import mobileImageFinal from '../../assets/images/mobile/end_round.png'
import hero2 from '../../assets/images/hero-bg/hero-2.jpg'
import { State } from '../../store'

const BackgroundImage: FC = () => {
  const isIlustrationBackground = useSelector((state: State) => state.theme.isIlustrationBackground)
  const matchStatusImage = useSelector((state: State) => state.theme.matchStatusImage)

  const image =
    matchStatusImage === 'ROUND_ENDS'
      ? desktopImageEnd
      : matchStatusImage === 'BANKRUPT'
        ? desktopImageBankrupt
        : desktopImageStart

  const imageUrl =
    useWindowWidth() >= 750
      ? image
      : matchStatusImage === 'ROUND_ENDS'
        ? mobileImageFinal
        : matchStatusImage === 'BANKRUPT'
          ? mobileImageBankrupt
          : mobileImageInicio

  return (
    <>
      {isIlustrationBackground ? (
        <div
          className="app-background-image"
          style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundPosition: imageUrl === image ? 'center' : 'inherit',
            backgroundSize: imageUrl === image ? 'cover' : 'cover',
            backgroundRepeat: 'no-repeat',
            alignItems: 'center',
            position: 'absolute',
            justifyContent: 'center'
          }}
        />
      ) : (
        <div
          className="bg-composed-wrapper--nofilter opacity-6"
          style={{ backgroundImage: `url(${hero2})`, zIndex: 1 }}
        />
      )}
    </>
  )
}

export default BackgroundImage
