import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { Card, Button } from 'reactstrap'

interface RibbonContentProps {
  matchId: any
}

const RibbonContent: FC<RibbonContentProps> = (props: RibbonContentProps) => {
  const { matchId } = props

  return (
    <>
      <Card
        className="card-box text-center pb-4 align-box-column flex-end"
        style={{ borderRadius: '1.6rem' }}>
        <span className="ribbon-angle ribbon-angle--top-right ribbon-primary-blue">
          <small>Quiebra</small>
        </span>
        <span className="ribbon-angle ribbon-angle--top-left ribbon-primary-blue">
          <small>Quiebra</small>
        </span>
        <span className="ribbon-angle ribbon-angle--bottom-right ribbon-primary-blue">
          <small>Quiebra</small>
        </span>
        <span className="ribbon-angle ribbon-angle--bottom-left ribbon-primary-blue">
          <small>Quiebra</small>
        </span>
        <h1 className="font-size-xxl sherpa font-weight-bold mt-4 mb-2 px-3">Te haz perdido del grupo!</h1>
        <h3 className="font-size-xl font-weight-bold mt-2 mb-2 px-3">
          Llevar a tu empresa a la cima no será posible en esta partida
        </h3>
        <p className="font-size-lg card-text px-5 mb-4">
          Las decisiones realizadas te han hecho perder mucho terreno. Revisa tus estadísticas en el Tablero y
          analiza la Industria en Objetivos. Únete a otras partidas y vuelve a intentarlo!
        </p>
        <div>
          <span className="mr-2">
            <Link to={`/bankrupt/${matchId}/dashboard`}>
              <Button color="sherpa-secondary" className="font-weight-bold">
                Ver Estadísticas
              </Button>
            </Link>
          </span>
          <span>
            <Link to="/home">
              <Button color="sherpa-dark" className="font-weight-bold">
                Volver a Home
              </Button>
            </Link>
          </span>
        </div>
      </Card>
    </>
  )
}

export default RibbonContent
