import React, { FC, useMemo } from 'react'
import {
  Card, CardBody, CardHeader, Col, Row
} from 'reactstrap'
import { useRanking } from 'src/hooks/useRanking'
import './style.scss'
import { SubHeader } from 'src/layoutComponents'
import { useTranslation } from 'react-i18next'
import RankingList from './RankingList'

interface RankingProps {
  header?: boolean
}

const Ranking: FC<RankingProps> = (props: RankingProps) => {
  const { t } = useTranslation()
  const { header } = props
  const { rankingGlobal, rankingCommunity } = useRanking()
  const communityRanking = useMemo(() => rankingCommunity?.ranking || [], [rankingCommunity])
  const communityRankingCurrentPlayer = rankingCommunity?.user_ranking
  const globalRanking = useMemo(() => rankingGlobal?.ranking || [], [rankingGlobal])
  const globalRankingCurrentPlayer = rankingGlobal?.user_ranking

  return (
    <div id="ranking-page">
      {(header || header === undefined) && (
        <SubHeader
          title={t('page.ranking.title')}
          description={t('page.ranking.description')}
          pageTitleIconBox="pe-7s-cup"
        />
      )}
      <Row className={`justify-content-md-center ${header ? 'my-2' : 'my-0'}`}>
        <Col xs={12} md={8} lg={5} xl={4} className="mb-2">
          <Card>
            <CardHeader className="text-center card-header-alt px-2 py-3">
              <h3>{t('page.ranking.community_ranking')}</h3>
            </CardHeader>
            <CardBody>
              <div className="ranking-list-wrapper">
                {communityRanking.length && (
                  <RankingList players={communityRanking} currentUser={communityRankingCurrentPlayer} />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={8} lg={5} xl={4} className="mb-2">
          <Card>
            <CardHeader className="text-center card-header-alt py-3 px-2">
              <h3>{t('page.ranking.global_ranking')}</h3>
            </CardHeader>
            <CardBody>
              <div className="ranking-list-wrapper">
                {globalRanking.length && (
                  <RankingList players={globalRanking} currentUser={globalRankingCurrentPlayer} />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Ranking
