import { countryMappingISO3toISO2, countryMappingISO2toISO3 } from './countriesMapping'

function mapIso3ToIso2Country(iso3Country: string): string {
  return countryMappingISO3toISO2[iso3Country.toUpperCase()] ?? iso3Country
}

function mapIso3ToIso2Countries(iso3Countries: string[]): string[] {
  return iso3Countries.map((iso3Country: string) => mapIso3ToIso2Country(iso3Country))
}

function mapIso2ToIso3Country(iso2Country: string): string {
  return countryMappingISO2toISO3[iso2Country]?.toLowerCase() ?? iso2Country
}

function mapIso2ToIso3Countries(iso2Countries: string[]): string[] {
  return iso2Countries.map((iso2Country: string) => mapIso2ToIso3Country(iso2Country))
}

export {
  mapIso3ToIso2Countries, mapIso2ToIso3Countries, mapIso2ToIso3Country, mapIso3ToIso2Country
}