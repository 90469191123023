import { useConfig } from './useConfig'
import { useIsAdmin } from './useIsAdmin'
import { useUser } from './useUser'

function useUserData() {
  const config = useConfig()
  const user = useUser()
  const isAdmin = useIsAdmin()
  const isStandardUser = !isAdmin

  const currentCommunityId = config?.data?.communityId

  return {
    user, isAdmin, isStandardUser, currentCommunityId
  }
}

export { useUserData }