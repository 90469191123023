import { produce } from 'immer'
import { GetCommunityMembersDispatchTypes, GetCommunityMembersSuccess } from 'src/store/actions/types/community/getCommunityMembers'
import { RemoveCommunityMemberDispatchTypes } from 'src/store/actions/types/community/removeCommunityMembers'
import { SetCommunity } from 'src/store/actions/types/community/setCommunity'
import { actionTypes as actions } from 'src/store/actions/types/community/actionTypes'
import { User, UserCommunity } from '../../store'

// FIXME: #91 Backend will answer if this is going to be a User (https://github.com/msahores/company-hike/issues/91)
export interface CommunitiesState {
  loading: boolean
  userCommunity: UserCommunity
  communityMembers: User[]
}

const initialState: CommunitiesState = {
  loading: false,
  userCommunity: {
    id: '1',
    name: '',
    isAdmin: false,
  },
  communityMembers: []
}
// const initialState: CommunitiesState = {
//   loading: false,
//   userCommunity: {
//     id: '1',
//     name: '',
//     isAdmin: false,
//   },
//   communityMembers:
//     [
//       {
//         id: '1',
//         avatar: '/assets/images/avatars/avatar1.jpg',
//         name: 'Analia',
//         isAdmin: false,
//         email: 'Suarez',
//         firstLogin: false,
//         language: 'es',
//         lastName: 'ana@lia.com',
//         pendingMatchInvitation: true,
//         user_data: '{"communityId": "1"}',
//         communities: [{
//           id: '1',
//           isAdmin: false,
//           name: 'USAL',
//         }]
//       },
//       {
//         id: '2',
//         avatar: '/assets/images/avatars/avatar2.jpg',
//         name: 'Maria',
//         isAdmin: false,
//         email: 'maria@hotmail.com',
//         firstLogin: true,
//         language: 'en',
//         lastName: 'Gimenez',
//         pendingMatchInvitation: false,
//         user_data: '{"communityId": "1"}',
//         communities: [{
//           id: '1',
//           isAdmin: false,
//           name: 'USAL',

//         }]
//       }
//     ]
// }

type CommunitiesReducerActionTypes = SetCommunity | GetCommunityMembersDispatchTypes | RemoveCommunityMemberDispatchTypes

function communitiesReducer(state: CommunitiesState = initialState, action: CommunitiesReducerActionTypes): CommunitiesState {
  switch (action.type) {
    case actions.SET_COMMUNITY:
      return produce(state, draft => {
        draft.userCommunity = (action as SetCommunity).payload.userCommunity
      })
    case actions.GET_COMMUNITY_MEMBERS_LOADING:
      return produce(state, draft => {
        draft.loading = true
      })
    case actions.GET_COMMUNITY_MEMBERS_FAIL:
      return produce(state, draft => {
        draft.loading = false
      })
    case actions.GET_COMMUNITY_MEMBERS_SUCCESS:
      return produce(state, draft => {
        draft.loading = false
        draft.communityMembers = (action as GetCommunityMembersSuccess).payload.communityMembers
      })
    case actions.REMOVE_COMMUNITY_MEMBERS_LOADING:
      return produce(state, draft => {
        draft.loading = true
      })
    case actions.REMOVE_COMMUNITY_MEMBERS_FAIL:
      return produce(state, draft => {
        draft.loading = false
      })
    case actions.REMOVE_COMMUNITY_MEMBERS_SUCCESS:
      return produce(state, draft => {
        // once endpoint is properly working we dont need to pass userId in the payload
        // and instead of this:
        // draft.communityMembers = state.communityMembers.filter(communityMember => communityMember.id !== (action as RemoveCommunityMemberSuccess).payload.userId)
        // we should do:
        draft.loading = false
        draft.communityMembers = (action as GetCommunityMembersSuccess).payload.communityMembers
      })
    default:
      return state
  }
}

export { communitiesReducer }