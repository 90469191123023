import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { reducer } from './reducer'
import analyticsMiddleware from '../utils/analyticsMiddleware'

// Use in case you want to save some section  of the store in Local Storage
// const store = createStore(rootReducer, persistedState, composeWithDevTools(applyMiddleware(thunk)))

// store.subscribe(
//   throttle(() => {
//     saveStateInStorage({
//       createMatch: store.getState().createMatch
//     })
//   }, 1000)
// )

const store = createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware(thunk, analyticsMiddleware) // Combine thunk and analyticsMiddleware
  )
)


export * from './actions'
export type { State } from './reducers/rootReducer'
export { store }
