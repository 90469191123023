/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { Component } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import * as actionTypes from '../../store/actions'
import { SidebarFooter, SidebarHeader, SidebarMenu } from '..'

class Sidebar extends Component {
  toggleSidebarMobile = () => {
    const { sidebarToggleMobile, setSidebarToggleMobile } = this.props

    setSidebarToggleMobile(!sidebarToggleMobile)
  }

  render() {
    const {
      sidebarStyle, sidebarShadow, sidebarFooter, sidebarToggleMobile
    } = this.props

    return (
      <>
        <div
          className={clsx('app-sidebar', sidebarStyle, {
            'app-sidebar--shadow': sidebarShadow
          })}>
          <SidebarHeader />
          <div className="app-sidebar--content">
            <SidebarMenu />
          </div>
          {sidebarFooter && <SidebarFooter />}
        </div>
        <div
          onClick={this.toggleSidebarMobile}
          className={clsx('app-sidebar-overlay', {
            'is-active': sidebarToggleMobile && window.innerWidth < 500
          })}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  sidebarShadow: state.theme.sidebarShadow,
  sidebarFooter: state.theme.sidebarFooter,
  sidebarStyle: state.theme.sidebarStyle,
  sidebarToggleMobile: state.theme.sidebarToggleMobile
})

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(actionTypes.setSidebarToggleMobile(enable))
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
