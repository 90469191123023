import React, { Component } from 'react'

export default class ExampleWrapperSeamless extends Component {
  render() {
    const { sectionHeading, children, marginBottom = null } = this.props

    return (
      <div className={`example-card-seamless mb-${marginBottom}`}>
        <h5 className="display-4 mb-1 font-weight-bold">{sectionHeading}</h5>
        {children}
      </div>
    )
  }
}
