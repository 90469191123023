import * as actionTypes from '../actions/types'

export interface ThemeStateI {
  sidebarShadow: boolean;
  sidebarFixed: boolean;
  sidebarLighter: boolean;
  sidebarFooter: boolean;
  sidebarUserbox: boolean;
  sidebarStyle: string;
  sidebarToggleMobile: boolean;
  sidebarToggle: boolean;
  headerFixed: boolean;
  headerShadow: boolean;
  headerBgTransparent: boolean;
  headerSearchHover: boolean;
  headerDrawerToggle: boolean;
  contentBackground: string;
  isIlustrationBackground: boolean;
  matchStatusImage: string;
  themeConfiguratorToggle: boolean;
  footerFixed: boolean;
  footerShadow: boolean;
  footerBgTransparent: boolean;
  pageTitleStyle: string;
  pageTitleBackground: string;
  pageTitleShadow: boolean;
  pageTitleBreadcrumb: boolean;
  pageTitleIconBox: boolean;
  pageTitleDescription: boolean;
}

const initialState = {
  // Sidebar
  sidebarShadow: false,
  sidebarFixed: true,
  sidebarLighter: false,
  sidebarFooter: true,
  sidebarUserbox: false,
  sidebarStyle: 'app-sidebar--dark',
  sidebarToggleMobile: false,
  sidebarToggle: false,

  // Header
  headerFixed: true,
  headerShadow: true,
  headerBgTransparent: true,
  headerSearchHover: false,
  headerDrawerToggle: false,

  // Main content
  contentBackground: '',
  isIlustrationBackground: true,
  matchStatusImage: '',
  themeConfiguratorToggle: false,

  // Footer
  footerFixed: false,
  footerShadow: false,
  footerBgTransparent: true,

  // Page title
  pageTitleStyle: '',
  pageTitleBackground: '',
  pageTitleShadow: false,
  pageTitleBreadcrumb: false,
  pageTitleIconBox: true,
  pageTitleDescription: true,
}

const themeReducer = (
  state: ThemeStateI = initialState,
  action: any,
): ThemeStateI => {
  switch (action.type) {
    // Sidebar
    case actionTypes.SET_SIDEBAR_SHADOW:
      return {
        ...state,
        sidebarShadow: action.sidebarShadow,
      }
    case actionTypes.SET_SIDEBAR_FIXED:
      return {
        ...state,
        sidebarFixed: action.sidebarFixed,
      }
    case actionTypes.SET_SIDEBAR_STYLE:
      return {
        ...state,
        sidebarStyle: action.sidebarStyle,
      }
    case actionTypes.SET_SIDEBAR_FOOTER:
      return {
        ...state,
        sidebarFooter: action.sidebarFooter,
      }
    case actionTypes.SET_SIDEBAR_TOGGLE_MOBILE:
      return {
        ...state,
        sidebarToggleMobile: action.sidebarToggleMobile,
      }
    case actionTypes.SET_SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarToggle: action.sidebarToggle,
      }
    case actionTypes.SET_SIDEBAR_USERBOX:
      return {
        ...state,
        sidebarUserbox: action.sidebarUserbox,
      }

    // Header
    case actionTypes.SET_HEADER_FIXED:
      return {
        ...state,
        headerFixed: action.headerFixed,
      }
    case actionTypes.SET_HEADER_SHADOW:
      return {
        ...state,
        headerShadow: action.headerShadow,
      }
    case actionTypes.SET_HEADER_BG_TRANSPARENT:
      return {
        ...state,
        headerBgTransparent: action.headerBgTransparent,
      }
    case actionTypes.SET_HEADER_SEARCH_HOVER:
      return {
        ...state,
        headerSearchHover: action.headerSearchHover,
      }
    case actionTypes.SET_HEADER_DRAWER_TOGGLE:
      return {
        ...state,
        headerDrawerToggle: action.headerDrawerToggle,
      }

    // Main content
    case actionTypes.SET_CONTENT_BACKGROUND:
      return {
        ...state,
        contentBackground: action.contentBackground,
      }
    case actionTypes.SET_SWITCH_BACKGROUND:
      return {
        ...state,
        isIlustrationBackground: action.isIlustrationBackground,
        matchStatusImage: action.matchStatusImage,
      }
    case actionTypes.SET_THEME_CONFIGURATOR_TOGGLE:
      return {
        ...state,
        themeConfiguratorToggle: action.themeConfiguratorToggle,
      }

    // Footer
    case actionTypes.SET_FOOTER_FIXED:
      return {
        ...state,
        footerFixed: action.footerFixed,
      }
    case actionTypes.SET_FOOTER_SHADOW:
      return {
        ...state,
        footerShadow: action.footerShadow,
      }
    case actionTypes.SET_FOOTER_BG_TRANSPARENT:
      return {
        ...state,
        footerBgTransparent: action.footerBgTransparent,
      }

    // Page title
    case actionTypes.SET_PAGE_TITLE_STYLE:
      return {
        ...state,
        pageTitleStyle: action.pageTitleStyle,
      }
    case actionTypes.SET_PAGE_TITLE_BACKGROUND:
      return {
        ...state,
        pageTitleBackground: action.pageTitleBackground,
      }
    case actionTypes.SET_PAGE_TITLE_SHADOW:
      return {
        ...state,
        pageTitleShadow: action.pageTitleShadow,
      }
    case actionTypes.SET_PAGE_TITLE_BREADCRUMB:
      return {
        ...state,
        pageTitleBreadcrumb: action.pageTitleBreadcrumb,
      }
    case actionTypes.SET_PAGE_TITLE_ICON_BOX:
      return {
        ...state,
        pageTitleIconBox: action.pageTitleIconBox,
      }
    case actionTypes.SET_PAGE_TITLE_DESCRIPTION:
      return {
        ...state,
        pageTitleDescription: action.pageTitleDescription,
      }
    default:
      return state
  }
}

export default themeReducer
export { themeReducer }
