import { combineReducers } from 'redux'
import { matchListReducer } from './matchList'
import { matchReducer } from './createMatch'
import { removeMatchReducer } from './removeMatch'

const matchesReducer = combineReducers({
  matchList: matchListReducer,
  createMatch: matchReducer,
  removeMatch: removeMatchReducer,
})

export { matchesReducer }