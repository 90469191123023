import React from 'react'
import { Loader } from 'src/components'
import Ranking from 'src/views/Ranking/Ranking'
// import { Player } from '../store/actions/types'
// import { Fadein } from './FadeIn'

interface UsersRankingProps {
  // ranking?: Player[]
  isLoading: boolean
}

function UsersRanking(props: UsersRankingProps) {
  const { isLoading } = props

  return (
    <>
      {isLoading && <Loader />}
      <Ranking header={false} />

      {/* <Row className="justify-content-md-center my-5">
        <Col xs={12} md={8} lg={5} xl={4} className="mb-3">
          <Card>
            <CardHeader className="text-center card-header-alt py-5 px-2">
              <h3>{t('page.ranking.community_ranking')}</h3>
            </CardHeader>
            <CardBody>
              <div className="ranking-list-wrapper">
                {communityRanking.length && (
                  <RankingList players={communityRanking} currentUser={communityRankingCurrentPlayer} />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={8} lg={5} xl={4} className="mb-3">
          <Card>
            <CardHeader className="text-center card-header-alt py-5 px-2">
              <h3>{t('page.ranking.global_ranking')}</h3>
            </CardHeader>
            <CardBody>
              <div className="ranking-list-wrapper">
                {globalRanking.length && (
                  <RankingList players={globalRanking} currentUser={globalRankingCurrentPlayer} />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>*/}
    </>
  )
}

export { UsersRanking }
