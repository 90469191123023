import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function TrashIcon() {
  return (
    <div className="d-flex align-items-center">
      <div className="badge rounded-circle badge-neutral-danger sherpa-primary d-40 btn-icon p-0 mr-1 pl-0">
        <FontAwesomeIcon icon={['fas', 'trash']} size="lg" />
      </div>
    </div>
  )
}

export { TrashIcon }
