import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMatchInfo, State } from 'src/store'

export const useMatchInfo = (matchId?: any, forceFromUI?: boolean) => {
  const matchInfo = useSelector((state: State) => state.matchInfo)
  const dispatch = useDispatch()

  useEffect(() => {
    if ((!matchInfo.data && matchId !== undefined && matchId !== 'undefined') || forceFromUI) {
      dispatch(getMatchInfo(matchId))
    }
  }, [matchInfo.data, matchId, dispatch, forceFromUI])

  return { matchInfo }
}