import { AxiosResponse } from 'axios'
import { Dispatch } from 'react'
import { client } from 'src/api'
import { camelize } from 'src/utils/camelize'
import { User } from '../types'
import {
  GetCommunityMembersDispatchTypes, getCommunityMembersActions as actions
} from '../types/community/getCommunityMembers'

function getCommunityMembers(communityId: string) {
  return async (dispatch: Dispatch<GetCommunityMembersDispatchTypes>) => {
    try {
      dispatch({
        type: actions.GET_COMMUNITY_MEMBERS_LOADING
      })
      const { data } = await client.get<any, AxiosResponse<Record<string, unknown>[]>>(`community/members/${communityId}`)
      const camelizedResponse = data.map(communityMember => camelize(communityMember)) as unknown as User[]

      dispatch({
        type: actions.GET_COMMUNITY_MEMBERS_SUCCESS,
        payload: {
          communityMembers: camelizedResponse
        }
      })
    } catch (e) {
      dispatch({
        type: actions.GET_COMMUNITY_MEMBERS_FAIL
      })
    }
  }
}

export { getCommunityMembers }