import { Dispatch } from 'redux'
import { client } from 'src/api/client'
import { State } from 'src/store/reducers/rootReducer'
import { SetMatchListDispatch, MatchListDispatchTypes, MatchList } from '../types/match/matchList'
import * as actionTypes from '../types/match'


const setMatchList = (matchList: MatchList): SetMatchListDispatch => ({
  type: actionTypes.SET_MATCH_LIST,
  payload: matchList
})


function getMatchList(communityId = null) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: Dispatch<MatchListDispatchTypes>, getState: () => State) => {
    try {
      dispatch({
        type: actionTypes.MATCH_LIST_LOADING
      })

      const res = await client.get(`match/list/${communityId}`)

      dispatch({
        type: actionTypes.MATCH_LIST_SUCCESS,
        payload:
          res.data,
      })
    } catch (e) {
      dispatch({
        type: actionTypes.MATCH_LIST_FAIL
      })
    }
  }
}

export { setMatchList, getMatchList }
