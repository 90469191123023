import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card } from 'reactstrap'

interface WinningProps {
  position: number
  matchId: any
}

const Winning: FC<WinningProps> = (props: WinningProps) => {
  const { position, matchId } = props

  return (
    <>
      <Card className="p-3 mb-3 bg-arielle-smile-light composed-bg-border-radius-md">
        {' '}
        {/* bg-grow-early*/}
        <div className="d-block text-md-left text-xl-left d-xl-flex justify-content-between align-items-center">
          <div className="text-white">
            <h5 className="display-4 font-weight-bold mb-1">
              Has Ganado la Partida! #
              {position}
            </h5>
            <p className="font-size-lg mb-2">
              Felicitaciones por lograr la cima!!! Revisa tus métricas y continúa participando!
            </p>
            {' '}
            <span className="flex-end">
              <Link to={`/endgame/${matchId}/dashboard`}>
                <Button color="sherpa-primary" className="font-weight-bold">
                  Ver Estadísticas
                </Button>
              </Link>
              <Link to="/ranking">
                <Button color="sherpa-primary" className="font-weight-bold ml-2">
                  Ver Ranking
                </Button>
              </Link>
            </span>
            <div className="d-block text-md-left text-xl-left d-xl-flex" />
          </div>
        </div>
      </Card>
      <Card
        xl="5"
        className="bg-endgame-win bg-composed bg-composed-wrapp composed-bg-border-radius-lg text-center p-4 text-white mb-3"
        style={{ backgroundColor: 'rgb(31 23 92)', minHeight: '22vh', maxHeight: '52vh' }}>
        <div style={{ height: 'inherit' }} />
      </Card>
    </>
  )
}

export default Winning
