import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setSwitchBackground } from 'src/store'
import { usePathname } from 'src/hooks/usePathname'
import { useHomeSteps } from 'src/hooks/onboarding/useHomeSteps'
import { Onboarding, Togglable } from 'src/components'
import { useIsOnboardingEnabled } from 'src/components/Onboarding/hooks/useIsOnboardingEnabled'
import { useNotifications } from 'src/hooks/useNotificationList'
import { HomeLayout } from './HomeLayout'
import { HomePanel } from './HomePanel/HomePanel'

function Home() {
  const pageName: string = usePathname()
  const isOnboardingEnabled = useIsOnboardingEnabled(pageName)
  const onboardingSteps = useHomeSteps()
  const dispatch = useDispatch()
  const { isLoading } = useNotifications()

  // TODO: move background management to layouts

  useEffect(() => {
    dispatch(setSwitchBackground(true, 'ROUND_STARTS'))
  }, [dispatch, isOnboardingEnabled, isLoading])

  return (
    <>
      <Togglable enabled={isOnboardingEnabled}>
        {!isLoading && <Onboarding steps={onboardingSteps} />}
      </Togglable>

      <HomeLayout>
        <HomePanel />
      </HomeLayout>
    </>
  )
}

export default Home
export { Home }
