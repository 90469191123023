import React, { FC, useEffect, useState } from 'react'
import { Card, CardHeader, ListGroup } from 'reactstrap'
import CountUp from 'react-countup'
import { useMatchInfo } from 'src/views/Dashboard/hooks/useMatchInfo'
import { useUser } from 'src/hooks/useUser'
import { Togglable } from 'src/components/Togglable'
import { Loader } from 'react-feather'
import { Ceo } from 'src/store/actions/types'
import { useTranslation } from 'react-i18next'
import { getUserProp, compareCeosPropByStage, ceoPosition } from '../../../utils/dataMatchServices'
import { CeoComparison, CeosData } from '../../../utils/dataMatchObj'
import CeoPositionItem from './CeoPositionItem'

interface PortfolioProps {
  isAdminView?: boolean
  idCeoViewMode?: number
  endgame?: boolean
}

const Portfolio: FC<PortfolioProps> = ({ isAdminView, idCeoViewMode, endgame }: PortfolioProps) => {
  const { matchInfo } = useMatchInfo()
  const id = useUser()?.id
  const matchData = matchInfo.data?.ceos
  const [isLoaded, setIsLoaded] = useState(false)
  const [isAdminViewOn, setIsAdminViewOn] = useState<boolean | undefined>(false)
  const [idCeoIsActive, setIdCeoIsActive] = useState<any | undefined>(undefined)
  const { t } = useTranslation()

  const [ceosData, setCeosData] = useState<Array<CeoComparison>>([])
  const [userBalance, setUserBalance] = useState(0)
  const [headline, setHeadline] = useState('')
  const [finalCeoId, setFinalCeoId] = useState(Number(id))
  const departmentColor =
    useMatchInfo(String(matchInfo.data?.matchId)).matchInfo.departmentSelectedInAdminMode || 'marketing'

  const earnings = 'earnings'

  const earningsLoaded = (
    loaded: boolean,
    data: CeoComparison[],
    ceoId: number,
    departmentColor: string
  ): JSX.Element[] => {
    const mock: Array<JSX.Element> = [<li key="mock">Loading Funds...</li>]

    if (loaded) {
      return data
        .sort((a: CeoComparison, b: CeoComparison) => (a.ceoProp > b.ceoProp ? -1 : 1))
        .map((ceo: CeoComparison, index: any) => (
          <CeoPositionItem key={index} ceoData={ceo} id={ceoId} departmentColor={departmentColor} />
        ))
    }
    return mock
  }

  useEffect(() => {
    if (matchInfo && matchData) {
      setIsAdminViewOn(isAdminView)

      const data: CeosData = { ceos: [] }

      data.ceos = matchData
      const ceoSelectedOrDefaultId = idCeoViewMode || (data.ceos && data.ceos[0].id)

      const idUserView: any = isAdminView ? ceoSelectedOrDefaultId : id

      setFinalCeoId(idUserView)

      const cname: Ceo[] = data.ceos.filter((ceo: Ceo) => ceo.id === idUserView)

      setHeadline(isAdminView ? `${cname[0].companyName}` : t('charts.ranking.ceo.header'))

      setIdCeoIsActive(isAdminView)

      const comparisons: CeoComparison[] = compareCeosPropByStage(
        { ceos: data.ceos },
        earnings,
        matchInfo.data?.lastResultsStage as number
      )
      const userPropValue = getUserProp(idUserView, earnings, { ceos: data.ceos })

      setCeosData(comparisons)
      setUserBalance(Number(userPropValue))

      setIsLoaded(true)
    }
  }, [isAdminViewOn, idCeoIsActive, matchData, matchInfo, isLoaded, idCeoViewMode, id, isAdminView, t])

  return (
    <>
      <Togglable enabled={isLoaded} fallback={<Loader />}>
        {/* </Togglable>
        <Card className="w-100 mb-5">
          <CardHeader className="d-flex align-items-center justify-content-between card-header-alt p-4">
            <div>
              <h6 className="font-weight-bold font-size-lg mb-1 text-black">{headline}</h6>
              <p className="d-flex text-black-50 font-size-md mb-0 ml-0">Earnings</p>
            </div>
            <div className="d-flex align-items-center">
              <Button
                color="link"
                className="mx-1 px-1 py-2 font-weight-bold text-primary"
                id="LastWeekTooltip">
                <span>1W</span>
              </Button>
              <Button
                color="link"
                className="mx-1 px-1 py-2 font-weight-bold text-dark opacity-5"
                id="LastMonthTooltip">
                <span>1M</span>
              </Button>
              <Button
                color="link"
                className="ml-1 px-1 py-2 font-weight-bold text-dark opacity-5"
                id="LastYearTooltip">
                <span>1Y</span>
              </Button>
              <UncontrolledTooltip target="LastWeekTooltip">View stats for last week</UncontrolledTooltip>
              <UncontrolledTooltip target="LastMonthTooltip">View stats for last month</UncontrolledTooltip>
              <UncontrolledTooltip target="LastYearTooltip">View stats for last year</UncontrolledTooltip>
            </div>
          </CardHeader>
          <div className="divider" />
          <div className="divider" />
          <h3 className="display-3 mt-5 mb-0 text-center font-weight-bold">
            <small className="opacity-6">$</small>
            <span className="pl-1">
              {userBalance !== undefined && (
                <CountUp
                  start={0}
                  end={userBalance}
                  duration={3}
                  separator=""
                  delay={1}
                  decimals={3}
                  decimal=","
                  prefix=""
                  suffix=""
                />
              )}
            </span>
          </h3>
          <small className="text-center font-weight-bold text-uppercase">
            {!idCeoIsActive && 'Your'}
            {' '}
            Total Balance
          </small>
          <div className="divider mt-5" />
          <div className="divider" />
          <div className="scroll-area-xxl">
            <ListGroup flush={true}>
              {isLoaded !== false && earningsLoaded(isLoaded, ceosData, finalCeoId, departmentColor)}
            </ListGroup>
          </div>
          <div className="card-footer p-3 text-center">*/}
        <Card className="w-100 mb-5">
          <CardHeader className="d-flex align-items-center justify-content-between card-header-alt p-4">
            <div>
              {endgame ? (
                <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                  Partida Finalizada | Posiciones
                </h6>
              ) : (
                <h6 className="font-weight-bold font-size-lg mb-1 text-black">{headline}</h6>
              )}
              <p className="text-black-50 mb-0">{t('charts.ranking.ceo.sub_title')}</p>
            </div>
            <div className="d-flex p-1 pt-4">
              <h3 className="font-weight-bold font-size-md mb-1 text-grey pt-3">
                {t('charts.ranking.ceo.position')}
                {' '}
                #
                {ceoPosition(isLoaded, ceosData, finalCeoId)}
              </h3>
            </div>
            {/* <div className="d-flex align-items-center">
              <Button
                color="link"
                className="mx-1 px-1 py-2 font-weight-bold text-primary"
                id="LastWeekTooltip">
                <span>1W</span>
              </Button>
              <Button
                color="link"
                className="mx-1 px-1 py-2 font-weight-bold text-dark opacity-5"
                id="LastMonthTooltip">
                <span>1M</span>
              </Button>
              <Button
                color="link"
                className="ml-1 px-1 py-2 font-weight-bold text-dark opacity-5"
                id="LastYearTooltip">
                <span>1Y</span>
              </Button>
              <UncontrolledTooltip target="LastWeekTooltip">View stats for last week</UncontrolledTooltip>
              <UncontrolledTooltip target="LastMonthTooltip">View stats for last month</UncontrolledTooltip>
              <UncontrolledTooltip target="LastYearTooltip">View stats for last year</UncontrolledTooltip>
              </div>*/}
          </CardHeader>
          <div className="divider" />
          <div className="divider" />
          <h3 className="display-3 mt-5 mb-0 text-center font-weight-bold">
            <small className="opacity-6">$</small>
            <span className="pl-1">
              <CountUp
                start={0}
                end={Number(userBalance)}
                duration={3}
                separator=","
                delay={1}
                decimals={0}
                decimal=","
                prefix=""
                suffix=""
              />
              <small>
                <sup>.00</sup>
              </small>
            </span>
          </h3>
          <small className="text-center font-weight-bold text-uppercase">
            {/* {!idCeoIsActive && 'Your'}
            {' '} */}
            {t('charts.ranking.ceo.balance')}
          </small>
          <div className="divider mt-5" />
          <div className="divider" />
          <div className="scroll-area-xxl">
            <ListGroup flush={true}>
              {isLoaded !== false && earningsLoaded(isLoaded, ceosData, finalCeoId, departmentColor)}
            </ListGroup>

            {/* </div><small className="text-center font-weight-bold text-uppercase">Your Total Balance</small>
          <div className="divider mt-5" />
          <div className="divider" />
          <div className="scroll-area-xxl">
            <ListGroup flush={true}>{isLoaded !== false && earningsLoaded(isLoaded, ceosData)}</ListGroup>*/}
          </div>
          {/* <div className="card-footer p-3 text-center">
          <Button size="sm" className="py-2 px-4" color="primary">
            <span className="btn-wrapper--label text-uppercase font-weight-bold">
              View all accounts
            </span>
          </Button>
              </div> */}
        </Card>
      </Togglable>
    </>
  )
}

export default Portfolio
