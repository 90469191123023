import { useState, useEffect } from 'react'
import { config } from 'src/config'
import { getFromStorage } from 'src/utils/localStorage'
import { OnboardingLocalStorage } from '../types'

const key = config.localStorage.keys.onboarding

export const useIsOnboardingEnabled = (pageName: string) => {
  const [isOnboardingEnabled, setIsOnboardingEnabled] = useState<boolean>(true)
  const localStorage: OnboardingLocalStorage | null = getFromStorage(key)

  useEffect(() => {
    if (localStorage && localStorage[pageName]?.status === 'finished') {
      setIsOnboardingEnabled(false)
    }
  }, [localStorage, pageName])

  return isOnboardingEnabled
}