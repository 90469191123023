import { Dispatch } from 'redux'
import { client } from 'src/api/client'
import {
  SetMatchInfoDispatchT, MatchInfoDispatchTypes, MatchInfo, Company, Ceo
} from './types/matchInfo'
import * as actionTypes from './types'

const setMatchInfo = (matchInfo: MatchInfo): SetMatchInfoDispatchT => ({
  type: actionTypes.SET_MATCH_INFO,
  payload: matchInfo
})

const setCompany = (companyCreated: boolean) => ({
  type: actionTypes.SET_COMPANY,
  companyCreated
})

const setCeoInAdminMode = (ceoSelectedInAdminMode: Ceo) => ({
  type: actionTypes.SET_CEO_IN_ADMIN_MODE,
  payload: ceoSelectedInAdminMode
})

const setDepartmentInAdminMode = (department: string) => ({
  type: actionTypes.SET_DEPARTMENT_IN_ADMIN_MODE,
  payload: department
})

const setFirstCeoAsDefaultInAdminMode = (set: boolean) => ({
  type: actionTypes.SET_FIRST_CEO_AS_DEFAULT_IN_ADMIN_MODE,
  payload: set
})

const addCompany = (companyDetails: Company) => async (dispatch: Dispatch<MatchInfoDispatchTypes>) => {
  try {
    dispatch({
      type: actionTypes.ADD_COMPANY_LOADING
    })

    await client.post('/game/add_company', {
      game_id: companyDetails.matchId,
      company_name: companyDetails.companyName,
      avatar: companyDetails.logo
    })

    dispatch({
      type: actionTypes.ADD_COMPANY_SUCCESS,
      payload: companyDetails
    })
  } catch (error) {
    dispatch({
      type: actionTypes.ADD_COMPANY_FAIL
    })
  }
}

const setFormat = (matchResponse: any, ceosResponse: any) => {
  const ceos: Ceo[] = ceosResponse?.map((ceo: any) => ({
    id: ceo.id,
    name: ceo.name,
    lastName: ceo.lastname,
    companyName: ceo.pivot.company_name,
    avatar: ceo.pivot.avatar,
    isFounded: ceo.pivot.is_funded,
    bankrupt: ceo.pivot.bankrupt,
    dismissed: ceo.pivot.dismissed,
    pivot: ceo.pivot,
    ceoParameters: ceo.pivot.ceo_parameters,
  }))

  const match = {
    matchId: matchResponse.id,
    name: matchResponse.name,
    type: matchResponse.type,
    industry: matchResponse.game_parameters.industry,
    gameParameters: matchResponse.game_parameters,
    playerPosition: matchResponse.playerPosition,
    currentStage: matchResponse.current_stage,
    lastResultsStage: matchResponse.results ? Object.keys(matchResponse.results).length - 1 : -1,
    isAdmin: matchResponse.is_admin,
    createdAt: matchResponse.created_at,
    playerHistory: matchResponse.player_history,
    results: matchResponse.results,
    bankrupt: matchResponse.bankrupt,
    ceos,
    stages: matchResponse.stages,
    statusId: matchResponse.status_id,
  }

  return match
}

const getMatchInfo = (matchId: string) => async (dispatch: Dispatch<MatchInfoDispatchTypes>) => {
  try {
    dispatch({
      type: actionTypes.MATCH_INFO_LOADING
    })

    const { data } = await client.get(`game/info/${matchId}`)
    const formated: MatchInfo = setFormat(data.match, data.ceos)

    dispatch({
      type: actionTypes.MATCH_INFO_SUCCESS,
      payload: formated
    })
  } catch (e) {
    dispatch({
      type: actionTypes.MATCH_INFO_FAIL
    })
  }
}

export {
  getMatchInfo, setCompany, addCompany, setMatchInfo, setCeoInAdminMode, setDepartmentInAdminMode, setFirstCeoAsDefaultInAdminMode
}
