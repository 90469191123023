import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import { getFromStorage } from 'src/utils/localStorage'
import { config } from 'src/config'
import { isLoggedIn, SessionData } from 'src/utils/session'
import { isLoginRequest } from 'src/api'
import { isRegisterRequest } from 'src/api/calls/register'

const key = config.localStorage.keys.session

function createClient() {
  const baseURL = config.api.url

  const client = axios.create({
    baseURL
  })

  return {
    client
  }
}
async function onRequest(req: AxiosRequestConfig): Promise<AxiosRequestConfig > {
  const token = getFromStorage<SessionData>(key)?.token
  const bearerToken = `Bearer ${token}`
  const logged = isLoggedIn()
  const isLoginReq = isLoginRequest(req.url)
  const isRegisterReq = isRegisterRequest(req.url)
  const isSafeRequest = logged || isLoginReq || isRegisterReq

  if (!isSafeRequest) {
    throw new axios.Cancel('Prohibited request')
  }

  const headers = {
    Authorization: bearerToken,
    Accept: 'application/json',
    ...req.headers
  }

  return {
    ...req,
    headers
  }
}

function onFullfilled<T>(response: AxiosResponse<T>): AxiosResponse<T> | Promise<AxiosResponse<T>> {
  return response
}

function onError<T>(error: AxiosError<T>): AxiosError<T> | Promise<AxiosError<T>> {
  return Promise.reject(error)
}

const { client } = createClient()

client.interceptors.request.use(onRequest, onError)
client.interceptors.response.use(onFullfilled, onError)

export { client }
