export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING'
export const NOTIFICATIONS_FAIL = 'NOTIFICATIONS_FAIL'
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS'


export interface Invitation {
  match_invitation_id: string
  community_name: string
  match_id: number
  match_name: string
  action: string
}

export interface Notification {
  type: string
  data: Invitation
}

export type NotificationList = Notification[]

export interface SetNotificationList {
  type: typeof SET_NOTIFICATIONS
  payload: Notification[]
}

export interface NotificationListLoading {
  type: typeof NOTIFICATIONS_LOADING
}

export interface NotificationListFail {
  type: typeof NOTIFICATIONS_FAIL
}

export interface NotificationListSuccess {
  type: typeof NOTIFICATIONS_SUCCESS
  payload: NotificationList
}

export type NotificationsDispatchTypes = SetNotificationList | NotificationListLoading | NotificationListFail | NotificationListSuccess
export type SetNotificationListDispatch = SetNotificationList | NotificationList