import { AxiosResponse } from 'axios'
import { client } from 'src/api'
import { config } from 'src/config'
// TODO: export from global space
import { Language } from 'src/views/Register'
import { setInStorage } from 'src/utils/localStorage'

interface RegisterParams {
  name: string
  lastName: string
  email: string
  password: string
  passwordConfirmation: string
  language: Language
  // communityId: string
  // invitationId: string
  communityInvitation: string
}

const registerUrl = 'auth/register'
const isRegisterRequest = (url: string | undefined): Boolean => url === registerUrl
const key = config.localStorage.keys.session

/**
   * Create a new user and returns a token
   * @param credentials RegisterParams
   * @returns Token
   * @author Company Hike
   */

async function register(credentials: RegisterParams): Promise<any> {
  try {
    const userCredentials: AxiosResponse = await client.post(registerUrl, {
      name: credentials.name,
      lastname: credentials.lastName,
      email: credentials.email,
      password: credentials.password,
      password_confirmation: credentials.passwordConfirmation,
      language: credentials.language,
      community_invitation: credentials.communityInvitation
    })
    const { access_token, expires_in } = userCredentials.data

    setInStorage(key, {
      token: access_token,
      expirationDate: Date.now() + expires_in * 1000
    })
    return userCredentials.data
  } catch (error) {
    throw new Error(`Error on register ${error}`)
  }
}


export { register, isRegisterRequest }
