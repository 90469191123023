import React from 'react'

const RankingListHeader = () => (
  <div className="d-flex flex-row justify-content-between align-items-center font-weight-bold">
    <div className="d-flex w-75">
      <div className="w-25 text-left">#</div>
      <div className="w-75">Players</div>
    </div>
    <div className="w-25 text-right">Points</div>
  </div>
)

export default RankingListHeader
