import {
  CeoDecisionsDispatchTypes, CeoDecisionsSchema, ApprovedDecisions, CeoDecisionsTypes, CeoDecisionsData
} from '../../actions'
import * as actionTypes from '../../actions/types'
import { updateDecisions } from './utils'

interface CeoDecisionsState {
  loading: boolean
  data: {
    matchId: string
    decisions: CeoDecisionsTypes
    approved: ApprovedDecisions
  }
  schema: CeoDecisionsSchema
  funds: number
}

const initialState: CeoDecisionsState = {
  loading: false,
  schema: {} as CeoDecisionsSchema,
  data: {} as CeoDecisionsData,
  funds: 100
}

const ceoDecisionsReducer = (state: CeoDecisionsState = initialState, action: CeoDecisionsDispatchTypes) => {
  switch (action.type) {
    case actionTypes.GET_CEO_DECISIONS_SCHEMA_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.GET_CEO_DECISIONS_SCHEMA_FAIL:
      return {
        ...state,
        loading: false
      }
    case actionTypes.GET_CEO_DECISIONS_SCHEMA_SUCCESS:
      return {
        ...state,
        loading: false,
        schema: action.payload
      }
    case actionTypes.SET_INITIAL_CEO_DECISIONS:
      return {
        ...state,
        data: action.payload
      }
    case actionTypes.UPDATE_CEO_DECISIONS:
      return updateDecisions(state, action.payload)
    case actionTypes.APPROVE_DEPARTMENT_DECISIONS:
      return {
        ...state,
        data: {
          ...state.data,
          approved: {
            ...state.data.approved,
            [action.payload]: !state.data.approved[action.payload]
          }
        }
      }
    case actionTypes.POST_CEO_DECISIONS_LOADING:
      return {
        ...state,
        loading: true
      }
    case actionTypes.UPDATE_AVAILABLE_FUNDS:
      return {
        ...state,
        funds: action.payload
      }
    default:
      return state
  }
}

export default ceoDecisionsReducer
export { ceoDecisionsReducer }
export type { CeoDecisionsState }