import React, { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Togglable } from 'src/components'
import { useIsAdmin } from 'src/hooks/useIsAdmin'
import { useUserData } from 'src/hooks/useUserData'
import { SubHeader } from 'src/layoutComponents'
import { AdminCtaButtons } from 'src/layoutComponents/AdminCtaButtons'

interface HomeSubHeaderProps {
  children: ReactNode
}

function HomeLayout(props: HomeSubHeaderProps) {
  const { children } = props
  const { t } = useTranslation()
  const { user, isStandardUser } = useUserData()
  const isAdmin = useIsAdmin()
  const renderTitleDescription = useMemo(() => {
    if (isStandardUser) return t('page.home.description.part_1')
    return ''
  }, [isStandardUser, t])

  return (
    <>
      <Togglable enabled={Boolean(user)}>
        <SubHeader
          title={t('page.home.title', { user: user?.name })}
          description={renderTitleDescription}
          extraContent={isAdmin && <AdminCtaButtons />}
        />
      </Togglable>
      <>{children}</>
    </>
  )
}

export { HomeLayout }
