/* eslint-disable no-negated-condition */
import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card } from 'reactstrap'
import { useMatchInfo } from 'src/views/Dashboard/hooks/useMatchInfo'

interface BannerInfoProps {
  endRound?: boolean
  title?: string
  subtitle?: string
}

const BannerInfo: FC<BannerInfoProps> = (props: BannerInfoProps) => {
  const { endRound, title, subtitle } = props
  const ceos = useMatchInfo().matchInfo?.data?.ceos
  const gameParams = useMatchInfo().matchInfo?.data?.gameParameters
  const currentStage = useMatchInfo().matchInfo.data?.currentStage
  const [fallbackMessage, setFallbackMessage] = useState(null)
  const [notFoundedCeos, setNotFoundedCeos] = useState([
    {
      name: '',
      lastname: '',
      email: ''
    }
  ])
  const [noDecisionsFromCeos, setNoDecisionsFromCeos] = useState([
    {
      name: '',
      lastname: ''
    }
  ])

  const checkDecisionMade = (ceoParams: any, stage: string) => ceoParams && (Object.prototype.hasOwnProperty.call(ceoParams, stage) as any)

  useEffect(() => {
    const getNotFundedCeos = (data: any[]) => {
      const result = {
        notFundedCeos: [
          {
            name: '',
            lastname: '',
            email: ''
          }
        ],
        fallbackMessage: ''
      }

      result.notFundedCeos = data
        .filter(ceo => ceo.isFounded === 0)
        .map(ceo => ({
          name: ceo.name,
          lastname: ceo.lastName,
          email: ''
        }))

      return { ceosStatus: result.notFundedCeos, fallBackMsg: gameParams && gameParams?.players }
    }

    const returnPendingCeos = (ceoList: any, currentRound: any) => {
      const notDecisionsFromCeos: Array<any> = []

      ceoList.forEach((ceo: { ceoParameters: any; name: string; lastName: string }) => {
        if (!checkDecisionMade(ceo?.ceoParameters, `stage_${currentRound}`)) {
          notDecisionsFromCeos.push({
            name: ceo.name,
            lastname: ceo.lastName
          })
        }
      })
      return notDecisionsFromCeos
    }

    setFallbackMessage(getNotFundedCeos(ceos as any).fallBackMsg)
    setNotFoundedCeos(getNotFundedCeos(ceos as any).ceosStatus)
    setNoDecisionsFromCeos(returnPendingCeos(ceos, currentStage))
  }, [ceos, currentStage, gameParams])

  const checkRecentlyAccepted = (fallback: any, ceosPending: any[]) => {
    const ceoTotal =
      Number(fallback) - ceosPending.length + ceosPending.filter(ceo => ceo.isFounded === 0).length

    return `${ceoTotal}/${fallback}`
  }

  return ceos && fallbackMessage !== '' && notFoundedCeos ? (
    <>
      <Card className={`p-4 mb-4 ${endRound ? 'bg-plum-plate-inverted' : 'bg-grow-early'}`}>
        <div className="d-block text-md-left text-xl-left d-xl-flex justify-content-between align-items-center">
          <div className="text-white">
            <h5 className="display-4 font-weight-bold mb-2">
              {title || 'Todo listo! La partida comenzará pronto'}
            </h5>
            <p className="font-size-lg opacity-7 mb-0">
              {subtitle ||
                'Debemos aguardar que todos los CEOS funden sus respectivas empresas! Recibirás la notificación en tu casilla de email'}
            </p>

            <div className="d-flex font-size-md opacity-7 mb-3">
              {!endRound ? (
                <div>
                  <div className="d-flex font-size-lg font-weight-bold opacity-8 mb-0">
                    {`CEOs Pendientes ${ceos.length > 0 && checkRecentlyAccepted(fallbackMessage, ceos)}`}
                    {ceos.length > 0 && ': '}
                    &nbsp;
                    {notFoundedCeos.map((ceo: any, idx: number) => (
                      <span className="mt-1 font-size-md font-weight-bold" key={idx}>
                        {ceo.name}
                        {' '}
                        {ceo.lastname}
                        {idx < notFoundedCeos.length - 1 ? ',\u00A0' : ''}
                      </span>
                    ))}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="d-flex font-size-lg font-weight-bold opacity-8 mb-0">
                    Esperando por CEOs: &nbsp;
                    {noDecisionsFromCeos.map((ceo: any, idx: number) => (
                      <span className="mt-1 font-size-md font-weight-bold" key={idx}>
                        {idx > 0 && ', '}
                        {' '}
                        {ceo.name}
                        {' '}
                        {ceo.lastname}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="d-block text-md-left text-xl-left d-xl-flex">
              <Link to="/home">
                <Button color={endRound ? 'primary' : 'success'} className="font-weight-bold">
                  Inicio
                </Button>
              </Link>
              <Link to="/help" className="ml-2">
                <Button color={endRound ? 'primary' : 'success'} className="font-weight-bold">
                  Ver Guía de Jugabilidad
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Card>
    </>
  ) : null
}

export default BannerInfo
