import { AxiosResponse } from 'axios'
import { Dispatch } from 'react'
import { client } from 'src/api'
import * as actionTypes from './../types'
import { UserConfigDispatchTypes } from './../types'

function getUserConfig() {
  // eslint-disable-next-line consistent-return
  return async (dispatch: Dispatch<UserConfigDispatchTypes>) => {
    try {
      dispatch({
        type: actionTypes.GET_USER_CONFIG_LOADING
      })
      const { data }: any = await client.get<any, AxiosResponse<Record<string, unknown>[]>>('user/get_user_data')

      const isCorrectFormat = data.toString().indexOf('\\') === -1

      const dataObject = isCorrectFormat ? data : JSON.parse(data)

      return dispatch({
        type: actionTypes.GET_USER_CONFIG_SUCCESS,
        payload: { data: dataObject },
      })
    } catch (e) {
      dispatch({
        type: actionTypes.GET_USER_CONFIG_FAIL
      })
    }
  }
}

export { getUserConfig }