import { toast, Flip } from 'react-toastify'
import { RankingDispatchTypes, Ranking } from '../actions/types'
import * as actionTypes from '../actions/types'

interface RankingState {
  loadingRankingGlobal: boolean
  loadingRankingCommunity: boolean
  rankingGlobal?: Ranking
  rankingCommunity?: Ranking
}

const initialState: RankingState = {
  loadingRankingGlobal: false,
  loadingRankingCommunity: false,
  rankingGlobal: undefined,
  rankingCommunity: undefined
}

const rankingReducer = (
  state: RankingState = initialState,
  action: RankingDispatchTypes
): RankingState => {
  switch (action.type) {
    case actionTypes.RANKING_GLOBAL_FAIL:
      toast.error('There was a problem loading global ranking, please wait.', {
        containerId: 'A',
        transition: Flip,
      })
      return {
        ...state,
        loadingRankingGlobal: false
      }
    case actionTypes.RANKING_GLOBAL_LOADING:
      return {
        ...state,
        loadingRankingGlobal: true,
      }
    case actionTypes.RANKING_GLOBAL_SUCCESS:
      return {
        ...state,
        loadingRankingGlobal: false,
        rankingGlobal: action.payload
      }
    case actionTypes.RANKING_COMMUNITY_FAIL:
      toast.error('There was a problem loading the community ranking, please wait.', {
        containerId: 'A',
        transition: Flip,
      })
      return {
        ...state,
        loadingRankingCommunity: false
      }
    case actionTypes.RANKING_COMMUNITY_LOADING:
      return {
        ...state,
        loadingRankingCommunity: true,
      }
    case actionTypes.RANKING_COMMUNITY_SUCCESS:
      return {
        ...state,
        loadingRankingCommunity: false,
        rankingCommunity: action.payload
      }
    default:
      return state
  }
}

export { rankingReducer }