import React from 'react'
import { Player } from 'src/store'

interface RankingPlayerItemProps {
  player: Player
}

const RankingPlayerItem = ({ player }: RankingPlayerItemProps) => (
  <div
    className={`ranking-player-item d-flex flex-row justify-content-between align-items-center ${player.ranking <=
      3 && 'display-5'}`}>
    <div className="d-flex w-75">
      <div className="w-25 text-left">
        {player.ranking}
        .
      </div>
      <div className="d-flex w-75 align-items-center">
        <span className="ranking-player-item-name w-75">{`${player.name} ${player.last_name}`}</span>
      </div>
    </div>
    <div className="w-25 text-right font-weight-bold">{player.points.toFixed(0)}</div>
  </div>
)

export default RankingPlayerItem
