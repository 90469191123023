import React, { FC } from 'react'
import CustomMenuListItem from './CustomMenuListItem'

interface CustomMenuProps {
  menuContent: CustomMenuItem[]
}

export type CustomMenuItem = {
  label: string
  icon: string
  to: string
  index?: number
}

const CustomMenu: FC<CustomMenuProps> = ({ menuContent }) => (
  <ul>
    {menuContent.map((item, index) => (
      <CustomMenuListItem key={index} to={item.to} label={item.label} icon={item.icon} />
    ))}
  </ul>
)

export default CustomMenu
