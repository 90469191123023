/* eslint-disable no-unreachable */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'

import { ListGroupItem } from 'reactstrap'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ArrowDownRight, ArrowUpRight } from 'react-feather'
// import CountUp from 'react-countup'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CountUp from 'react-countup'
import { CeoComparison } from 'src/utils/dataMatchObj'
import { useMatchInfo } from 'src/views/Dashboard/hooks/useMatchInfo'
import { useMatches } from 'src/hooks/useMatchList'
import { images } from 'src/utils/companyLogos'
import { toCurrency } from 'src/utils/toCurrency'

interface CeoPositionItemProps {
  ceoData: CeoComparison
  id: number
  departmentColor: string
}
// { FIX THIS}
const CeoPositionItem = (props: CeoPositionItemProps) => {
  const {
    ceoName, ceoAvatar, ceoProp, ceoPropPreviousRound, companyName, id: ceoId
  } = props.ceoData
  const [industry, setIndustry] = useState()
  const { matches } = useMatches()
  const { id, departmentColor } = props
  const { matchInfo } = useMatchInfo()
  const matchId = String(matchInfo.data?.matchId)

  useEffect(() => {
    const getMatchProp = (matchId: any): any => {
      const matchType = matches?.filter(match => Number(matchId) === Number(match.id))

      const ind: any = matchType && matchType[0].industry

      return ind
    }

    setIndustry(getMatchProp(matchId))
  }, [industry, matchId, matches])

  return (
    <ListGroupItem
      className={`${
        ceoId === id ? `border-3 mt-2 h-auto border-${departmentColor}` : ''
      } d-flex justify-content-between align-items-center py-3`}>
      <div className="d-flex align-items-center mr-4">
        <div className="d-40 text-white d-flex align-items-center justify-content-center rounded-pill mr-3 bg-white">
          {/* <FontAwesomeIcon icon={['fab', 'bitcoin']} className="font-size-lg" />*/}
          <img
            src={images[`../assets/images/company-logos/${industry}/${ceoAvatar}.png`]}
            alt=""
            width="60"
            height="50"
          />
        </div>
        <div>
          <div className="font-weight-bold">{companyName || 'Company not founded yet'}</div>
          <span className="text-warning d-block">{ceoName}</span>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="text-right mr-3">
          <div className="font-weight-bold font-size-lg">
            $
            <CountUp
              start={0}
              end={ceoProp as number}
              duration={1}
              separator=","
              delay={Number(ceoAvatar) * 0.2}
              decimals={0}
              decimal=","
              prefix=""
              suffix=""
            />
          </div>
          {ceoPropPreviousRound && ceoPropPreviousRound !== '0' ? (
            <div className="font-weight-bold text-black opacity-4">
              <span className="font-weight-normal text-black opacity-9">turno previo: </span>
              {ceoPropPreviousRound !== '0' && ceoPropPreviousRound
                ? toCurrency(ceoPropPreviousRound as number)
                : ''}
            </div>
          ) : (
            <div>Contexto inicial</div>
          )}
        </div>
        {ceoPropPreviousRound && ceoPropPreviousRound !== '0' ? (
          <div
            className={
              ceoProp > ceoPropPreviousRound ? 'font-size-lg text-success' : '`font-size-lg text-danger'
            }>
            {ceoProp > ceoPropPreviousRound ? <ArrowUpRight /> : <ArrowDownRight />}
          </div>
        ) : null}
      </div>
    </ListGroupItem>
  )
}

export default CeoPositionItem
