import React, { useCallback, useMemo } from 'react'
import Nouislider, { Formatter, NouisliderProps } from 'nouislider-react'
import { toCurrency } from 'src/utils/toCurrency'

interface InputSliderProps extends Omit<NouisliderProps, 'pips'> {
  hasPercentagePrefix?: boolean
  hasDollarPrefix?: boolean
  hasPips?: boolean
  pips?: Object
  onChange?: (data: number[]) => void
  color?: string
}

/**
 * A wrapper over nouislider-react
 * @param {bool} [hasPercentagePrefix] If setted to true, it will add percentage format to main value and to pips
 * @param {bool} [hasPips] if set to true will show default values, without needing to pass pips
 * @param {Object} [pips] piss passed through prop will overwrite default values
 * @returns {Nouislider} Returns an instance of Nouislider
 */

function InputSlider(props: InputSliderProps) {
  const {
    hasPercentagePrefix = false, pips, hasPips, onChange, color, hasDollarPrefix, ...rest
  } = props

  const setFormat = useCallback(
    (val: number) => {
      if (hasDollarPrefix) {
        return toCurrency(val)
      }
      return `${Math.floor(val)}${hasPercentagePrefix ? '%' : ''}`
    },
    [hasDollarPrefix, hasPercentagePrefix]
  )

  const format = useMemo<Formatter>(
    () => ({
      from: val => parseInt(val, 10),
      to: val => setFormat(val)
      // to: val => `${Math.floor(val)}${hasPercentagePrefix ? '%' : ''}`
    }),
    [setFormat]
  )
  const withPips: boolean = useMemo<boolean>(() => Boolean(pips) || Boolean(hasPips), [pips, hasPips])

  return (
    <Nouislider
      connect={true}
      step={1}
      tooltips={true}
      // TODO A wrapper will be more desirable than pre-fixed margins
      className={`mt-5 mb-2 mx-4 ${color && `slider-${color}`}`}
      animate={true}
      format={format}
      onChange={(data: string[]) => {
        const dataWithoutPercentage = data.map(el => el.split('%')[0])
        const numericData = dataWithoutPercentage.map(data => Number(data))

        onChange?.(numericData)
      }}
      pips={
        withPips
          ? {
            mode: 'count',
            values: 2,
            density: 100,
            stepped: false,
            format,
            ...pips
          }
          : undefined
      }
      {...rest}
    />
  )
}

export { InputSlider }
