export const GET_CEO_DECISIONS_SCHEMA_LOADING = 'GET_CEO_DECISIONS_SCHEMA_LOADING'
export const GET_CEO_DECISIONS_SCHEMA_FAIL = 'GET_CEO_DECISIONS_SCHEMA_FAIL'
export const GET_CEO_DECISIONS_SCHEMA_SUCCESS = 'GET_CEO_DECISIONS_SCHEMA_SUCCESS'
export const SET_INITIAL_CEO_DECISIONS = 'SET_INITIAL_CEO_DECISIONS'
export const UPDATE_CEO_DECISIONS = 'UPDATE_CEO_DECISIONS'
export const POST_CEO_DECISIONS_LOADING = 'POST_CEO_DECISIONS_LOADING'
export const POST_CEO_DECISIONS_FAIL = 'POST_CEO_DECISIONS_FAIL'
export const POST_CEO_DECISIONS_SUCCESS = 'POST_CEO_DECISIONS_SUCCESS'
export const APPROVE_DEPARTMENT_DECISIONS = 'APPROVE_DEPARTMENT_DECISIONS'
export const UPDATE_AVAILABLE_FUNDS = 'UPDATE_AVAILABLE_FUNDS'

export interface CeoDecisionsTypeRange {
  type: string
  min: number
  max: number
}

export interface CeoDecisionsTypeSelect {
  type: string
  options: string[]
}

export interface CeoDecisionsTypeValue {
  type: string
  value: number
}

export type CeoDecisionsSchema = {
  capital_inv: CeoDecisionsTypeRange
  design: CeoDecisionsTypeRange
  final_cash: CeoDecisionsTypeValue
  financial_debt: CeoDecisionsTypeValue
  ibk: CeoDecisionsTypeRange
  line_credit: CeoDecisionsTypeValue
  mkt: CeoDecisionsTypeRange
  price: CeoDecisionsTypeRange
  production: CeoDecisionsTypeRange
  quality_control: CeoDecisionsTypeSelect
  recycle: CeoDecisionsTypeSelect
  safety: CeoDecisionsTypeSelect
  survey: CeoDecisionsTypeRange
  total_funds: CeoDecisionsTypeValue
}

export type CeoDecisionsPostSchema = {
  design: number
  new_debt: number
  ibk: number
  mkt: number
  price: number
  production: number
  quality_control: string
  recycle: string
  safety: string
  survey: number
}

export interface CeoDecisionsData {
  matchId: string
  decisions: CeoDecisionsTypes
  approved: ApprovedDecisions
}

export type CeoDecisionsTypes = {
  lending: number
  design: number
  ibk: number
  mkt: number
  price: number
  production: number
  qualityControl: string
  recycle: string
  safety: string
  survey: number // CHECK GRAMMAR IN BACKEND suvery
}

export interface CeoBalance {
  finalCash: number
  financialDebt: number
}

export interface ApprovedDecisions {
  marketing: boolean
  production: boolean
  finance: boolean
}

export type DepartmentName = keyof ApprovedDecisions

export interface Decision {
  value: number | string
  area: keyof CeoDecisionsTypes
}

export interface GetCeoDecisionsSchemaLoading {
  type: typeof GET_CEO_DECISIONS_SCHEMA_LOADING
}

export interface GetCeoDecisionsSchemaFail {
  type: typeof GET_CEO_DECISIONS_SCHEMA_FAIL
}

export interface GetCeoDecisionsSchemaSuccess {
  type: typeof GET_CEO_DECISIONS_SCHEMA_SUCCESS
  payload: CeoDecisionsSchema
}

export interface SetInitialCeoDecisions {
  type: typeof SET_INITIAL_CEO_DECISIONS
  payload: CeoDecisionsData
}

export interface UpdateCeoDecisions {
  type: typeof UPDATE_CEO_DECISIONS
  payload: Decision
}

export interface PostCeoDecisionsLoading {
  type: typeof POST_CEO_DECISIONS_LOADING
}

export interface PostCeoDecisionsFail {
  type: typeof POST_CEO_DECISIONS_FAIL
}

export interface PostCeoDecisionsSuccess {
  type: typeof POST_CEO_DECISIONS_SUCCESS
  payload: string
}

export interface AddCeoDecisionsData {
  type: typeof APPROVE_DEPARTMENT_DECISIONS
  payload: keyof ApprovedDecisions
}

export interface UpdateAvailableFuncs {
  type: typeof UPDATE_AVAILABLE_FUNDS
  payload: number
}

export type CeoDecisionsDispatchTypes =
  | GetCeoDecisionsSchemaLoading
  | GetCeoDecisionsSchemaFail
  | GetCeoDecisionsSchemaSuccess
  | SetInitialCeoDecisions
  | UpdateCeoDecisions
  | PostCeoDecisionsLoading
  | PostCeoDecisionsFail
  | PostCeoDecisionsSuccess
  | AddCeoDecisionsData
  | UpdateAvailableFuncs