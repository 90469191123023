import React from 'react'
import { motion } from 'framer-motion'
import classes from './Fadein.module.scss'

interface FadeInProps {
  delay: number
  children: React.ReactNode
  hover?: boolean
  mobile?: boolean
}

function Fadein(props: FadeInProps) {
  const {
    delay, children, hover, mobile
  } = props
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  }

  return (
    <motion.li
      variants={variants}
      transition={{ duration: 1, delay }}
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0 }}
      className={`${!mobile && 'd-flex'} ${
        hover ? 'hover-scale-rounded scale-rounded' : ''
      } text-left p-3 mb-2 ${classes.fixMotion}`}>
      {children}
    </motion.li>
  )
}

export { Fadein }
