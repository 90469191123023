import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Togglable, UsersList } from 'src/components'
import { useMatches } from 'src/hooks/useMatchList'
import {
  addUserToMatch,
  getCommunityMembers,
  InvitationSent,
  Match,
  removeUserFromMatch,
  User
} from 'src/store'
import { useCommunityMembers, useUserCommunityId } from 'src/features/communities'
import { useUserData } from 'src/hooks/useUserData'
import { useInvitations } from 'src/hooks/useInvitations'
import { filterCeosOnly } from 'src/utils/calculations'
import useWindowWidth from 'src/hooks/useWindowWidth'
import { Card, AdminMatchesList, PlayerMatchesList } from './components'

function HomePanel() {
  const { t } = useTranslation()
  const { isLoading, matches } = useMatches()
  const { isAdmin } = useUserData()
  const [matchSelected, setMatchSelected] = useState<Match | null>(null)
  const [screenSizeMobile, setScreenSizeMobile] = useState(false)
  const {
    isInvited, invitations, isLoading: loadingItem, setLoadingUser
  } = useInvitations(matchSelected?.id)
  const { communityMembers } = useCommunityMembers()
  const showMatchesList = !matchSelected
  const dispatch = useDispatch()
  const communityId = useUserCommunityId()
  const isMobile = useWindowWidth() <= 750

  const onAddUserToMatch = (user: User) => {
    setLoadingUser(user.id)
    dispatch(addUserToMatch({ user, matchId: matchSelected?.id!, communityId }))
  }

  const onRemoveUserFromMatch = (_userId: string) => {
    const invitationToRemove = invitations.find(
      invitation => invitation.matchId === matchSelected?.id.toString() && invitation.userId === _userId.toString()
    )

    if (invitationToRemove) {
      setLoadingUser(_userId)
      dispatch(removeUserFromMatch(invitationToRemove))
    }
  }

  const showUserAdd = () => (user: User) => !isInvited(user.id)

  const disableListItems = (invitedPlayers: InvitationSent[], match: Match | null) => (user: User) => {
    if (match && invitedPlayers.length >= match.players) {
      return !isInvited(user.id)
    }
    return false
  }

  useEffect(() => {
    if (isAdmin) {
      dispatch(getCommunityMembers(communityId))
    }
    setScreenSizeMobile(isMobile)
    return setMatchSelected(null)
  }, [dispatch, communityId, isAdmin, isMobile])

  const renderUi = (): JSX.Element => {
    if (matches !== undefined) {
      return (
        <Card isMobile={screenSizeMobile}>
          <Togglable
            enabled={Boolean(showMatchesList)}
            fallback={
              <UsersList
                headerTitle={t('page.home.users_list.user_item.add_players', {
                  match_name: matchSelected?.name,
                  players: matchSelected?.players,
                  invited: invitations.length
                })}
                onHeaderClick={() => setMatchSelected(null)}
                users={filterCeosOnly(communityMembers)}
                ctaText={t('page.home.users_list.user_item.button_back')}
                onUserAdd={user => onAddUserToMatch(user)}
                onUserRemove={userId => onRemoveUserFromMatch(userId)}
                showUserAdd={showUserAdd()}
                disabledListItem={disableListItems(invitations, matchSelected)}
                loadingItem={loadingItem()}
              />
            }>
            <Togglable
              enabled={isAdmin}
              fallback={
                <PlayerMatchesList matches={matches} isLoading={isLoading} onMatchItemClick={() => null} />
              }>
              <AdminMatchesList
                matches={matches}
                isLoading={isLoading}
                onMatchItemClick={match => setMatchSelected(match)}
              />
            </Togglable>
          </Togglable>
        </Card>
      )
    }
    return <div />
  }

  useEffect(() => {
    renderUi()
  })

  return <>{renderUi()}</>
}

export { HomePanel }
