import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Badge,
  ListGroup,
  ListGroupItem,
  // UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  // Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap'
import { logout } from 'src/api'
import { UserAvatar } from 'src/components/Avatar/UserAvatar'
import { User } from 'src/store'
import { capitalizeString } from 'src/utils/calculations'

type ComponentProps = {
  user: User
}

function HeaderUserbox(props: ComponentProps) {
  const { user } = props
  const { t } = useTranslation()

  return (
    props && (
      <UncontrolledDropdown className="user-box position-relative ml-2 homeTourStep2">
        <DropdownToggle color="link" className="p-0 text-left d-flex align-items-center">
          <div className="d-block p-0 avatar-icon-wrapper">
            <Badge color="success" className="badge-circle p-top-a">
              {t('layout.header_user_box.status_online')}
            </Badge>
            <UserAvatar user={user} />
            {/* <div className="avatar-icon rounded">
              <img src={avatar7} alt="..." />
            </div> */}
          </div>
          <div className="d-none d-xl-block pl-2">
            <div className="font-weight-bold">
              {user.name}
              {' '}
              {user.lastName}
            </div>
            <span className="text-black-50">
              {user?.stats && user?.stats?.league && capitalizeString(user.stats.league)}
            </span>
          </div>
          <span className="pl-1 pl-xl-3">
            <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
          </span>
        </DropdownToggle>
        <DropdownMenu right={true} className="dropdown-menu-lg overflow-hidden p-0">
          <ListGroup flush={true} className="text-left bg-transparent">
            <ListGroupItem className="rounded-top">
              <Nav pills={true} className="nav-neutral-primary flex-column">
                <NavItem className="nav-header d-flex text-primary pt-1 pb-2 font-weight-bold align-items-center">
                  <div>{t('layout.header_user_box.profile_options')}</div>
                  {/* <div className="ml-auto font-size-xs">
                    <a href="#/" onClick={e => e.preventDefault()} id="ChangeSettingsTooltip">
                      <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                    </a>
                    <UncontrolledTooltip target="ChangeSettingsTooltip" container="body">
                      {t('layout.header_user_box.icon_tooltip')}
                    </UncontrolledTooltip>
                  </div>*/}
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/account">
                    {t('layout.header_user_box.my_account')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/settings">
                    {t('layout.header_user_box.profile_settings')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/" onClick={logout}>
                    {t('layout.header_user_box.log_out')}
                  </NavLink>
                </NavItem>
              </Nav>
            </ListGroupItem>
            {/* <ListGroupItem className="bg-transparent p-0">
              <div className="grid-menu grid-menu-2col">
                <div className="py-3">
                  <div className="d-flex justify-content-center">
                    <div className="d-flex align-items-center">
                      <div>
                        <FontAwesomeIcon icon={['far', 'chart-bar']} className="font-size-xxl text-info" />
                      </div>
                      <div className="pl-3 line-height-sm">
                        <b className="font-size-lg">$9,693</b>
                        <span className="text-black-50 d-block">
                          {t('layout.header_user_box.widget_item_description')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ListGroupItem>
            <ListGroupItem className="rounded-bottom p-3 text-center">
              <Button
                tag="a"
                href="#/"
                onClick={e => e.preventDefault()}
                color="facebook"
                id="FacebookTooltipHeader"
                container="body">
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fab', 'facebook']} />
                </span>
              </Button>
              <UncontrolledTooltip target="FacebookTooltipHeader">Facebook</UncontrolledTooltip>
              <Button
                tag="a"
                href="#/"
                onClick={e => e.preventDefault()}
                color="dribbble"
                className="mr-2 ml-2"
                id="btnDribbbleTooltipHeader"
                container="body">
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fab', 'dribbble']} />
                </span>
              </Button>
              <UncontrolledTooltip target="btnDribbbleTooltipHeader">Dribbble</UncontrolledTooltip>
              <Button
                tag="a"
                href="#/"
                onClick={e => e.preventDefault()}
                color="twitter"
                id="btnTwitterTooltipHeader"
                container="body">
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fab', 'twitter']} />
                </span>
              </Button>
              <UncontrolledTooltip target="btnTwitterTooltipHeader">Twitter</UncontrolledTooltip>
          </ListGroupItem>*/}
          </ListGroup>
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  )
}

export { HeaderUserbox }
