/* eslint-disable no-negated-condition */
import React, {
  FC, useEffect, useMemo, useState
} from 'react'

import { Row } from 'reactstrap'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TrendingDown, TrendingUp } from 'react-feather'
import { useMatchInfo } from 'src/views/Dashboard/hooks/useMatchInfo'
import TrendChart from 'src/components/News/trends/trendChart'

interface GameData {
  results: {}
}

const units = {
  dolar: 'dolar',
  percentage: 'percentage',
  unit: 'unit',
  none: 'none'
}

export interface StageComparison {
  stage: string
  value: number
  previousValue: number | null
  change: string
  title: string
  unit: string
}

interface NewsProps {
  screenSizeMobile?: Boolean
}

const News: FC<NewsProps> = ({ screenSizeMobile }) => {
  const { matchInfo } = useMatchInfo()
  const matchData = matchInfo.data?.results
  const [isLoaded, setIsLoaded] = useState(false)
  const [trendsData, setTrendsData] = useState({})
  const data: GameData = useMemo(() => ({ results: matchData }), [matchData])

  data.results = matchData

  function comparePropertyByStage(
    data: any,
    unit: string,
    propertyName: string,
    title: string
  ): StageComparison[] {
    const { results } = data

    const stages = Object.keys(results)

    const comparisons: StageComparison[] = []

    for (let i = 0; i < stages.length; i++) {
      const stage = stages[i]
      const value = Math.floor(results[stage][propertyName])
      let previousValue = null

      if (i >= 0) {
        const previousStage = stages[i - 1]

        if (results[previousStage]) {
          previousValue = results[previousStage][propertyName]
        }

        let change = 'No Change'

        if (i > 0) {
          const previousStage = stages[i - 1]
          const previousValue = results[previousStage][propertyName]

          if (value > previousValue) {
            change = 'Increased'
          } else if (value < previousValue) {
            change = 'Decreased'
          }

          comparisons.push({
            stage,
            value,
            change,
            title,
            unit,
            previousValue
          })
        } else {
          comparisons.push({
            stage,
            value,
            change: 'N/A',
            title,
            unit,
            previousValue
          })
        }
      }
    }
    return comparisons
  }

  const TrendsComponentLoaded = (loaded: boolean, data: any) => {
    if (loaded) {
      return data.map((chart: any, i: any) => (
        <TrendChart
          key={i}
          popOver={i}
          trendData={chart[matchInfo.data?.lastResultsStage as number]}
          screenSizeMobile={!!screenSizeMobile}
        />
      ))
    }
    return <div>loading trends..</div>
  }

  useEffect(() => {
    if (data.results !== undefined && !isLoaded) {
      const priceIndexComparisons = comparePropertyByStage(
        data,
        units.dolar,
        'average_price',
        'Precio Promedio'
      )

      const sdPriceComparisons = comparePropertyByStage(
        data,
        units.dolar,
        'sd_price',
        'Dispersión de Precios'
      )
      const soldUComparisons = comparePropertyByStage(
        data,
        units.unit,
        'sold_u_industry',
        'Unidades Vendidas Industria'
      )
      const averageCbu = comparePropertyByStage(data, units.dolar, 'average_cbu', 'Costo Directo Promedio')
      const realAddedDemand = comparePropertyByStage(
        data,
        units.percentage,
        'real_added_demand',
        'Demanda Industria (variación porcentual)'
      )
      const deltaInterestRate = comparePropertyByStage(
        data,
        units.percentage,
        'delta_interest_rate',
        'Tasa Interés (variación porcentual)'
      )

      const trends = []

      trends.push(priceIndexComparisons)
      trends.push(soldUComparisons)
      trends.push(averageCbu)
      trends.push(realAddedDemand)
      trends.push(deltaInterestRate)
      trends.push(sdPriceComparisons)
      setTrendsData(trends)
      setIsLoaded(true)
    }
  }, [data.results, data, isLoaded])

  return isLoaded ? (
    <>
      <div className="app-header-widget">
        <Row
          className="no-gutters"
          style={{
            paddingLeft: '-12px !important',
            paddingRight: '-12px !important',
            justifyContent: 'center'
          }}>
          {TrendsComponentLoaded(isLoaded, trendsData)}
        </Row>
      </div>
    </>
  ) : null
}

export default News
