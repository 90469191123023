export const GET_DECISIONS_SCHEMA_LOADING = 'GET_DECISIONS_SCHEMA_LOADING'
export const GET_DECISIONS_SCHEMA_FAILS = 'GET_DECISIONS_SCHEMA_FAILS'
export const GET_DECISIONS_SCHEMA_SUCCESS = 'GET_DECISIONS_SCHEMA_SUCCESS'

export const ADD_DECISIONS_DATA = 'ADD_DECISIONS_DATA'
export const RESET_DECISIONS_DATA = 'RESET_DECISIONS_DATA'

export const POST_DECISIONS_DATA_LOADING = 'POST_DECISIONS_DATA_LOADING'
export const POST_DECISIONS_DATA_FAILS = 'POST_DECISIONS_DATA_FAILS'
export const POST_DECISIONS_DATA_SUCCESS = 'POST_DECISIONS_DATA_SUCCESS'

interface Interval {
  min: number,
  max: number,
  start?: number
}

export interface DecisionsParameters {
  compensation_cost: Interval
  added_demand_variation: Interval
  financial_cost: Interval
  interest_rate: Interval
  labor_tax: Interval
  vat_tax: Interval
  profit_tax: Interval
}

export interface DecisionsSchema {
  decisions_parameters: DecisionsParameters
}

export interface DecisionsData {
  step: number
  matchId?: string
  currentStage?: number

  compensationCost?: number
  addedDemandVariation?: number
  financialCost?: number
  interestRate?: number
  laborTax?: number
  vatTax?: number
  profitTax?: number
}

export interface GetDesicionsSchemaLoading {
  type: typeof GET_DECISIONS_SCHEMA_LOADING
}

export interface GetDesicionsSchemaFails {
  type: typeof GET_DECISIONS_SCHEMA_FAILS
}

export interface GetDecisionsSchemaSuccess {
  type: typeof GET_DECISIONS_SCHEMA_SUCCESS
  payload: DecisionsSchema
}

export interface AddDecisionsData {
  type: typeof ADD_DECISIONS_DATA
  payload: DecisionsData
}

export interface ResetDecisionsData {
  type: typeof RESET_DECISIONS_DATA
}

export interface PostDecisionsDataLoading {
  type: typeof POST_DECISIONS_DATA_LOADING
}

export interface PostDecisionsDataFails {
  type: typeof POST_DECISIONS_DATA_FAILS
}

export interface PostDecisionsDataSuccess {
  type: typeof POST_DECISIONS_DATA_SUCCESS
  payload: string
}

export type CreateRoundDecisionsDispatchTypes =
  | GetDesicionsSchemaLoading
  | GetDesicionsSchemaFails
  | GetDecisionsSchemaSuccess
  | AddDecisionsData
  | ResetDecisionsData
  | PostDecisionsDataLoading
  | PostDecisionsDataFails
  | PostDecisionsDataSuccess