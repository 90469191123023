import logo1 from '../assets/images/company-logos/phones/0.png'
import logo2 from '../assets/images/company-logos/phones/1.png'
import logo3 from '../assets/images/company-logos/phones/2.png'
import logo4 from '../assets/images/company-logos/phones/3.png'
import logo5 from '../assets/images/company-logos/phones/4.png'
import logo6 from '../assets/images/company-logos/phones/5.png'
import logo7 from '../assets/images/company-logos/phones/6.png'
import logo8 from '../assets/images/company-logos/phones/7.png'
import logo9 from '../assets/images/company-logos/phones/8.png'
import logo10 from '../assets/images/company-logos/phones/9.png'
import logo11 from '../assets/images/company-logos/phones/10.png'
import logo12 from '../assets/images/company-logos/phones/11.png'
import logo13 from '../assets/images/company-logos/phones/12.png'
import logo14 from '../assets/images/company-logos/phones/13.png'
import logo15 from '../assets/images/company-logos/phones/14.png'
import logo16 from '../assets/images/company-logos/phones/15.png'

import logo17 from '../assets/images/company-logos/agro/0.png'
import logo18 from '../assets/images/company-logos/agro/1.png'
import logo19 from '../assets/images/company-logos/agro/2.png'
import logo20 from '../assets/images/company-logos/agro/3.png'
import logo21 from '../assets/images/company-logos/agro/4.png'
import logo22 from '../assets/images/company-logos/agro/5.png'
import logo23 from '../assets/images/company-logos/agro/6.png'
import logo24 from '../assets/images/company-logos/agro/7.png'
import logo25 from '../assets/images/company-logos/agro/8.png'
import logo26 from '../assets/images/company-logos/agro/9.png'
import logo27 from '../assets/images/company-logos/agro/10.png'
import logo28 from '../assets/images/company-logos/agro/11.png'
import logo29 from '../assets/images/company-logos/agro/12.png'
import logo30 from '../assets/images/company-logos/agro/13.png'
import logo31 from '../assets/images/company-logos/agro/14.png'
import logo32 from '../assets/images/company-logos/agro/15.png'

import logo33 from '../assets/images/company-logos/cars/0.png'
import logo34 from '../assets/images/company-logos/cars/1.png'
import logo35 from '../assets/images/company-logos/cars/2.png'
import logo36 from '../assets/images/company-logos/cars/3.png'
import logo37 from '../assets/images/company-logos/cars/4.png'
import logo38 from '../assets/images/company-logos/cars/5.png'
import logo39 from '../assets/images/company-logos/cars/6.png'
import logo40 from '../assets/images/company-logos/cars/7.png'
import logo41 from '../assets/images/company-logos/cars/8.png'
import logo42 from '../assets/images/company-logos/cars/9.png'
import logo43 from '../assets/images/company-logos/cars/10.png'
import logo44 from '../assets/images/company-logos/cars/11.png'
import logo45 from '../assets/images/company-logos/cars/12.png'
import logo46 from '../assets/images/company-logos/cars/13.png'
import logo47 from '../assets/images/company-logos/cars/14.png'
import logo48 from '../assets/images/company-logos/cars/15.png'
import logo49 from '../assets/images/company-logos/cars/16.png'
import logo50 from '../assets/images/company-logos/cars/17.png'
import logo51 from '../assets/images/company-logos/cars/18.png'

export const images : {[key:string] : any} = {
  // phones
  '../assets/images/company-logos/phones/0.png': logo1,
  '../assets/images/company-logos/phones/1.png': logo2,
  '../assets/images/company-logos/phones/2.png': logo3,
  '../assets/images/company-logos/phones/3.png': logo4,
  '../assets/images/company-logos/phones/4.png': logo5,
  '../assets/images/company-logos/phones/5.png': logo6,
  '../assets/images/company-logos/phones/6.png': logo7,
  '../assets/images/company-logos/phones/7.png': logo8,
  '../assets/images/company-logos/phones/8.png': logo9,
  '../assets/images/company-logos/phones/9.png': logo10,
  '../assets/images/company-logos/phones/10.png': logo11,
  '../assets/images/company-logos/phones/11.png': logo12,
  '../assets/images/company-logos/phones/12.png': logo13,
  '../assets/images/company-logos/phones/13.png': logo14,
  '../assets/images/company-logos/phones/14.png': logo15,
  '../assets/images/company-logos/phones/15.png': logo16,
  // agro
  '../assets/images/company-logos/agro/0.png': logo17,
  '../assets/images/company-logos/agro/1.png': logo18,
  '../assets/images/company-logos/agro/2.png': logo19,
  '../assets/images/company-logos/agro/3.png': logo20,
  '../assets/images/company-logos/agro/4.png': logo21,
  '../assets/images/company-logos/agro/5.png': logo22,
  '../assets/images/company-logos/agro/6.png': logo23,
  '../assets/images/company-logos/agro/7.png': logo24,
  '../assets/images/company-logos/agro/8.png': logo25,
  '../assets/images/company-logos/agro/9.png': logo26,
  '../assets/images/company-logos/agro/10.png': logo27,
  '../assets/images/company-logos/agro/11.png': logo28,
  '../assets/images/company-logos/agro/12.png': logo29,
  '../assets/images/company-logos/agro/13.png': logo30,
  '../assets/images/company-logos/agro/14.png': logo31,
  '../assets/images/company-logos/agro/15.png': logo32,
  // cars
  '../assets/images/company-logos/cars/0.png': logo33,
  '../assets/images/company-logos/cars/1.png': logo34,
  '../assets/images/company-logos/cars/2.png': logo35,
  '../assets/images/company-logos/cars/3.png': logo36,
  '../assets/images/company-logos/cars/4.png': logo37,
  '../assets/images/company-logos/cars/5.png': logo38,
  '../assets/images/company-logos/cars/6.png': logo39,
  '../assets/images/company-logos/cars/7.png': logo40,
  '../assets/images/company-logos/cars/8.png': logo41,
  '../assets/images/company-logos/cars/9.png': logo42,
  '../assets/images/company-logos/cars/10.png': logo43,
  '../assets/images/company-logos/cars/11.png': logo44,
  '../assets/images/company-logos/cars/12.png': logo45,
  '../assets/images/company-logos/cars/13.png': logo46,
  '../assets/images/company-logos/cars/14.png': logo47,
  '../assets/images/company-logos/cars/15.png': logo48,
  '../assets/images/company-logos/cars/16.png': logo49,
  '../assets/images/company-logos/cars/17.png': logo50,
  '../assets/images/company-logos/cars/18.png': logo51,
}

export function getImagePaths(category: string) {
  const categoryKeys = Object.keys(images)
    .filter(key => key.includes(`/${category}/`))

  // Map over the filtered keys to return their respective values
  return categoryKeys.map(key => images[key])
}