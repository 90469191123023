import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import {
  Row, Col, Card, ListGroup
} from 'reactstrap'
// import PerfectScrollbar from 'react-perfect-scrollbar'
import { Invitation, Notification } from 'src/store/actions/types/notification'
import { setNotificationList } from 'src/store'
import { useDispatch } from 'react-redux'
import { useNotifications } from 'src/hooks/useNotificationList'
import { InvitationListItem } from './InvitationsListItem'

interface InvitationsListProps {
  showAcceptInvite: boolean
}

function InvitationsList(props: InvitationsListProps) {
  const { showAcceptInvite } = props

  const { notifications, isLoading } = useNotifications()
  const dispatch = useDispatch()

  const [filteredNotifications, setFilteredNotifications] = useState(notifications as Notification[])

  const onInvitationAccepted = (matchId: Number) => {
    // TODO remove once delete match endpoint is properly working
    const updatedNotifications = filteredNotifications?.filter(
      notification => notification.data.match_id !== matchId
    )

    setFilteredNotifications(updatedNotifications)
    dispatch(setNotificationList(updatedNotifications))
  }

  const emptyList = () => (
    <div className="scroll-area scroll-area-lg shadow-overflow">
      {/* <PerfectScrollbar
        options={{
          wheelPropagation: false
        }}>*/}
      <div className="text-center my-5">
        <div className="avatar-icon-wrapper rounded-circle m-0">
          <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-success text-success m-0 d-130">
            <FontAwesomeIcon icon={['fas', 'times']} className="d-flex align-self-center display-3" />
          </div>
        </div>
        <h6 className="font-weight-bold font-size-lg mb-1 mt-4 text-black">Enhorabuena!</h6>
        <p className="text-black-50 mb-0">No tienes invitaciones pendientes.</p>
      </div>
      {/* </PerfectScrollbar>*/}
    </div>
  )

  return !isLoading && filteredNotifications.length > 0 ? (
    <div className="pending">
      <Row className="m-0">
        <Col className="d-flex">
          <Card className="w-100">
            <div className="divider" />
            <ListGroup flush={true}>
              {filteredNotifications.length === 0 && emptyList()}
              {filteredNotifications.map((notification: { data: Invitation }, index) => (
                <InvitationListItem
                  key={index}
                  invitation={notification.data}
                  onInvitationAccepted={onInvitationAccepted}
                  showUserAdd={showAcceptInvite}
                />
              ))}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </div>
  ) : (
    <div />
  )
}

export { InvitationsList }
