import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

interface Props {
  matchId: any
  resultsView: boolean
  goalsView: boolean
  dashboardView: boolean
}

const EndgameNav: FC<Props> = ({
  matchId, resultsView, goalsView, dashboardView
}: Props) => {
  const { t } = useTranslation()

  return (
    <div className="d-flex align-items-center" style={{ gap: '0.5em' }}>
      <Link to={`/endgame/${matchId}`}>
        <Button size="lg" className="text-nowrap" color={resultsView ? 'sherpa-secondary' : 'sherpa-primary'}>
          <span className="d-xl-block">{t('page.navigation_buttons.result')}</span>
        </Button>
      </Link>
      <Link to={`/endgame/${matchId}/dashboard`}>
        <Button
          size="lg"
          className="text-nowrap"
          color={dashboardView ? 'sherpa-secondary' : 'sherpa-primary'}>
          <span className="d-xl-block">{t('page.navigation_buttons.dashboard')}</span>
        </Button>
      </Link>
      <Link to={`/endgame/${matchId}/goals`}>
        <Button size="lg" className="text-nowrap" color={goalsView ? 'sherpa-secondary' : 'sherpa-primary'}>
          <span className="d-xl-block">{t('page.navigation_buttons.goals')}</span>
        </Button>
      </Link>
    </div>
  )
}

export default EndgameNav
