import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { CustomMenuItem } from './CustomMenu'

const CustomMenuListItem: FC<CustomMenuItem> = props => (
  <li key={props.index}>
    <Link to={props.to}>
      <span className={`sidebar-icon icon-size-lg font-weight-bold ${props.icon}`} />
      <span className="menu-item-label font-weight-bold font-size-lg">{props.label}</span>
    </Link>
  </li>
)

export default CustomMenuListItem
