import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'src/hooks/useUserData'
import { Ceo } from 'src/store'
import { HeaderMatch, SubHeader } from '../../layoutComponents'
import { useMatchInfo } from '../../views/Dashboard/hooks/useMatchInfo'

function SimpleLayout(props: PropsWithChildren<unknown>) {
  const { t } = useTranslation()
  // const currentMatch = useSelector((state: State) => state.preview.preview?.match)

  const { matchInfo } = useMatchInfo()
  const currentStage = matchInfo.data?.currentStage || 0
  const { user } = useUserData()

  const ceoFounded: Ceo | any = matchInfo.data?.ceos?.some(
    (ceo: { id: number; isFounded: boolean }) => Number(ceo.id) === Number(user?.id) && ceo.isFounded
  )

  return (
    <>
      {currentStage && currentStage > 0 ? (
        <HeaderMatch />
      ) : (
        <SubHeader
          title={`${t('page.dashboard.title')} ${user?.name}`}
          description={(!ceoFounded && `${t('page.add_company.subtitle')}`) as string}
        />
      )}
      <div>{props.children}</div>
    </>
  )
}

export default SimpleLayout
