import React from 'react'
import { User } from 'src/store'
import { stringToHexColor } from 'src/utils/stringToHexColor'
import { Togglable } from '../Togglable'

interface UserAvatarProps {
  user: User
}

function UserAvatar(props: UserAvatarProps) {
  const { user } = props

  const userInitials = (user.name[0] + user.lastName[0]).toUpperCase()
  const userColor = stringToHexColor(user.email)

  return (
    <div className="user-avatar d-50">
      <div
        className="avatar-icon rounded-circle d-50 text-white-50 d-flex justify-content-center align-items-center"
        style={{ backgroundColor: userColor }}>
        {/* Enable when can get user avatar */}
        <Togglable enabled={false} fallback={<span>{userInitials}</span>}>
          {/* TODO: create a generic component for images management */}
          {/* TODO: get user avatar from api */}
          <img alt="..." src={user.avatar} />
        </Togglable>
      </div>
    </div>
  )
}
export { UserAvatar }
