import React, { ReactNode } from 'react'
import classes from './ButtonWrapper.module.scss'

interface ButtonWrapperProps {
  children: ReactNode
}

function ButtonWrapper(props: ButtonWrapperProps) {
  const { children } = props

  return <div className={`mr-2 ${classes.wrapper}`}>{children}</div>
}

export { ButtonWrapper }
