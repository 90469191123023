import React, { ReactNode } from 'react'
import { motion } from 'framer-motion'
import { usePageVariants } from './hooks/usePageVariants'
import { usePageTransition } from './hooks/usePageTransition'

interface FadeinTransitionProps {
  children: ReactNode
}

function FadeinTransition(props: FadeinTransitionProps) {
  const { children } = props
  const pageVariants = usePageVariants()
  const pageTransition = usePageTransition()

  return (
    <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
      {children}
    </motion.div>
  )
}
export { FadeinTransition }
