import React, { useState } from 'react'
import { Button, Togglable } from 'src/components'
import { TrashIcon } from 'src/components/Icons'
import { removeMatch } from 'src/store/actions/match'
import { useDispatch } from 'react-redux'
import { useUserData } from 'src/hooks/useUserData'
import { ConfirmationModal } from 'src/components/ConfirmationModal/ConfirmationModal'

interface DeleteMatchButtonProps {
  matchId: string
  onMatchDeletedSuccess?: (matchId: string) => void
  paddingx?: number
  endedGame: boolean
}

function DeleteMatchButton({
  onMatchDeletedSuccess, matchId, paddingx, endedGame
}: DeleteMatchButtonProps) {
  const { isAdmin } = useUserData()
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const closeModal = () => setIsModalOpen(false)

  const onConfirmation = async () => {
    await dispatch(removeMatch(matchId))
    closeModal()
    onMatchDeletedSuccess?.(matchId)
  }

  return (
    <>
      <Togglable enabled={isAdmin}>
        {/* <Button onClick={() => setIsModalOpen(true)} look="link">
          <CrossIcon />
        </Button>*/}
        <Button
          onClick={() => !endedGame && setIsModalOpen(true)}
          disabled={endedGame}
          look="link"
          icon={true}
          paddingx={paddingx}>
          <TrashIcon />
        </Button>
      </Togglable>
      <ConfirmationModal
        enabled={isModalOpen}
        onConfirmation={onConfirmation}
        onCancellation={closeModal}
        temperament="first"
        // TODO: translate
        questionMessage="Deseas eliminar la partida?"
        description="Una vez eliminada se perdera toda la informacion de la misma."
      />
    </>
  )
}

export { DeleteMatchButton }
