import { User } from 'src/store'

export const calcPercentAmount = (percent: number, amount: number) => (percent * amount) / 100

export const formatIndustry = (industry:string):String => {
  let industryFormated: string = 'loading..'

  switch (industry) {
    case 'cars':
      return industryFormated = 'Industria Automotriz'
    case 'agro':
      return industryFormated = 'Industria Agroinsumos'
    case 'phones':
      return industryFormated = 'Telefonía Móvil'
    default:
      return industryFormated
  }
}

export const capitalizeString = (text: string) => {
  const newText = text.charAt(0).toUpperCase() + text.slice(1)

  return newText
}

export const filterCeosOnly = (communityMembers: User[]): User[] => {
  const ceosOnly = communityMembers.filter(
    (player: { id: string; isAdmin: boolean }) => !player?.isAdmin

    /* TODO: THIS WILL CHANGE AND THIS FILTER REMOVED.
    /* NOW AVOIDING ANY "isAdmin community member" TO PLAY.
    /* TODO: WILL HANDLE THIS EXCEPTION, OR EVEN BETTER, ALLOWING ADMINS AND GOB TO PARTICIPATE.
    (
     player: { id: string; isAdmin: boolean }) => !player?.isAdmin
     ||
     player?.isAdmin && String(player?.id) !== String(user?.id
    )*/
  )

  return ceosOnly
}