import React from 'react'
import CountUp from 'react-countup'
import { useTranslation } from 'react-i18next'
import { Progress } from 'reactstrap'

interface Props {
  dataProp: string
  value: number
}

const DataProgressBarTwo = (props: Props) => {
  const { dataProp, value } = props
  const { t } = useTranslation()

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="font-weight-bold">{t(`variables.${dataProp}`)}</div>
        <div className="display-5 font-weight-bold">
          <CountUp
            start={0}
            end={value}
            duration={1}
            delay={1}
            separator=","
            decimals={0}
            decimal="."
            suffix="%"
          />
        </div>
      </div>
      <div>
        <Progress className="progress-sm progress-bar-rounded" color="finance" value={value} />
      </div>
    </>
  )
}

export default DataProgressBarTwo
