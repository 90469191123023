import { PreviewDispatchTypes, Preview } from '../actions/types/preview'
import * as actionTypes from '../actions/types'

interface PreviewState {
  loading: boolean,
  preview?: Preview
}
const initialState: PreviewState = {
  loading: false,
  preview: undefined,
}

const previewReducer = (
  state: PreviewState = initialState,
  action: PreviewDispatchTypes,
): PreviewState => {
  switch (action.type) {
    case actionTypes.PREVIEW_FAIL:
      return {
        loading: false,
      }
    case actionTypes.PREVIEW_LOADING:
      return {
        loading: true,
      }
    case actionTypes.PREVIEW_SUCCESS:
      return {
        loading: false,
        preview: action.payload,
      }
    case actionTypes.RESET_PREVIEW:
      return {
        loading: false,
        preview: initialState.preview,
      }
    default:
      return state
  }
}

export { previewReducer }
