import React, { FC } from 'react'
import { Col, Row } from 'reactstrap'
import OverviewPortfolioList from 'src/components/Overview/OverviewPortfolioList/OverviewPortfolioList'
import Winning from './GameResults/Winning'
import Loosing from './GameResults/Loosing'

interface BannerResultsProps {
  position: number
  matchId: any
}

const BannerResults: FC<BannerResultsProps> = (props: BannerResultsProps) => {
  const { position, matchId } = props

  return (
    <Row>
      <Col md="6" lg="5" xl="4" className="dashboard-alpha">
        <div className="scroll-area-xxl">
          <OverviewPortfolioList endgame={true} />
        </div>
      </Col>
      <Col md="2" xl="3" />
      <Col lg="4" xl="5">
        {position <= 1 ? (
          <Winning matchId={matchId} position={position} />
        ) : (
          <Loosing matchId={matchId} position={position} />
        )}
      </Col>
    </Row>
  )
}

export default BannerResults
