/* eslint-disable padded-blocks */
/* eslint-disable no-negated-condition */
/* eslint-disable no-nested-ternary */
import React, {
  FC, useCallback, useEffect, useMemo, useState
} from 'react'
// import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { useIsAdmin } from 'src/hooks/useIsAdmin'
// import { addDecisionsData } from 'src/store/actions'
// import { useDecisionsParameters } from 'src/views/CreateRoundDecisions/hooks/useCreateRoundDecisions'
// TODO: remove useMatchPreview IMPORTS AND DUPLICATED HOOK FILES
// import { useMatchPreview } from 'src/hooks/useMatchPreview'
// import { useTranslation } from 'react-i18next'
import { useMatches } from 'src/hooks/useMatchList'
import { useUser } from 'src/hooks/useUser'
import { formatIndustry } from 'src/utils/calculations'
import { useMatchInfo } from '../../views/Dashboard/hooks/useMatchInfo'
import BankruptNav from './NavViews/BankruptNav'
import SimulatorNav from './NavViews/SimulatorNav'
import EndgameNav from './NavViews/EndgameNav'
import PageTitleBreadcrumb from './NavViews/PageTitleBreadcrumb'
import HeaderInfo from './NavViews/HeaderInfo'

interface Props {
  pageTitleStyle?: string
  pageTitleBackground?: string
  pageTitleShadow?: any
  pageTitleBreadcrumb?: boolean
  pageTitleIconBox?: boolean
  titleHeading?: string
}

const HeaderMatch: FC<Props> = ({
  pageTitleStyle = '',
  pageTitleBackground = '',
  pageTitleShadow = '',
  pageTitleBreadcrumb = false,
  pageTitleIconBox = true,
  titleHeading = ''
}: Props) => {

  /*
   const {
      added_demand_variation,
      compensation_cost,
      financial_cost,
      interest_rate,
      labor_tax,
      vat_tax,
      profit_tax
    } = useDecisionsParameters() */

  const isAdmin = useIsAdmin()
  const location = useLocation()
  // const { t } = useTranslation()

  const { matchInfo } = useMatchInfo()
  const match = matchInfo.data
  // const currentStage = matchInfo.data?.currentStage
  const matchId = String(matchInfo.data?.matchId)
  const { matches } = useMatches()
  const user = useUser()
  const [isUserOnBankrupt, setIsUserOnBankrupt] = useState(null)
  const [endMatch, setEndMatch] = useState<boolean | undefined>(undefined)
  const [industry, setIndustry] = useState<string | undefined>(undefined)
  const [matchType, setMatchType] = useState<string | undefined>(undefined)

  // const dispatch = useDispatch()
  const checkIsEndMatch = useCallback((matchInfo: any) => {
    const isGameEnded = matchInfo?.statusId === 3

    return isGameEnded
  }, [])

  useEffect(() => {
    if (matchInfo.data) {
      setEndMatch(checkIsEndMatch(matchInfo.data))
    }
  }, [checkIsEndMatch, endMatch, matchInfo.data])

  const isGoalsView = useMemo(() => location.pathname === `/dashboard/${matchId}/goals`, [
    location.pathname,
    matchId
  ])
  const isGoalsBankruptView = useMemo(() => location.pathname === `/bankrupt/${matchId}/goals`, [
    location.pathname,
    matchId
  ])
  const isResultsBankruptView = useMemo(() => location.pathname === `/bankrupt/${matchId}`, [
    location.pathname,
    matchId
  ])
  const isResultsEndGameView = useMemo(() => location.pathname === `/endgame/${matchId}`, [
    location.pathname,
    matchId
  ])
  const isGoalsEndGameView = useMemo(() => location.pathname === `/endgame/${matchId}/goals`, [
    location.pathname,
    matchId
  ])
  const isDashboardView = useMemo(() => location.pathname === `/dashboard/${matchId}`, [
    location.pathname,
    matchId
  ])
  const isDashboardEndGameView = useMemo(() => location.pathname === `/endgame/${matchId}/dashboard`, [
    location.pathname,
    matchId
  ])
  const isDashboardBankruptView = useMemo(() => location.pathname === `/bankrupt/${matchId}/dashboard`, [
    location.pathname,
    matchId
  ])
  const isSimulatorView = useMemo(() => location.pathname === `/dashboard/${matchId}/simulator`, [
    location.pathname,
    matchId
  ])
  // const isIndustryView = useMemo(() => location.pathname === '/market-and-competence', [location.pathname])

  useEffect(() => {
    if (matchInfo.data?.matchId && matches && matches?.length > 0) {
      const getMatchProp = (matchId: any): any => {
        const matchType = matches?.filter(match => Number(matchId) === Number(match.id))

        return matchType && matchType[0]
      }

      setIndustry(getMatchProp(matchId).industry)
      setMatchType(getMatchProp(matchId).type)

      const checkBankrupt: any =
        matchInfo.data?.ceos?.some(ceo => ceo.id === Number(user?.id) && ceo.dismissed) ||
        matchInfo.data?.ceos?.some(
          (ceo: { id: number; bankrupt: any }) => ceo.id === Number(user?.id) && ceo.bankrupt
        )

      setIsUserOnBankrupt(checkBankrupt)
    }
  }, [
    matchInfo.data?.matchId,
    matches,
    user,
    matchType,
    industry,
    matchInfo.loading,
    matchInfo.data?.ceos,
    matchId
  ])

  /* const addRoundDecisionsData = useCallback(() => {
    dispatch(
      addDecisionsData({
        matchId,
        currentStage,
        step: 1,
        compensationCost: compensation_cost.min || 0,
        addedDemandVariation: added_demand_variation.start || 0,
        financialCost: financial_cost.min || 0,
        interestRate: interest_rate.min || 0,
        laborTax: labor_tax.min || 0,
        vatTax: vat_tax.min || 0,
        profitTax: profit_tax.min || 0
      })
    )
  }, [
    dispatch,
    matchId,
    currentStage,
    compensation_cost.min,
    added_demand_variation.start,
    financial_cost.min,
    interest_rate.min,
    labor_tax.min,
    vat_tax.min,
    profit_tax.min
  ])*/

  return (
    <>
      {!matchInfo.loading && (
        <div
          className={clsx('app-page-title-reduced-match', pageTitleStyle, pageTitleBackground, {
            'app-page-title--shadow': pageTitleShadow
          })}>
          <div>
            {pageTitleBreadcrumb && <PageTitleBreadcrumb titleHeading={titleHeading} />}
            <HeaderInfo
              isAdmin={isAdmin}
              matchType={matchType as string}
              matchIndustryFormated={formatIndustry(industry as string)}
              industry={industry as String}
              match={match}
              pageTitleIconBox={pageTitleIconBox}
            />
          </div>
          {isAdmin ? (
            <div className="d-flex align-items-center">
              {/* {matchType === 'custom' && (
                <Link to={`${location.pathname}/create-round-decisions`}>
                  <Button
                    size="lg"
                    className="text-nowrap mr-2"
                    color="sherpa-success"
                    onClick={addRoundDecisionsData}>
                    <span className="d-xl-block">{t('page.navigation_buttons.govern_round')}</span>
                  </Button>
                </Link>
              )}*/}
            </div>
          ) : isUserOnBankrupt ? (
            <BankruptNav
              matchId={matchId}
              resultsView={isResultsBankruptView}
              goalsView={isGoalsBankruptView}
              dashboardView={isDashboardBankruptView}
            />
          ) : endMatch ? (
            <EndgameNav
              matchId={matchId}
              resultsView={isResultsEndGameView}
              goalsView={isGoalsEndGameView}
              dashboardView={isDashboardEndGameView}
            />
          ) : (
            <SimulatorNav
              matchId={matchId}
              dashboardView={isDashboardView}
              goalsView={isGoalsView}
              simulatorView={isSimulatorView}
            />
          )}
        </div>
      )}
    </>
  )
}

export default HeaderMatch
