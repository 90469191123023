import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import { Togglable } from 'src/components'
import { useIsLoggedIn } from './useIsLoggedIn'

function LoginRoute(props: RouteProps) {
  const isLoggedIn = useIsLoggedIn()

  return (
    <Togglable enabled={isLoggedIn} fallback={<Route {...props} />}>
      <Redirect to="/home" />
    </Togglable>
  )
}

export { LoginRoute }
