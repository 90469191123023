/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlagIcon } from 'react-flag-kit'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

import {
  Container, Nav, NavItem, NavLink as NavLinkStrap
} from 'reactstrap'

function Multilanguage() {
  const { i18n } = useTranslation()
  const [lan, setLan] = useState('es')
  const changeLanguage = (language: string) => {
    setLan(language)
    i18n.changeLanguage(language)
  }
  const [collapse, setCollapse] = useState(false)
  const toggle = () => setCollapse(!collapse)

  return (
    <>
      <div>
        <Container
          className="header-top-section pt-0 d-block bg-neutral-primary font-size-lg p-0 d-inline-block shadow-none border-0
         text-center d-44 rounded position-relative btn btn-neutral-primary pr-3 mr-3">
          <div className="d-flex header-nav-menu justify-content-between align-items-center navbar-dark">
            <ul className="d-flex">
              <li>
                <a
                  className="rounded-sm py-0 px-1 font-size-xs text-uppercase"
                  href="#/"
                  onClick={e => e.preventDefault()}>
                  {/* {t('layout.multilanguage.languages')} */}
                  <span className="mr-0">
                    <FlagIcon code={lan === 'en' ? 'US' : lan === 'es' ? 'ES' : 'BR'} size={26} />
                  </span>
                  {/* <span className="opacity-5 dropdown-arrow">
                    <FontAwesomeIcon icon={['fas', 'angle-down']} />
                  </span>*/}
                </a>
                <div className="submenu-dropdown submenu-dropdown--sm">
                  <div className="shadow-sm-dark bg-white rounded-sm">
                    <Nav pills={true} className="nav-neutral-primary flex-column p-2">
                      <NavItem>
                        <NavLinkStrap
                          href="#/"
                          onClick={() => changeLanguage('en')}
                          className="px-3 d-flex align-items-center">
                          <span className="mr-3">
                            <FlagIcon code="US" size={28} />
                          </span>
                          <span>EN</span>
                        </NavLinkStrap>
                      </NavItem>
                      <NavItem>
                        <NavLinkStrap
                          href="#/"
                          onClick={() => changeLanguage('es')}
                          className="px-3 d-flex align-items-center">
                          <span className="mr-3">
                            <FlagIcon code="ES" size={28} />
                          </span>
                          <span>SP</span>
                        </NavLinkStrap>
                      </NavItem>
                      <NavItem>
                        <NavLinkStrap
                          href="#/"
                          onClick={() => changeLanguage('pt')}
                          className="px-3 d-flex align-items-center">
                          <span className="mr-3">
                            <FlagIcon code="BR" size={28} />
                          </span>
                          <span>BR</span>
                        </NavLinkStrap>
                      </NavItem>
                    </Nav>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          {/* <div className="divider mt-2" /> */}
        </Container>
      </div>
      <div
        className={clsx('collapse-page-trigger', { 'is-active': collapse })}
        onClick={toggle}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
        aria-label="collapse"
      />
    </>
  )
}

export { Multilanguage }
