import React, { FC } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { connect } from 'react-redux'
import { State } from '../../store'
import { currentYear } from '../../utils/scripts'

// todo: specify the types and change mapStateToProps to hooks
export interface FooterPropsI {
  footerShadow: any
  footerBgTransparent: any
}

const Footer: FC<FooterPropsI> = ({ footerShadow, footerBgTransparent }) => (
  <>
    <div
      className={clsx('app-footer text-black-50', {
        'app-footer--shadow': footerShadow,
        'app-footer--opacity-bg': footerBgTransparent
      })}>
      <div className="app-footer--first">
        <Nav>
          <NavItem>
            <NavLink tag={Link} to="/home" className="rounded-sm">
              Home
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div className="app-footer--second">
        <span>Company Hike </span>
        ©
        {currentYear()}
      </div>
    </div>
  </>
)

const mapStateToProps = (state: State) => ({
  footerFixed: state.theme.footerFixed,
  footerShadow: state.theme.footerShadow,
  footerBgTransparent: state.theme.footerBgTransparent
})

export default connect(mapStateToProps)(Footer)
