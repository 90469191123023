import { Dispatch } from 'redux'
import { client } from 'src/api'
import { snakeize } from 'src/utils/camelize'
import { CreateRoundDecisionsDispatchTypes, DecisionsData } from './types'
import * as actionTypes from './types'

const addDecisionsData = (data: DecisionsData) => ({
  type: actionTypes.ADD_DECISIONS_DATA,
  payload: data
})

const setDecisionsParameters = (data: DecisionsData) => ({
  compensationCost: data.compensationCost,
  addedDemandVariation: data.addedDemandVariation,
  financialCost: data.financialCost,
  interestRate: data.interestRate,
  laborTax: data.laborTax,
  vatTax: data.vatTax,
  profitTax: data.profitTax,
})

const postDecisionsData = (data: {
  community_id: string,
  version: string,
  data: DecisionsData,
}) => async (dispatch: Dispatch<CreateRoundDecisionsDispatchTypes>) => {
  try {
    dispatch({
      type: actionTypes.POST_DECISIONS_DATA_LOADING
    })

    const formatted = snakeize(setDecisionsParameters(data.data))

    const res = await client.post('match/set_goverment_parameters', {
      match_id: data.data.matchId,
      parameters: formatted
    })

    return res.data
  } catch (error) {
    dispatch({
      type: actionTypes.POST_DECISIONS_DATA_FAILS
    })
  }
  return null
}

const resetDecisionsData = () => ({
  type: actionTypes.RESET_DECISIONS_DATA
})

export { addDecisionsData, postDecisionsData, resetDecisionsData }