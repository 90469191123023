import { toast, Flip } from 'react-toastify'
import { MatchListDispatchTypes, Match } from '../../actions/types/match/matchList'
import * as actionTypes from '../../actions/types/match'

interface MatchListState {
  loading: boolean,
  matches?: Match[]
}
const initialState: MatchListState = {
  loading: false,
  matches: []
}

const matchListReducer = (
  state: MatchListState = initialState,
  action: MatchListDispatchTypes,
): MatchListState => {
  switch (action.type) {
    case actionTypes.MATCH_LIST_FAIL:
      toast.error('There was a problem with the server, please wait.', {
        containerId: 'A',
        transition: Flip,
      })
      return {
        loading: false,
      }
    case actionTypes.MATCH_LIST_LOADING:
      return {
        loading: true,
      }
    case actionTypes.MATCH_LIST_SUCCESS:
      return {
        loading: false,
        matches: action.payload.Match,
      }

    default:
      return state
  }
}

export { matchListReducer }
