interface ApiConfig {
  url: string
}
interface LocalStorageConfig {
  keys: {
    session: string
    store: string
    onboarding: string
  }
}
interface Config {
  readonly api: ApiConfig
  localStorage: LocalStorageConfig
}

const config: Config = {
  api: {
    url: process.env.REACT_APP_API_URL || ''
  },
  localStorage: {
    keys: {
      session: 'ch-auth',
      store: 'ch-store',
      onboarding: 'ch-onboarging'
    }
  }
}

export { config }
