import { useState } from 'react'
import { useSelector } from 'react-redux'
import { State, Match } from 'src/store'

function useMatches() {
  const matches = useSelector((state: State) => state.match.matchList.matches)

  const isLoading = useSelector((state: State) => state.match.matchList.loading)
  const [forceRender, setForceRender] = useState(false)

  function change() {
    try {
      setForceRender(true)
    } catch {
      //  setForceRender(false)
    }
  }

  setTimeout(() => change(), 100)

  return {
    matches, isLoading, forceRender
  }
}

function useMatch(id:string) {
  const { matches, isLoading } = useMatches()
  const match = matches?.find((match: Match) => match.id === id)

  return { match, isLoading }
}

export { useMatches, useMatch }