import { User } from '../user'

const actions = {
  GET_COMMUNITY_MEMBERS_LOADING: 'GET_COMMUNITY_MEMBERS_LOADING',
  GET_COMMUNITY_MEMBERS_FAIL: 'GET_COMMUNITY_MEMBERS_FAIL',
  GET_COMMUNITY_MEMBERS_SUCCESS: 'GET_COMMUNITY_MEMBERS_SUCCESS'
}

interface GetCommunityMembersLoading {
  type: typeof actions.GET_COMMUNITY_MEMBERS_LOADING;
}

interface GetCommunityMembersFail {
  type: typeof actions.GET_COMMUNITY_MEMBERS_FAIL;
}

interface GetCommunityMembersSuccess {
  type: typeof actions.GET_COMMUNITY_MEMBERS_SUCCESS;
  payload: {
    communityMembers : User[];
  }
}

type GetCommunityMembersDispatchTypes =
  | GetCommunityMembersLoading
  | GetCommunityMembersSuccess
  | GetCommunityMembersFail;

export type { GetCommunityMembersDispatchTypes, GetCommunityMembersSuccess }
export { actions as getCommunityMembersActions }