import React from 'react'
import { BeatLoader } from 'react-spinners'

interface LoaderProps {
  color?: string
}

/**
 * White Loader centered on container
 * @param props LoaderProps
 * @returns undefined
 */
function Loader(props: LoaderProps) {
  const { color } = props

  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <BeatLoader color={color ?? 'white'} />
    </div>
  )
}

export { Loader }
