import { Dispatch } from 'redux'
import { client } from 'src/api/client'
import { InvitationsDispatchTypes, InvitationSent, User } from './types'
import * as actionTypes from './types'

interface AddUserToMatchConfig {
  communityId: string
  matchId: string
  user: User
}

interface InvitationsFromApi {
  id: number
  community_id: number
  game_id: number
  user_id: number
  accepted: boolean | null
}

const addUserToMatch = (config: AddUserToMatchConfig) => async (dispatch: Dispatch<InvitationsDispatchTypes>) => {
  const { user, matchId, communityId } = config

  try {
    dispatch({
      type: actionTypes.ADD_USER_TO_MATCH_LOADING
    })
    const body = {
      match_id: matchId,
      community_id: communityId,
      user_id: user.id,
      email: user.email,
      name: user.name,
      lastname: user.lastName,
    }
    // @here: despues de agregar jugador al match, deberia editar su estado: userInvited?
    const res = await client.post('match_invite', body)

    const invitation: InvitationSent = res && {
      communityId: communityId.toString(),
      matchId: matchId.toString(),
      userId: user.id.toString()
    }

    dispatch({
      type: actionTypes.ADD_USER_TO_MATCH_SUCCESS,
      payload: invitation
    })
  } catch (error) {
    dispatch({
      type: actionTypes.ADD_USER_TO_MATCH_FAIL
    })
  }
}

const removeUserFromMatch = (invitationToRemove: InvitationSent) => async (dispatch: Dispatch<InvitationsDispatchTypes>) => {
  try {
    dispatch({
      type: actionTypes.REMOVE_USER_FROM_MATCH_LOADING
    })

    const body = {
      invitation_id: invitationToRemove.invitationId
    }

    await client.post('delete_invitation', body)

    dispatch({
      type: actionTypes.REMOVE_USER_FROM_MATCH_SUCCESS,
      payload: invitationToRemove
    })
  } catch (error) {
    dispatch({
      type: actionTypes.REMOVE_USER_FROM_MATCH_FAIL
    })
  }
}

const getMatchInvitations = (matchId: string) => async (dispatch: Dispatch<InvitationsDispatchTypes>) => {
  try {
    dispatch({
      type: actionTypes.GET_INVITATIONS_LOADING
    })

    const res = await client.get(`game_invitations/${matchId}`)

    const invitatios: InvitationSent[] = res.data?.map((invitation: InvitationsFromApi) => ({
      invitationId: invitation.id.toString(),
      communityId: invitation.community_id.toString(),
      matchId: invitation.game_id.toString(),
      userId: invitation.user_id.toString(),
      accepted: invitation.accepted
    }))

    dispatch({
      type: actionTypes.GET_INVITATIONS_SUCCESS,
      payload: invitatios
    })
  } catch (error) {
    dispatch({
      type: actionTypes.GET_INVITATIONS_FAILS
    })
  }
}

export { addUserToMatch, removeUserFromMatch, getMatchInvitations }