export const RANKING_SET = 'RANKING_SET'
export const RANKING_GLOBAL_LOADING = 'RANKING_GLOBAL_LOADING'
export const RANKING_GLOBAL_FAIL = 'RANKING_GLOBAL_FAIL'
export const RANKING_GLOBAL_SUCCESS = 'RANKING_GLOBAL_SUCCESS'
export const RANKING_COMMUNITY_LOADING = 'RANKING_COMMUNITY_LOADING'
export const RANKING_COMMUNITY_FAIL = 'RANKING_COMMUNITY_FAIL'
export const RANKING_COMMUNITY_SUCCESS = 'RANKING_COMMUNITY_SUCCESS'

export interface Ranking {
  ranking: Player[]
  user_ranking: Player
}

export type Player = {
  id: number
  name: string
  last_name: string
  email: string
  points: number
  ranking: number
}

export interface SetRanking {
  type: typeof RANKING_SET
  payload: Ranking
}

export interface RankingGlobalLoading {
  type: typeof RANKING_GLOBAL_LOADING
}

export interface RankingGlobalFail {
  type: typeof RANKING_GLOBAL_FAIL
}

export interface RankingGlobalSuccess {
  type: typeof RANKING_GLOBAL_SUCCESS
  payload: Ranking;
}

export interface RankingCommunityLoading {
  type: typeof RANKING_COMMUNITY_LOADING
}

export interface RankingCommunityFail {
  type: typeof RANKING_COMMUNITY_FAIL
}

export interface RankingCommunitySuccess {
  type: typeof RANKING_COMMUNITY_SUCCESS
  payload: Ranking
}

export type RankingDispatchTypes =
  | RankingGlobalLoading
  | RankingGlobalFail
  | RankingGlobalSuccess
  | RankingCommunityLoading
  | RankingCommunityFail
  | RankingCommunitySuccess

export type SetRankingDispatch = SetRanking | Ranking