import React from 'react'
import { ListGroup } from 'reactstrap'
import { Player } from 'src/store'
import RankingListHeader from './RankingListHeader'
import RankingPlayerItem from './RankingPlayerItem'

interface RankingListProps {
  players: Player[]
  currentUser?: Player
}

const RankingList = ({ players, currentUser }: RankingListProps) => (
  <ListGroup flush={true}>
    <li className="list-group-item">
      <RankingListHeader />
    </li>
    {players.map(player => (
      <li key={player.id} className={`list-group-item ${player.id === currentUser?.id && 'active'}`}>
        <RankingPlayerItem player={player} />
      </li>
    ))}
  </ListGroup>
)

export default RankingList
