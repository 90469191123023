import React from 'react'
import { Row, Col, Card } from 'reactstrap'
import CountUp from 'react-countup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUser } from 'src/hooks/useUser'
import { useRanking } from 'src/hooks/useRanking'
import podio from '../assets/images/ranking-star-solid.svg'
import hike from '../assets/images/person-hiking-solid.svg'

function UserStatistics() {
  const totalFunds: number = 3
  const stats: any = useUser()?.stats
  const pos: any = useRanking().rankingGlobal?.user_ranking.ranking

  return (
    stats &&
    pos && (
      <>
        <Row>
          <Col lg="4">
            <Card className="card-box mb-2 p-4">
              {/* <div className="card-tr-actions">
              <Button className="p-0 d-20 mr-1 btn-transition-none opacity-6" color="link">
                <FontAwesomeIcon icon={['far', 'heart']} className="font-size-sm" />
              </Button>
              <Button className="p-0 d-20 btn-transition-none opacity-6" color="link">
                <FontAwesomeIcon icon={['fas', 'user']} className="font-size-sm" />
              </Button>
            </div>*/}
              <div className="d-flex align-items-center justify-content-between py-0">
                <div className="d-50 rounded-circle border-0 my-0 card-icon-wrapper bg-success text-white btn-icon text-center">
                  <FontAwesomeIcon icon={['fas', 'check']} className="font-size-xxl pt-2" />
                </div>
                {/* <div className="d-flex align-items-center">
                <div className="badge rounded-circle badge-success d-20 btn-icon p-0 mr-0 mr-xl-2">
                  <FontAwesomeIcon icon={['fas', 'check']} className="font-size-sm" />
                </div>
                <span className="font-size-xs text-dark d-none d-xl-block">Partidas Completadas</span>
               </div>*/}
              </div>
              <div className="font-size-xl font-weight-bold pt-2 text-black">Partidas Jugadas</div>
              {/* <div className="opacity-7 font-size-lg font-weight-bold">Todays analytics</div> */}
              <div className="d-flex display-4 text-black mb-0 font-weight-bold opacity-6">
                {totalFunds !== 0 && (
                  <CountUp
                    start={0}
                    end={stats.games}
                    duration={1}
                    separator=","
                    decimals={0}
                    decimal="."
                    prefix="#"
                    suffix=""
                  />
                )}
              </div>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-box mb-4 p-4">
              {/* <div className="card-tr-actions">
              <Button className="p-0 d-20 mr-1 btn-transition-none opacity-6" color="link">
                <FontAwesomeIcon icon={['far', 'heart']} className="font-size-sm" />
              </Button>
              <Button className="p-0 d-20 btn-transition-none opacity-6" color="link">
                <FontAwesomeIcon icon={['fas', 'user']} className="font-size-sm" />
              </Button>
          </div>*/}
              <div className="d-flex align-items-center justify-content-between py-0">
                <div className="d-50 rounded-circle border-0 my-0 card-icon-wrapper bg-production text-white btn-icon text-center">
                  <img src={podio} alt="Podio" width="35" height="35" className="mb-1" color="white" />
                </div>
                {/* <div className="d-flex align-items-center">
                <div className="badge rounded-circle badge-success d-20 btn-icon p-0 mr-0 mr-xl-2">
                  <FontAwesomeIcon icon={['fas', 'check']} className="font-size-sm" />
                </div>
                <span className="font-size-xs text-dark d-none d-xl-block">Target achieved</span>
              </div>*/}
              </div>
              <div className="font-size-xl font-weight-bold pt-2 text-black">Mejor Desempeño</div>
              {/* <div className="opacity-7 font-size-lg font-weight-bold">Successful orders</div>*/}
              <div className="d-flex display-4 text-black mb-0 font-weight-bold opacity-6">
                {totalFunds !== 0 && (
                  <CountUp
                    start={0}
                    end={stats.user_best_position}
                    duration={1}
                    separator=","
                    decimals={0}
                    decimal="."
                    prefix="#"
                    suffix=""
                  />
                )}
              </div>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-box mb-4 p-4">
              {/* <div className="card-tr-actions">
              <Button className="p-0 d-20 mr-1 btn-transition-none opacity-6" color="link">
                <FontAwesomeIcon icon={['far', 'heart']} className="font-size-sm" />
              </Button>
              <Button className="p-0 d-20 btn-transition-none opacity-6" color="link">
                <FontAwesomeIcon icon={['fas', 'user']} className="font-size-sm" />
              </Button>
   ]        </div>*/}
              <div className="d-flex align-items-center justify-content-between py-0">
                <div className="d-50 rounded-circle border-0 my-0 card-icon-wrapper bg-warning text-white btn-icon text-center">
                  {/* <FontAwesomeIcon icon={['fas', 'fas-person-hiking']} className="font-size-xl" />*/}
                  <img src={hike} alt="Hike" width="35" height="35" className="mb-1" color="white" />
                </div>
                {/* <div className="d-flex align-items-center">
                <div className="badge rounded-circle badge-danger d-20 btn-icon p-0 mr-0 mr-xl-2">
                  <FontAwesomeIcon icon={['fas', 'times']} className="font-size-sm" />
                </div>
                <span className="font-size-xs text-danger d-none d-xl-block">Target failed</span>
              </div>*/}
              </div>
              <div className="font-size-xl font-weight-bold pt-2 text-black">Nivel de Experiencia</div>
              <div className="opacity-7 font-weight-bold font-size-xl">{stats.league.toUpperCase()}</div>
            </Card>
          </Col>
        </Row>
        <Card className="card-box mb-4">
          <Row className="no-gutters">
            <Col xl="4" className="pt-4 pr-4 pl-4 pb-4">
              <div className="divider-v divider-v-lg" />
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <b>Posición en Ranking Global</b>
                  <div className="text-black-50">Tu posición actual</div>
                </div>
                <div className="font-weight-bold text-success font-size-xl">
                  <CountUp
                    start={0}
                    end={pos}
                    prefix="#"
                    duration={3}
                    delay={2}
                    separator=""
                    decimals={0}
                    decimal=""
                  />
                </div>
              </div>
            </Col>
            <Col xl="4" className="p-4">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <b>Puntos en Ranking Global</b>
                  <div className="text-black-50">Puntos en total</div>
                </div>
                <div className="font-weight-bold text-success font-size-xl">
                  <CountUp
                    start={0}
                    end={stats.ranking_points.toFixed(2)}
                    duration={6}
                    delay={2}
                    separator=""
                    decimals={3}
                    decimal=","
                  />
                </div>
              </div>
              <div className="divider-v divider-v-lg" />
            </Col>
            <Col xl="4" className="p-4">
              <div className="divider-v divider-v-lg" />
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <b>Posición Promedio Global</b>
                  <div className="text-black-50">promediando todas tus partidas</div>
                </div>
                <div className="font-weight-bold text-success font-size-xl">
                  {stats.historic_position_avg.toFixed(2)}
                </div>
              </div>
              <div className="divider-v divider-v-lg" />
            </Col>
          </Row>
        </Card>
      </>
    )
  )
}

export { UserStatistics }
