import { UserCommunity } from '../types'
import { setCommunityActions, SetCommunity } from '../types/community/setCommunity'

function setCommunity(community: UserCommunity): SetCommunity {
  return {
    type: setCommunityActions.SET_COMMUNITY,
    payload: {
      userCommunity: community
    }
  }
}

export { setCommunity, setCommunityActions }