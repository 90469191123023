/* eslint-disable no-negated-condition */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from 'react'
import { FlagIcon, FlagIconCode } from 'react-flag-kit'
import { useTranslation } from 'react-i18next'
// import { FlagIcon } from 'react-flag-kit'
import { useUser } from 'src/hooks/useUser'
import useWindowWidth from 'src/hooks/useWindowWidth'
import { Ceo } from 'src/store'
import { images } from 'src/utils/companyLogos'
import { CeoComparison, CeosData } from 'src/utils/dataMatchObj'
import { ceoPosition, compareCeosPropByStage } from 'src/utils/dataMatchServices'
import { useMatchInfo } from 'src/views/Dashboard/hooks/useMatchInfo'

interface Props {
  isAdmin: boolean
  matchType: string
  matchIndustryFormated: String
  industry: String
  match: any
  pageTitleIconBox: boolean
}

const HeaderInfo: FC<Props> = ({
  isAdmin,
  matchType,
  matchIndustryFormated,
  industry,
  match,
  pageTitleIconBox
}: Props) => {
  const id = useUser()?.id
  const ceos = useMatchInfo().matchInfo?.data?.ceos
  const [ceosData, setCeosData] = useState<Array<CeoComparison>>([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [screenSizeMobile, setScreenSizeMobile] = useState(false)
  const isMobile = useWindowWidth() <= 750
  const { t } = useTranslation()

  const checkCountryCode = (code: FlagIconCode): FlagIconCode => {
    switch (code) {
      case 'CH':
        return 'CN'
      case 'ME':
        return 'MX'
      case 'BR':
        return 'BR'
      case 'US':
        return 'US'
      case 'CO':
        return 'CO'
      case 'AR':
        return 'AR'
      default:
        return 'UY'
    }
  }

  useEffect(() => {
    setIsLoaded(true)
    const earnings = 'earnings'
    const data: CeosData = { ceos: [] }

    data.ceos = ceos
    const comparisons: CeoComparison[] = compareCeosPropByStage(
      { ceos: data?.ceos },
      earnings,
      match?.lastResultsStage as number
    )

    setScreenSizeMobile(isMobile)
    setCeosData(comparisons)
  }, [match, ceos, isMobile])

  const cname: Ceo[] | undefined = ceos && ceos.filter((ceo: Ceo) => Number(ceo.id) === Number(id))

  return (
    match &&
    ceosData.length !== 0 && (
      <div className="mb-1" style={{ marginLeft: screenSizeMobile ? '10px' : '20px' }}>
        <div
          className="app-page-title-reduced-match--first"
          style={{ marginBottom: screenSizeMobile && !isAdmin ? '-1.5rem' : '' }}>
          {pageTitleIconBox && !isAdmin && (
            <div
              className={`app-page-title-reduced-match--iconbox d-80 ${screenSizeMobile ? 'mr-2' : 'mr-4'}`}>
              <div className="d-80 d-flex gradient-icon align-items-center justify-content-center">
                {/* <i className="pe-7s-umbrella display-3 gradient-icon bg-ripe-malin" /> */}
                {/* <span className="mr-2" style={{ marginLeft: '3px' }}>
                  <FlagIcon code="UY" size={94} />
                </span>*/}
                <img
                  className="p-1"
                  src={images[`../assets/images/company-logos/${industry}/${cname && cname[0].avatar}.png`]}
                  alt=""
                  width="80"
                  height="70"
                />
              </div>
            </div>
          )}
          <div className="app-page-title--heading">
            <h1>{`${match?.name} | ${matchIndustryFormated}`}</h1>
            {!screenSizeMobile && (
              <div className="app-page-title--description" style={{ opacity: '1' }}>
                <span
                  className="m-1 text-primary badge badge-neutral-primary badge-pill"
                  style={{ marginRight: '20px' }}>
                  {matchType === 'custom' ? (
                    matchType
                  ) : matchType === 'country' ? (
                    <div>
                      <span style={{ marginRight: '6px' }}>CONQUEROR:</span>
                      <FlagIcon
                        className="pr-0 pb-0"
                        size={20}
                        code={checkCountryCode(
                          match.gameParameters.country.substring(0, 2).toUpperCase() as FlagIconCode
                        )}
                      />
                    </div>
                  ) : (
                    `${t(`page.create_match.scenario.${match?.gameParameters.scenario}`)}`
                  )}
                </span>
                <span
                  className="m-1 text-primary badge badge-neutral-primary badge-pill"
                  style={{ marginRight: '20px' }}>
                  {`Nivel: ${match?.gameParameters.proficiency_rate.substr(12)}`}
                </span>
                <span
                  className="m-1 text-primary badge badge-neutral-primary badge-pill"
                  style={{ marginRight: '20px' }}>
                  {match?.createdAt.substr(0, 10)}
                </span>
                <span
                  className="m-1 text-info badge badge-neutral-info badge-pill"
                  style={{ marginRight: '20px' }}>
                  {`Turno: ${match?.currentStage}/${match?.stages}`}
                </span>
                {!isAdmin && (
                  <span
                    className="m-1 text-primary badge badge-neutral-primary badge-pill"
                    style={{ marginRight: '20px' }}>
                    {`Posición: ${ceoPosition(isLoaded, ceosData, Number(id))}`}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        {screenSizeMobile && (
          <div className="app-page-title--description mb-2" style={{ opacity: '1' }}>
            <span
              className="mt-1 mb-1 ml-0 mr-0 text-primary badge badge-neutral-primary badge-pill"
              style={{ marginRight: '20px' }}>
              {matchType !== 'country' ? (
                matchType
              ) : (
                <div>
                  <span style={{ marginRight: '6px' }}>CONQUEROR:</span>
                  <FlagIcon
                    className="pr-0 pb-0"
                    size={20}
                    code={checkCountryCode(
                      match.gameParameters.country.substring(0, 2).toUpperCase() as FlagIconCode
                    )}
                  />
                </div>
              )}
            </span>
            <span
              className="mt-1 mb-1 ml-1 mr-0 text-primary badge badge-neutral-primary badge-pill"
              style={{ marginRight: '20px' }}>
              {`${match?.gameParameters.proficiency_rate.substr(12)}`}
            </span>
            <span
              className="mt-1 mb-1 ml-1 mr-0 text-primary badge badge-neutral-primary badge-pill"
              style={{ marginRight: '20px' }}>
              {match?.createdAt.substr(0, 7)}
            </span>
            <span
              className="mt-1 mb-1 ml-1 mr-0 text-info badge badge-neutral-info badge-pill"
              style={{ marginRight: '20px' }}>
              {`Turno: ${match?.currentStage}/${match?.stages}`}
            </span>
            {!isAdmin && (
              <span
                className="mt-1 mb-1 ml-0 mr-0 text-primary badge badge-neutral-primary badge-pill"
                style={{ marginRight: '20px' }}>
                {`Posición: ${ceoPosition(isLoaded, ceosData, Number(id))}`}
              </span>
            )}
          </div>
        )}
      </div>
    )
  )
}

export default HeaderInfo
