export const UPLOAD_USER_AVATAR_LOADING = 'UPLOAD_USER_AVATAR_LOADING'
export const UPLOAD_USER_AVATAR_FAIL = 'UPLOAD_USER_AVATAR_FAIL'
export const UPLOAD_USER_AVATAR_SUCCESS = 'UPLOAD_USER_AVATAR_SUCCESS'
export const GET_USER_AVATAR_LOADING = 'GET_USER_AVATAR_LOADING'
export const GET_USER_AVATAR_FAIL = 'GET_USER_AVATAR_FAIL'
export const GET_USER_AVATAR_SUCCESS = 'GET_USER_AVATAR_SUCCESS'

export interface UploadUserAvatarLoading {
  type: typeof UPLOAD_USER_AVATAR_LOADING
}

export interface UploadUserAvatarFail {
  type: typeof UPLOAD_USER_AVATAR_FAIL
}

export interface UploadUserAvatarSuccess {
  type: typeof UPLOAD_USER_AVATAR_SUCCESS
  payload: string
}

export interface GetUserAvatarLoading {
  type: typeof GET_USER_AVATAR_LOADING
}

export interface GetUserAvatarFail {
  type: typeof GET_USER_AVATAR_FAIL
}

export interface GetUserAvatarSuccess {
  type: typeof GET_USER_AVATAR_SUCCESS
}

export interface PostImageBody {
  file: File
  type: 'avatar' | 'community' | 'match' | 'user'
  id: string
}

export type UploadUserAvatarDispatchTypes = UploadUserAvatarLoading | UploadUserAvatarFail | UploadUserAvatarSuccess
export type GetUserAvatarDispatchTypes = GetUserAvatarLoading | GetUserAvatarFail | GetUserAvatarSuccess