import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Card } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { setSwitchBackground } from 'src/store/actions/theme'
import { useMatchParameters } from 'src/views/CreateMatch/hooks/useCreateMatch'
import { WorldMap } from '../WorldMap/WorldMap'
import { CountriesTab } from '../Tables/Countries/Countries'
import classes from './index.module.scss'
import { BackButton } from '../Button/BackButton'

export interface MapControllerProps {
  selectCountry?: (key: string) => void
  onClickHandler?: () => void
  readOnly: boolean
}

function MapController(props: MapControllerProps) {
  const { selectCountry, onClickHandler, readOnly } = props
  const matchParams = useMatchParameters()

  const countries = (matchParams?.country?.options as string[]) ?? []

  const [selectedCountry, setSelectedCountry] = useState<string>()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setSwitchBackground(true))
  }, [dispatch])

  // TODO: refactor function
  const onCountrySelected = (countryKey: string) => {
    if (countries.indexOf(countryKey) >= 0) {
      setSelectedCountry(countryKey)
      if (selectCountry) {
        selectCountry(countryKey)
      }
    }
  }

  return (
    <div className={classes.container}>
      <h3 className="mb-3">
        {readOnly
          ? t('component.map_controller.steps.map.title')
          : t('component.map_controller.steps.map.title_creatematch')}
      </h3>
      <Card className="card-box mb-1 p-4" style={{ backgroundColor: 'rgba(179, 219, 188, 1)' }}>
        <WorldMap onCountrySelected={onCountrySelected} availableCountries={countries} />
      </Card>
      {!readOnly && (
        <>
          <CountriesTab selectedCountry={selectedCountry} />
          <div className="mt-3">
            <BackButton />
            <Button onClick={onClickHandler}>{`${t('page.create_match.buttonSelectCountry')}`}</Button>
          </div>
        </>
      )}
    </div>
  )
}

export { MapController }
