import React, { useEffect, useState } from 'react'
// TEMPORARILY COMMENTED UNTIL WE INVESTIGATE THE ISSUE WITH JoyRide Component

import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS // Step
} from 'react-joyride'
import { useTranslation } from 'react-i18next'
import { usePathname } from 'src/hooks/usePathname'
import { config } from '../../config'
import { setInStorage, getFromStorage } from '../../utils/localStorage'
import sherpa from '../../assets/images/react.svg'
import { JoyrideState, OnboardingLocalStorage, OnboardingProps } from './types'

const defaultStyles = {
  options: {
    zIndex: 2000
  },
  beaconInner: {
    borderRadius: 0,
    backgroundColor: 'none',
    border: 0
  },
  beaconOuter: {
    borderRadius: 0,
    backgroundImage: `url(${sherpa})`,
    backgroundColor: 'none',
    border: 0
  },
  buttonNext: {
    backgroundColor: '#502a91'
  },
  buttonBack: {
    color: '#502a91'
  }
}

const key: string = config.localStorage.keys.onboarding

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Onboarding = (props: OnboardingProps) => {
  const localStorage: OnboardingLocalStorage | null = getFromStorage(key)
  const pageName: string = usePathname()

  const { steps } = props

  const { t } = useTranslation()

  const [joyrideState, setJoyrideState] = useState<JoyrideState>({
    run: false,
    stepIndex: 0
  })

  const controls = {
    back: t('page.account.onboarding.controls.back'),
    close: t('page.account.onboarding.controls.close'),
    last: t('page.account.onboarding.controls.last'),
    next: t('page.account.onboarding.controls.next'),
    open: t('page.account.onboarding.controls.open'),
    skip: t('page.account.onboarding.controls.skip')
  }

  useEffect(() => {
    setJoyrideState(joyrideState => ({
      ...joyrideState,
      run: true
    }))
  }, [])

  const handleJoyrideCallback = (data: CallBackProps) => {
    const {
      action, type, status, index
    } = data

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      setJoyrideState({
        run: false,
        stepIndex: 0
      })
      if (localStorage) {
        setInStorage(key, {
          ...localStorage,
          [pageName]: { status: data.status, step: index + 1 }
        })
      } else {
        setInStorage(key, {
          [pageName]: { status: data.status, step: index + 1 }
        })
      }
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      if (localStorage && localStorage[pageName]?.status === 'skipped') {
        setInStorage(key, {
          ...localStorage,
          [pageName]: { ...localStorage[pageName], status: 'running' }
        })

        return
      }

      const stepIndex = joyrideState && joyrideState?.stepIndex + (action === ACTIONS.PREV ? -1 : 1)

      setJoyrideState({
        run: true,
        stepIndex
      })

      if (localStorage) {
        setInStorage(key, {
          ...localStorage,
          [pageName]: { status: data.status, step: index + 1 }
        })
      } else {
        setInStorage(key, {
          [pageName]: { status: data.status, step: index + 1 }
        })
      }
    } else if (localStorage && localStorage[pageName]?.status === 'skipped') {
      setJoyrideState(joyrideState => ({
        ...joyrideState,
        stepIndex: localStorage[pageName].step
      }))
    } else if (action === 'start' && localStorage && localStorage[pageName]?.step) {
      setJoyrideState(joyrideState => ({
        ...joyrideState,
        stepIndex: localStorage[pageName].step
      }))
      setInStorage(key, {
        [pageName]: { ...localStorage[pageName], status: action }
      })
    } else if (action === 'close' && localStorage && localStorage[pageName]?.step) {
      setInStorage(key, {
        [pageName]: { ...localStorage[pageName], step: index - 1 }
      })
    }
  }

  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        steps={steps}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        locale={controls}
        styles={defaultStyles}
        stepIndex={joyrideState.stepIndex}
        run={joyrideState?.run}
      />
    </>
  )
}

export { Onboarding }
