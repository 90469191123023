'use strict'

import { Ceo, MatchInfo } from 'src/store'
import { NegativeEvent } from 'src/layoutComponents/Tabs/AdvisersTips/HazardousEvent'
import { CeoComparison } from './dataMatchObj'

const getCeoResults = (userId: any, data?: MatchInfo) => {
  const ceo = data?.ceos?.find((ceo: any) => ceo.id === userId)

  return ceo?.pivot.results
}

const getUserProp = (userId: any, prop: string, data: any, stage?: number): number | string | undefined | NegativeEvent => {
  const ceoResults = getCeoResults(userId, data)
  const stages = ceoResults ? Object.keys(ceoResults) : undefined

  if (ceoResults && stage !== undefined) {
    const stageKey = `stage_${stage}`
    const value = ceoResults[stageKey][prop]

    return value
  }

  if (ceoResults && stages) {
    const stages = Object.keys(ceoResults)
    const latestStage = stages[stages.length - 1]
    const value = ceoResults[latestStage][prop]

    return value
  }

  return undefined
}

type GetUserPropOptions = {
  userId: string
  prop: string
  data: MatchInfo
}

const getUserPropAllStages = (options: GetUserPropOptions): number[] | undefined => {
  const { userId, prop, data } = options
  const ceoResults = getCeoResults(userId, data)
  const propData: number[] = []

  if (ceoResults) {
    for (const key of Object.keys(ceoResults)) {
      propData.push(ceoResults[key][prop])
    }

    return propData
  }

  return undefined
}

const getUserPropByStage = (userId: any, prop: string, data: any, stage: number): number | string | undefined => {
  const ceo = data.ceos.find((ceo: any) => ceo.id === userId)

  if (!ceo) {
    return undefined
  }
  const stages = Object.keys(ceo.pivot.results)

  if (stages.length === 0 || stage < 0) {
    return undefined
  }

  const stageName = stages[stage]
  const value = ceo.pivot.results[stageName][prop]

  return value
}

const compareCeosPropByStage = (
  data: { ceos?: Ceo[] },
  property: any,
  stage: number
): Array<CeoComparison> => {
  const { ceos } = data
  const comparisons: Array<CeoComparison> = []

  for (const [ind, ceo] of ceos?.entries() || []) {
    const index = ind
    const { id } = ceo
    const stageKey = `stage_${stage}`
    const ceoName = `${ceo.name} ${ceo.lastName}`
    const ceoAvatar = ceo.avatar
    const { companyName } = ceo

    const ceoProp:any = ceo.pivot.results[stageKey] ? ceo.pivot.results[stageKey][property] : 0
    const ceoPropPreviousRound:any = ceo.pivot.results[`stage_${stage - 1}`] ? ceo.pivot.results[`stage_${stage - 1}`][property] : 0

    comparisons.push({
      index,
      id,
      ceoName,
      ceoAvatar,
      ceoProp,
      ceoPropPreviousRound,
      companyName,
    })
  }
  return comparisons
}

function getMatchParameters(userId: any, prop: string, gameParameters: any): any | null {
  const matchProp: any = gameParameters[prop]

  return matchProp
}

function getMatchGlobalProperty(userId: any, prop: string, stage: any, matchData: any): any | null {
  const stageName = `stage_${stage}`
  let matchProp: any = matchData.results[stageName][prop]

  // fallback
  if (matchProp === undefined) {
    const ceo = matchData.ceos?.find((ceo: any) => ceo.id === userId)

    matchProp = ceo.pivot.results[stageName][prop]
  }

  return matchProp
}

// TODO: REVISAR ALGUNOS CHARTS DE INDUSTRIA LOOPEAN CON ESTA FUNCIÓN
function compareMatchPropByStages(userId: any, prop: string, matchData: any) {
  let index: number = 0
  const propAcrossStages = []
  const stages = Object.keys(matchData.results)

  while (index < stages.length) {
    propAcrossStages.push(getMatchGlobalProperty(userId, prop, index, matchData))
    index++
  }
  return propAcrossStages
}

const findMatchLeader = (ceos: CeoComparison[]) => {
  let leaderId = ceos[0].id
  let leaderProp = ceos[0].ceoProp

  for (let i = 0; i < ceos.length; i++) {
    if (ceos[i].ceoProp > leaderProp) {
      leaderId = ceos[i].id
      leaderProp = ceos[i].ceoProp
    }
  }

  return leaderId
}

const findMaxPropCeo = (ceos: CeoComparison[]) => {
  let maxPropCeo = ceos[0]
  let leaderProp = ceos[0].ceoProp

  for (let i = 0; i < ceos.length; i++) {
    if (ceos[i].ceoProp > leaderProp) {
      maxPropCeo = ceos[i]
      leaderProp = ceos[i].ceoProp
    }
  }

  return maxPropCeo
}

const findMatchMinPropCeo = (ceos: CeoComparison[]) => {
  let minCeoId = ceos[0].id
  let prop = ceos[0].ceoProp

  for (let i = 0; i < ceos.length; i++) {
    if (ceos[i].ceoProp <= prop) {
      minCeoId = ceos[i].id
      prop = ceos[i].ceoProp
    }
  }

  return minCeoId
}

const findMinPropCeo = (ceos: CeoComparison[]) => {
  let minPropCeo = ceos[0]
  let prop = ceos[0].ceoProp

  for (let i = 0; i < ceos.length; i++) {
    if (ceos[i].ceoProp <= prop) {
      minPropCeo = ceos[i]
      prop = ceos[i].ceoProp
    }
  }

  return minPropCeo
}

const enhanceDataForChart = (currentProps: number[], stagesAmount: number) => {
  const realData: number[] = currentProps.concat()
  const dataPositionsToFake = stagesAmount - currentProps.length

  for (let i: number = 0; i < dataPositionsToFake; i++) {
    realData.push(0)
  }

  return realData
}

const returnChartLabels = (roundsCount: number, initialRoundLabel: string, stageLabel: string): Array<string> => {
  let index: number = 0
  const labels: any[] = [initialRoundLabel]

  while (index < roundsCount) {
    labels.push(`${stageLabel} ${index + 1}`)
    index++
  }

  return labels
}

const generateChartColors = (
  colorsObj: {
    readonly [key: string]: string;
  },
  department: string,
  props: number
) => {
  const colors: string[] = []

  for (let i = 0; i < props; i++) {
    const limit = 3
    let serie = i

    if (i > 2) {
      serie = i % limit
    }
    const variation = 100 - (30 * serie)

    const color = colorsObj[`${department}_${variation}`]

    colors.push(color)
  }

  return colors
}

const ceoPosition = (loaded: boolean, data: CeoComparison[], ceoId: number): number => {
  function findCeo(item: CeoComparison) {
    return item.id === ceoId
  }
  if (loaded) {
    const pos =
      data
        .sort((a: CeoComparison, b: CeoComparison) => (a.ceoProp > b.ceoProp ? -1 : 1))
        .findIndex(findCeo) + 1

    return pos
  }
  return 0
}

export { getUserProp }
export { getUserPropByStage }
export { compareCeosPropByStage }
export { getMatchGlobalProperty }
export { compareMatchPropByStages }
export { findMatchLeader }
export { enhanceDataForChart }
export { returnChartLabels }
export { generateChartColors }
export { getCeoResults }
export { findMatchMinPropCeo }
export { getUserPropAllStages }
export { findMaxPropCeo }
export { findMinPropCeo }
export { ceoPosition }
export { getMatchParameters }