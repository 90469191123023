import React, { lazy } from 'react'
import {
  Switch, Route, Redirect, useLocation, useRouteMatch, useHistory
} from 'react-router-dom'
import { PagesError404 } from 'src/views/PagesError404'
import Goals from 'src/views/Goals/Goals'
import { Loader, FadeinTransition } from 'src/components'
import { GlobalDataConsumer } from 'src/components/GlobalDataConsumer'
import EndGame from 'src/views/EndGame/EndGame'
import useHistoryParamsSetter from 'src/hooks/useHistoryParamsSetter'
import {
  LeftSidebar, MinimalLayout, SimpleLayout, FullLayout
} from '../layoutBlueprints'
import { PrivateRoute } from './components/PrivateRoute'
import { LoginRoute } from './components/LoginRoute'

const Home = lazy(() => import('../views/Home'))
const Login = lazy(() => import('../views/Login'))
const CreateMatch = lazy(() => import('../views/CreateMatch'))
const CreateRoundDecisions = lazy(() => import('../views/CreateRoundDecisions'))
const Settings = lazy(() => import('../views/Settings'))
const Account = lazy(() => import('../views/Account/Account'))
const Community = lazy(() => import('../views/Community'))
const Dashboard = lazy(() => import('../views/Dashboard/Dashboard'))
const Bankrupt = lazy(() => import('../views/Bankrupt/Bankrupt'))
const EndRound = lazy(() => import('../views/EndRound'))
const Government = lazy(() => import('../views/Government'))
const Help = lazy(() => import('../views/Help'))
const Ranking = lazy(() => import('../views/Ranking/Ranking'))
const MarketAndCompetence = lazy(() => import('../views/MarketAndCompetence'))
const Register = lazy(() => import('../views/Register'))
const Simulator = lazy(() => import('../views/Simulator'))

function BaseRouter() {
  useHistoryParamsSetter()
  const location = useLocation()
  const { url } = useRouteMatch()
  const history = useHistory()

  const redirectToHome = () => {
    history.replace(url)
  }

  return (
    <Switch>
      <Redirect exact={true} from="/" to="/home" />
      <LoginRoute path="/login">
        <Login onLoginSuccess={redirectToHome} />
      </LoginRoute>
      <LoginRoute path="/register">
        <Register onRegisterSuccess={redirectToHome} />
      </LoginRoute>
      <Route path={['/pages-error-404']}>
        <MinimalLayout>
          <Switch location={location} key={location.pathname}>
            <FadeinTransition>
              <Route path="/pages-error-404" component={PagesError404} />
            </FadeinTransition>
          </Switch>
        </MinimalLayout>
      </Route>
      <Route
        path={[
          '/home',
          '/settings',
          '/account',
          '/create-match',
          '/community',
          '/end-round',
          '/government/:id/create-round-decisions',
          '/dashboard/:id/end-round',
          '/help',
          '/ranking'
        ]}>
        <LeftSidebar>
          <GlobalDataConsumer fallback={<Loader />}>
            <Switch location={location} key={location.pathname}>
              <FadeinTransition>
                <PrivateRoute path="/home" component={Home} />
                <PrivateRoute path="/create-match" component={CreateMatch} />
                <PrivateRoute path="/settings" component={Settings} />
                <PrivateRoute path="/account" component={Account} />
                <PrivateRoute path="/community" component={Community} />
                <PrivateRoute exact={true} path="/dashboard/:id/end-round" component={EndRound} />
                <PrivateRoute
                  path="/government/:id/create-round-decisions"
                  component={CreateRoundDecisions}
                />
                <PrivateRoute path="/help" component={Help} />
                <PrivateRoute path="/ranking" component={Ranking} />
              </FadeinTransition>
            </Switch>
          </GlobalDataConsumer>
        </LeftSidebar>
      </Route>
      <Route path={['/government', '/dashboard/:id/simulator']}>
        <LeftSidebar>
          <GlobalDataConsumer fallback={<Loader />}>
            <FullLayout>
              <Switch location={location} key={location.pathname}>
                <FadeinTransition>
                  <Route exact={true} path="/dashboard/:id/simulator" component={Simulator} />
                  <PrivateRoute path="/government/:id" component={Government} />
                </FadeinTransition>
              </Switch>
            </FullLayout>
          </GlobalDataConsumer>
        </LeftSidebar>
      </Route>
      <Route
        path={[
          '/dashboard',
          '/dashboard/goals',
          '/bankrupt',
          '/endgame',
          // '/endround',
          '/market-and-competence',
          '/dashboard/simulator',
          '/dashboard/goals/simulator'
        ]}>
        <LeftSidebar>
          <GlobalDataConsumer fallback={<Loader />}>
            <SimpleLayout>
              <Switch location={location} key={location.pathname}>
                <FadeinTransition>
                  {/* Todo: subrouter should be anidated on a deeper router */}

                  {/* <Route exact={true} path="/bankrupt/:id" component={Bankrupt} /> */}
                  {/* <Route exact={true} path="/:id/bankrupt" component={Bankrupt} />*/}
                  <Route exact={true} path="/dashboard/:id/goals" component={Goals} />
                  <Route exact={true} path="/dashboard/:id" component={Dashboard} />
                  <Route exact={true} path="/bankrupt/:id" component={Bankrupt} />
                  <Route exact={true} path="/bankrupt/:id/goals" component={Goals} />
                  <Route exact={true} path="/bankrupt/:id/dashboard" component={Dashboard} />
                  <Route exact={true} path="/endgame/:id" component={EndGame} />
                  <Route exact={true} path="/endgame/:id/goals" component={Goals} />
                  <Route exact={true} path="/endgame/:id/dashboard" component={Dashboard} />
                  {/* <Route exact={true} path="/endround/:id" component={EndGame} />
                  <Route exact={true} path="/endround/:id/goals" component={Goals} />
                  <Route exact={true} path="/endround/:id/dashboard" component={Dashboard} />*/}
                  <Route path="/market-and-competence" component={MarketAndCompetence} />
                </FadeinTransition>
              </Switch>
            </SimpleLayout>
          </GlobalDataConsumer>
        </LeftSidebar>
      </Route>
      <Route path="*">
        <Redirect to="/home" />
      </Route>
    </Switch>
  )
}

export { BaseRouter }
