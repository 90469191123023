import { config } from 'src/config'
import { State } from 'src/store'

const storeKey = config.localStorage.keys.store

function setInStorage(key: string, value: any) {
  window.localStorage.setItem(key, JSON.stringify(value))
}

function getFromStorage<T>(key: string): T | null {
  const res = window.localStorage.getItem(key)

  if (!res) {
    return null
  }

  const parsedRes: T = JSON.parse(res)

  return parsedRes
}

function removeFromStorage(key: string) {
  window.localStorage.removeItem(key)
}

function loadStateFromStorage(): State | null {
  const storageState = getFromStorage<State>(storeKey)

  if (!storageState) return null
  return storageState as State
}

function saveStateInStorage<T>(state: T) {
  try {
    setInStorage(storeKey, state)
  } catch {
    throw new Error('Error saving in storage')
  }
}


export {
  setInStorage, getFromStorage, removeFromStorage, saveStateInStorage, loadStateFromStorage
}
