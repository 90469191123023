import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { getMatchInfo } from 'src/store'
import { Match } from 'src/store/actions/types'
import { MatchesList, MatchesListProps } from '../MatchesList/MatchesList'

function AdminMatchesList(props: Omit<MatchesListProps, 'title' | 'matchItemButtonText'>) {
  const { isLoading, matches, onMatchItemClick: onMatchItemClickBase } = props
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const redirectAdminToGovernment = (match: Match) => {
    history.push(`/government/${match.id}`)
    // dispatch(getPreview(match.id))
    dispatch(getMatchInfo(match.id))
  }

  const onMatchItemClick = (match: Match) => {
    const matchIsInitiated = match.current_stage > 0

    if (matchIsInitiated) {
      redirectAdminToGovernment(match)
    } else {
      onMatchItemClickBase(match)
    }
  }

  return (
    <MatchesList
      title={t('page.home.match_list.title')}
      matches={matches}
      isLoading={isLoading}
      matchItemButtonText={isMatchInitiated => isMatchInitiated
        ? t('page.home.match_list.match_item.button_audit')
        : t('page.home.match_list.match_item.button_invite')}
      onMatchItemClick={onMatchItemClick}
    />
  )
}

export { AdminMatchesList }
