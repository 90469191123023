import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSideBarMenu } from 'src/hooks/useSidebarMenu'
import { useUserIsLoading } from 'src/hooks/useUserIsLoading'
import CustomMenu from './CustomMenu'

const SidebarMenu = () => {
  const menuContent = useSideBarMenu()
  const userIsLoading = useUserIsLoading()
  const { t } = useTranslation()

  const memoizedMenu = useMemo(() => <CustomMenu menuContent={menuContent} />, [menuContent])

  return (
    <div className="sidebar-navigation">
      <div className="sidebar-header">
        <span>{t('layout.sidebar_menu.navigation_menu')}</span>
      </div>
      {!userIsLoading && memoizedMenu}
    </div>
  )
}

export { SidebarMenu }
