/* eslint-disable no-nested-ternary */
/* eslint-disable no-negated-condition */
import React, { useEffect, useState } from 'react'
import { Match } from 'src/store/actions/types'
import { Fadein } from 'src/components/FadeIn'
import { CustomButton } from 'src/components/CustomButton/CustomButton'
// import { useMatchPreview } from 'src/hooks/useMatchPreview'
import { useTranslation } from 'react-i18next'
import { FlagIcon, FlagIconCode } from 'react-flag-kit'
import useWindowWidth from 'src/hooks/useWindowWidth'
import { useUserCommunity } from 'src/features/communities'
import { DeleteMatchButton } from './DeleteMatchButton'
// import scenario from '../../../../../../../assets/images/composed-bg/endgame-lost.png'
import custom from '../../../../../../../assets/images/composed-bg/custom.jpg'
import classes from './MatchItem.module.scss'

import neutralEventImage from '../../../../../../../assets/images/composed-bg/Mision-canoa.jpg'
import negativeEventImage from '../../../../../../../assets/images/composed-bg/brujula.jpg'
import positiveEventImage from '../../../../../../../assets/images/composed-bg/mochila-custom.jpg'

interface MatchItemProps {
  index: number
  match: Match
  matchItemButtonText: (condition: boolean, condition2: boolean) => string
  onCallbackClick: (match: Match) => void
  onMatchDeletedSuccess?: (matchId: string) => void
}

function MatchItem(props: MatchItemProps) {
  const {
    match, index, matchItemButtonText, onCallbackClick, onMatchDeletedSuccess
  } = props
  const { t } = useTranslation()
  const [screenSizeMobile, setScreenSizeMobile] = useState(false)
  // const { isMatchPreviewLoading } = useMatchPreview()
  const initiatedGame = match.current_stage > 0
  const endedGame = match.status_id === 3

  const isMobile = useWindowWidth() <= 1200 // 750
  const { isAdmin } = useUserCommunity()

  useEffect(() => {
    setScreenSizeMobile(isMobile)
  }, [isMobile])

  // eslint-disable-next-line consistent-return
  const checkCountryCode = (code: FlagIconCode): FlagIconCode => {
    switch (code) {
      case 'CH':
        return 'CN'
      case 'ME':
        return 'MX'
      case 'BR':
        return 'BR'
      case 'US':
        return 'US'
      case 'CO':
        return 'CO'
      case 'AR':
        return 'AR'
      // case 'UR':
      // return 'UY'
      default:
        return 'UY'
    }
  }

  const scenario = (scenario: string) => {
    const value =
      scenario === 'stable_economy'
        ? neutralEventImage
        : scenario === 'recession_fiscal_adjustment'
          ? negativeEventImage
          : positiveEventImage

    return value
  }

  return (
    <Fadein delay={index * 0.25} mobile={screenSizeMobile}>
      <div className={`d-flex align-items-center mr-3 ${classes.fixWidth}`}>
        <div
          className={`avatar-icon mr-3 ${screenSizeMobile ? 'd-60' : 'd-80'}`}
          style={{ minWidth: screenSizeMobile ? 60 : 80 }}>
          {match.type !== 'country' ? (
            <img
              className="img-fit-container"
              alt="placeholder"
              // eslint-disable-next-line no-nested-ternary
              src={match.type === 'scenario' ? scenario(match.scenario) : custom}
            />
          ) : (
            <div className="align-box-row">
              <FlagIcon
                className="pr-3 pb-3"
                size={95}
                code={checkCountryCode(match.country.substring(0, 2).toUpperCase() as FlagIconCode)}
              />
            </div>
          )}
        </div>
        <div>
          <div className="font-weight-bold text-black">{match.name}</div>
          <div className="d-flex text-black-50 mx-0 py-1">
            <span style={{ marginRight: 50, minWidth: screenSizeMobile && isAdmin ? 100 : 140 }}>
              {match.type !== 'scenario'
                ? `${t('common.'.concat(match.type))} | `
                : `${t(`page.create_match.scenario.${match.scenario}`)} | `}

              {!endedGame
                ? `${t('common.stage')}: ${match.current_stage}/${match.stages}`
                : `${t('common.status.finished')}`}
            </span>
            {screenSizeMobile && (
              <>
                <CustomButton
                  clickHandler={_ => onCallbackClick(match)}
                  classColor={initiatedGame ? 'btn-sherpa-primary' : 'btn-sherpa-success'}
                  color={initiatedGame ? 'sherpa-primary' : 'sherpa-success'}
                  isInDynamicList={true}
                  isMobile={screenSizeMobile}>
                  {matchItemButtonText(match.current_stage > 0, match.status_id === 3)}
                </CustomButton>
                <DeleteMatchButton
                  matchId={match.id}
                  onMatchDeletedSuccess={onMatchDeletedSuccess}
                  paddingx={1}
                  endedGame={endedGame}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {!screenSizeMobile && (
        <>
          <CustomButton
            clickHandler={_ => onCallbackClick(match)}
            loadingCondition={false}
            classColor={initiatedGame ? 'btn-sherpa-primary' : 'btn-sherpa-success'}
            color={initiatedGame ? 'sherpa-primary' : 'sherpa-success'}
            isInDynamicList={true}
            isMobile={screenSizeMobile}>
            {matchItemButtonText(match.current_stage > 0, match.status_id === 3)}
          </CustomButton>
          {/* {isAdmin && (
        <Button onClick={() => onMatchDelete(match.id)} look="link" icon={true}>
          <TrashIcon />
        </Button>
      )} */}
          <DeleteMatchButton
            matchId={match.id}
            onMatchDeletedSuccess={onMatchDeletedSuccess}
            paddingx={3}
            endedGame={endedGame}
          />
        </>
      )}
    </Fadein>
  )
}
export { MatchItem }
