import React, { Component } from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import * as actionTypes from '../../store/actions'

class HeaderSearch extends Component {
  toggleHeaderSearchHover = () => {
    const { headerSearchHover, setHeaderSearchHover } = this.props

    setHeaderSearchHover(!headerSearchHover)
  }

  render() {
    const { headerSearchHover } = this.props

    return (
      <>
        <div className="header-search-wrapper">
          <div
            className={clsx('search-wrapper', {
              'is-active': headerSearchHover
            })}>
            <label className="icon-wrapper text-black" htmlFor="header-search-input">
              <FontAwesomeIcon icon={['fas', 'search']} />
            </label>
            <input
              onFocus={this.toggleHeaderSearchHover}
              onBlur={this.toggleHeaderSearchHover}
              className="form-control"
              id="header-search-input"
              name="header-search-input"
              placeholder="Search terms..."
              type="search"
            />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  headerSearchHover: state.theme.headerSearchHover
})

const mapDispatchToProps = dispatch => ({
  setHeaderSearchHover: enable => dispatch(actionTypes.setHeaderSearchHover(enable))
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSearch)
