import { useSelector } from 'react-redux'
import { State } from 'src/store'

function useNotifications() {
  const notifications = useSelector((state: State) => state?.notifications.notifications)

  const isLoading = useSelector((state: State) => state.notifications.loading)

  return { notifications, isLoading }
}


export { useNotifications }