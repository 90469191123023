import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  titleHeading: string
}

const PageTitleBreadcrumb: FC<Props> = ({ titleHeading }: Props) => (
  <ol className="app-page-title-reduced-match--breadcrumb breadcrumb">
    <li className="breadcrumb-item">
      <Link to="/">
        <FontAwesomeIcon icon={['fas', 'tachometer-alt']} />
      </Link>
    </li>
    <li className="breadcrumb-item">
      <Link to="/home">Dashboards</Link>
    </li>
    <li className="breadcrumb-item active">
      <span>{titleHeading}</span>
    </li>
  </ol>
)

export default PageTitleBreadcrumb
