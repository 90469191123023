import { toast, Flip } from 'react-toastify'
import { NotificationsDispatchTypes, Notification } from '../../actions/types/notification'
import * as actions from '../../actions/types/notification'


interface NotificationListState {
  loading: boolean,
  notifications?: Notification[]
}
const initialState: NotificationListState = {
  loading: true,
  notifications: []
}

const notificationListReducer = (
  state: NotificationListState = initialState,
  action: NotificationsDispatchTypes,
): NotificationListState => {
  switch (action.type) {
    case actions.SET_NOTIFICATIONS:
      return {
        loading: false,
        notifications: action.payload,
      }
    case actions.NOTIFICATIONS_FAIL:
      toast.error('There was a problem with notifications, please wait.', {
        containerId: 'A',
        transition: Flip,
      })
      return {
        loading: false,
      }
    case actions.NOTIFICATIONS_LOADING:
      return {
        loading: true,
      }
    case actions.NOTIFICATIONS_SUCCESS:
      return {
        loading: false,
        notifications: action.payload,
      }

    default:
      return state
  }
}

export { notificationListReducer }
