// TODO Abstract into component to avoid all linter exceptions all around
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { UncontrolledTooltip, Button } from 'reactstrap'
import { ConfirmationModal } from 'src/components/ConfirmationModal/ConfirmationModal'
import { useMatchInfo } from 'src/views/Dashboard/hooks/useMatchInfo'
import { useHistory } from 'react-router'
import { postCeoDecisions } from 'src/store/actions/ceoDecisions/ceoDecisions'
import { setHeaderDrawerToggle } from '../store/actions/theme'
import { CeoBalance, State } from '../store'
import ExecutiveCards from '../components/StatusCards/ExecutiveCards'
import hike from '../assets/images/person-hiking-solid.svg'

const HeaderDrawer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [decisionPost, setDecisionPost] = useState(false)
  const headerDrawerToggle = useSelector((state: State) => state.theme.headerDrawerToggle)
  const { data, schema } = useSelector((state: State) => state.ceoDecisions)
  const { approved } = data
  const { matchInfo } = useMatchInfo()
  const matchId = String(matchInfo.data?.matchId)
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false)

  const toggleSidebarMobile = () => {
    dispatch(setHeaderDrawerToggle(!headerDrawerToggle))
  }

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => {
    setIsModalOpen(false)
  }

  /* const endRoundHandleRedirect = useCallback(() => {
    dispatch(setHeaderDrawerToggle(false))

    history.push(`/dashboard/${matchId}/end-round`)
  }, [dispatch, history, matchId])*/

  useEffect(() => {
    let isActive = true
    const closeModalAndNavigate = async () => {
      if (headerDrawerToggle) {
        closeModal()
        await new Promise(resolve => setTimeout(resolve, 1300))
      }

      if (isActive) {
        history.push(`/dashboard/${matchId}/end-round`)
      }
    }

    if (!isModalOpen && decisionPost) {
      closeModalAndNavigate()
    }

    return () => {
      isActive = false // Prevents state updates if the component unmounts
    }
  }, [isModalOpen, decisionPost, history, matchId, headerDrawerToggle])

  const ceoBalance: CeoBalance = useMemo(
    () => ({
      finalCash: schema.final_cash?.value,
      financialDebt: schema.financial_debt?.value
    }),
    [schema]
  )

  const onConfirmation = async () => {
    setIsConfirmButtonDisabled(true)
    if (isModalOpen) {
      closeModal()
    }
    await dispatch(postCeoDecisions(data, ceoBalance))
    setDecisionPost(true)
  }

  const isAllApproved = useMemo(() => {
    const decisionsStatuses: boolean[] = approved && Object.values(approved)

    return decisionsStatuses?.every(status => status)
  }, [approved])

  return (
    <>
      <div className="app-drawer-wrapper bg-nice-redora">
        <button
          type="button"
          onClick={toggleSidebarMobile}
          className={clsx('navbar-toggler hamburger hamburger--elastic', {
            'is-active': headerDrawerToggle
          })}>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>
      <div className="app-drawer-content">
        <Button onClick={toggleSidebarMobile} className="close-drawer-btn btn btn-sm" id="CloseDrawerTooltip">
          <div
            className={clsx('navbar-toggler hamburger hamburger--elastic', {
              'is-active': headerDrawerToggle
            })}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </div>
        </Button>
        <UncontrolledTooltip target="CloseDrawerTooltip" placement="left">
          Close drawer
        </UncontrolledTooltip>
        <div className="vh-50 bg-composed-guru bg-guru executive-cards">
          <div className="p-4" style={{ marginBottom: '16px', position: 'relative' }}>
            <div className="text-center">
              {/* <FontAwesomeIcon icon={['fas', 'fas-person-hiking']} className="font-size-xl" />*/}
              <img src={hike} alt="Hike" width="35" height="35" className="mb-1" color="white" />

              <div className="font-weight-bold font-size-lg mb-0 text-white">Departamento Ejecutivo</div>
            </div>
          </div>
          <ExecutiveCards />
          <div className="mt-5">
            <div className="d-flex align-items-center justify-content-center flex-wrap">
              <Button
                onClick={openModal}
                color="primary"
                className="m-2 btn-lg font-weight-bold font-size-lg"
                disabled={!isAllApproved}>
                Aprobar paquete de decisiones
              </Button>
              {isModalOpen && (
                <ConfirmationModal
                  enabled={isModalOpen}
                  onConfirmation={onConfirmation}
                  onCancellation={closeModal}
                  temperament="success"
                  // TODO: translate
                  questionMessage="Estás seguro de aprobar todas las decisiones?"
                  description="Estas serán procesadas en este período y no podrás modificarlas."
                  confirmButtonDisabled={isConfirmButtonDisabled}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* TODO abstrat into component */}
      <div
        onClick={toggleSidebarMobile}
        role="button"
        className={clsx('app-drawer-overlay', {
          'is-active': headerDrawerToggle
        })}
      />
    </>
  )
}

export default HeaderDrawer
