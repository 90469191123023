import { User } from 'src/store/actions/types'
import { useUserCommunity } from '../features/communities'
import { useUser } from './useUser'

function useIsAdmin(): boolean {
  const user = useUser()

  const selectedCommunity = useUserCommunity()

  const userCommunity = user?.communities.find(community => community.id === selectedCommunity.id)
  const isAdmin = userCommunity?.isAdmin || false

  return isAdmin
}

function useUserIsAdmin(user: User | undefined): boolean {
  const selectedCommunity = useUserCommunity()

  if (!user) return false
  const userCommunity = user?.communities.find(community => community.id === selectedCommunity.id)
  const isAdmin = userCommunity?.isAdmin || false

  return isAdmin
}

export { useIsAdmin, useUserIsAdmin }