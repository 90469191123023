import React from 'react'
import { ListGroupItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { User } from 'src/store/actions/types'
import { Togglable, Button } from 'src/components'
import { Avatar } from '../Avatar/Avatar'

interface UserListItemProps {
  user: User
  showUserAdd: boolean
  onUserAdd?: (user: User) => void
  onUserRemove?: (userId: string) => void
  disabled?: boolean
  loading?: boolean
}

function UserListItem(props: UserListItemProps) {
  const {
    user, onUserAdd, onUserRemove, showUserAdd, disabled, loading
  } = props
  const { t } = useTranslation()

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    return showUserAdd ? onUserAdd?.(user) : onUserRemove?.(user.id)
  }

  return (
    <ListGroupItem
      tag="a"
      action={true}
      href="#/"
      // onClick={clickHandler}
      disabled={disabled ?? false}
      className="d-flex hover-scale-rounded justify-content-between align-items-center py-3"
      style={{
        background: `rgba(255, 255, 255, ${showUserAdd ? '0.5' : '1'})`
      }}>
      <div className="d-flex align-items-center">
        <Avatar src={user.avatar} />
        <div>
          <div className="font-weight-bold font-size-sm text-black">{user.name}</div>
        </div>
      </div>
      <div>
        {showUserAdd !== null && (
          <Button
            size="sm"
            color="neutral-dark"
            className="px-3"
            onClick={e => clickHandler(e)}
            showSpinner={loading}>
            <span className="btn-wrapper--icon">
              <Togglable enabled={showUserAdd} fallback={<FontAwesomeIcon icon={['fas', 'minus']} />}>
                <FontAwesomeIcon icon={['fas', 'plus']} />
              </Togglable>
            </span>
            <Togglable
              enabled={showUserAdd}
              fallback={<span className="btn-wrapper--label">{t('component.user_list_item.delete')}</span>}>
              <span className="btn-wrapper--label">{t('component.user_list_item.add')}</span>
            </Togglable>
          </Button>
        )}
      </div>
    </ListGroupItem>
  )
}

export { UserListItem }
